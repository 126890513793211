import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioFarm from './DetalleInventarioFarm';
import DetalleInventarioVer from './DetalleInventarioVer';
import DetalleInventarioFarmVer from './DetalleInventarioFarmVer';

import styled from 'styled-components';

import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderDayPickerB,
    renderCurrencyFloatTC,
    renderNumber
} from "../../Utils/renderField/renderField";

function EntradasForm(props) {
    const {
        crear,
        bloqueo,
        obtenerBodegas,
        listarProductos,
        modulo,
        periodoD,
        detalleProducto,
        registroProductoInventario,
        eliminarLineaProducto,
        confirmarEntrada,
        datos
    } = props;

    const [cantidad, setCantidad] = useState(0.00);
    const [total, setTotal] = useState(0.0);
    
    const estilo = {
        overflow: "auto",
        height: "300px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar Entrada' : 'Nueva Entrada';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Entrada'
    }
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let autoFocus = true;
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Field
                                    autoFocus={autoFocus}
                                    name='serie'
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Serie"
                                    disabled={disabled}
                                    msj="Serie"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name='numero'
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Numero"
                                    disabled={disabled}
                                    msj="Numero"
                                />
                            </div>
                            <div className='col-3'>
                                <Field
                                    name="fecha"
                                    component={renderDayPickerB}
                                    msj="Fecha"
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <Field
                                id="descripcion"
                                name="descripcion"
                                placeholder="Descripcion"
                                component={renderField}
                                className="form-control"
                                msj="Referencia"
                                disabled={disabled}
                            />
                        </div>
                        {(datos.farmaceutica === 'S') && (crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle entrada</h5>
                                <div className='row mb-2'>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <Field
                                                name="bodega"
                                                options={obtenerBodegas}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Bodega"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="producto"
                                            placeholder="Producto..."
                                            className="form-control-find"
                                            loadOptions={listarProductos}
                                            component={AsyncSelectField}
                                            msj="Producto"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setCantidad(value);
                                                }
                                            }}
                                            msj="Cantidad"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="precio"
                                            placeholder="Costo Unitario"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                                    setTotal(total);
                                                    localStorage.setItem('total_material', total)
                                                }
                                            }}
                                            msj="Costo Unitario"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Base"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={total}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Total
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="lote"
                                            placeholder="Lote"
                                            className="form-control"
                                            component={renderNumber}
                                            msj="Lote"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="fecha_vencimiento"
                                            placeholder="Fecha de vencimiento"
                                            component={renderDayPickerB}
                                            msj="Fecha de vencimiento"
                                        />
                                     </div>
                                </div>
                            </div>
                        }
                        {(datos.farmaceutica === 'N') &&(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle entrada</h5>
                                <div className='row mb-2'>
                                    <div className='col-4'>
                                        <div className="form-floating">
                                            <Field
                                                name="bodega"
                                                options={obtenerBodegas}
                                                component={SelectField}
                                                className="form-select"
                                                msj="Bodega"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="producto"
                                            placeholder="Producto..."
                                            className="form-control-find"
                                            loadOptions={listarProductos}
                                            component={AsyncSelectField}
                                            msj="Producto"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            disabled={bloqueo}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setCantidad(value);
                                                }
                                            }}
                                            msj="Cantidad"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="precio"
                                            placeholder="Costo Unitario"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                                    setTotal(total);
                                                    localStorage.setItem('total_material', total)
                                                }
                                            }}
                                            msj="Costo Unitario"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-floating">
                                            <NumberFormat
                                                className={classNames('form-control')}
                                                decimalScale={2}
                                                disabled={true}
                                                placeholder="Base"
                                                fixedDecimalScale
                                                thousandSeparator
                                                value={total}
                                                name="total"
                                            />
                                            <label className='form-label' htmlFor="total">
                                                Total
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true || editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => {
                                        registroProductoInventario(periodoD, datos);
                                        setTotal(0.00);
                                    }}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {(datos.farmaceutica === 'S') && (crear === true || editar === true) &&
                            <div style={estilo}>
                                <DetalleInventarioFarm
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {(datos.farmaceutica === 'N') &&(crear === true || editar === true) &&
                            <div style={estilo}>
                                <DetalleInventario
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {(datos.farmaceutica === 'S') && (crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleInventarioFarmVer
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {(datos.farmaceutica === 'N') && (crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleInventarioVer
                                    detalle={detalleProducto}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {disabled == false &&
                                <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={confirmarEntrada}
                                >
                                {editar ? 'Modificar' : 'Guardar'}
                                </button>
                            }
                            <a
                                href = {`/#/inventario/${id_emp[5]}/entradas/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

EntradasForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormEntradasInventario',
})(EntradasForm);
