import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { validar } from "../../../common/components/components/ValidarNit";
import Swal from 'sweetalert2';

const LISTADO_CLIENTE = 'LISTADO_CLIENTE';
const GUARDAR_CLIENTE = 'GUARDAR_CLIENTE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADER = 'LOADER';
const PARAMETRO = 'PARAMETRO';
const MODULO = 'MODULO';
const NOMBRE = 'NOMBRE';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    dispatch(setLoader(true));
    api.get('/pdv_cliente', params).then((response) => {
        dispatch({ type: LISTADO_CLIENTE, data: response });

    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar clientes',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCliente = (nombreCli) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormClientePDV.values;
    
    // Registro Cliente con NIT
    let valid = validar.valNit(data.identificadorCli);
    if (valid === false) {
        Swal.fire({
            title: 'Cliente',
            text: 'Ingrese Nit válido',
            type: 'error',
        })
    } else {
        if (nombreCli === 'NIT no encontrado'){
            Swal.fire({
                title: 'Cliente',
                text: 'Ingrese Nit válido',
                type: 'error',
            })  
        }else{
            const formData = {
                identificador: data.identificadorCli,
                nombre: nombreCli,
                direccion: data.direccionCli,
                telefono: data.telefonoCli,
                correo: data.correo,
                modulo: 'PDV',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/pdv_cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
                let ruta = `/punto_venta/${id_emp[5]}/clientes`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const modificarCliente = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormClientePDV.values;

    const id = datos.id;
    const formData = {
        id: id,
        identificador: datos.identificadorCli,
        nombre: datos.nombre,
        tipo_cliente: datos.tipo_cliente,
        direccion: datos.direccionCli,
        telefono: datos.telefonoCli,
        correo: datos.correo,
    }
    dispatch(setLoader(true));
    api.put(`/pdv_cliente/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Cliente modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/punto_venta/${id_emp[5]}/clientes`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrio un error al mododificar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/pdv_cliente/${id}`).then((response) => {
        response.identificadorCli = response.identificador
        response.direccionCli = response.direccion
        response.telefonoCli = response.telefono
        dispatch({ type: GUARDAR_CLIENTE, lectura: response });
        dispatch(initializeForm('FormClientePDV', response));
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const buscarNit = (nit) => (dispatch) => {
    const params = { nit }
    dispatch(setLoader(true));
    api.get('/pdv_cliente/buscar_nit/', params).then((response) => {
        if (response.detailCode === 'FREY_FREY_NIT_SEARCH_NOT_FOUND'){
            dispatch({ type: NOMBRE, nombreCli: 'NIT no encontrado' });
        }
        if (response.message === 'OK'){
            dispatch({ type: NOMBRE, nombreCli: response.name });
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const limpiarDatos = () => (dispatch) => {
    dispatch({ type: NOMBRE, nombreCli: '' });
}

export const actions = {
    listar,
    registroCliente,
    modificarCliente,
    leer,
    getModulo,
    parametrosEmpresa,
    buscarNit,
    limpiarDatos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CLIENTE]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_PAISES]: (state, { listaPaises }) => {
        return {
            ...state,
            listaPaises,
        };
    },
    [GUARDAR_CLIENTE]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [NOMBRE]: (state, { nombreCli }) => {
        return {
            ...state,
            nombreCli,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    listaPaises: [],
    datos: [],
    modulo: [],
    data: [],
    nombreCli: ''
};

export default handleActions(reducers, initialState)