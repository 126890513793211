import React, { Component } from 'react';
import Formulario from './productoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Productos extends Component{
    state = {
        crear: true,
        logo: null
    }

    componentDidMount = () => {
        const { match, leer, listarTipos, listarPresentacion, getModulo, 
            parametrosEmpresa, listarUnidades } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        listarTipos();
        listarPresentacion();
        listarUnidades();
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
    }
    setLogo = (logo) => {
        this.setState({logo});
    };

    registroProducto = (data) => {
        const { registroProducto } = this.props;
        registroProducto( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

    modificarProducto = (data) => {
        const { modificarProducto } = this.props;
        modificarProducto( {...data, logo: null},
        [{ "file": this.state.logo, "name": 'logo' },])
    }

    render(){
        const { tipo, presentacion, loader, lectura, modulo, 
            reporteProductos, datos, unidadMedida } = this.props;
        const { crear } = this.state;
        const funcionEnvio = crear ? this.registroProducto : this.modificarProducto;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    onSubmit = {funcionEnvio}
                    crear = {crear}
                    lectura = {lectura}
                    tipo={tipo}
                    presentacion={presentacion}
                    setLogo = {this.setLogo}
                    modulo = {modulo}
                    reporteProductos = {reporteProductos}
                    datos = {datos}
                    unidadMedida = {unidadMedida}
                />
            </LoadMask>
        );
    }
}

export default Productos

