import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import Modal from '../../../components/components/PuntoVentaModal';

// Elementos del formulario
import {
  renderField,
  renderCurrencyPoliza,
  renderCurrencyFloat,
  SelectField,
  renderCurrencyPuntoVenta,

} from "../../Utils/renderField/renderField";
import "../../../../../style/PuntoVenta/index.css";
import { FaArrowAltCircleLeft, FaCashRegister, FaPlusCircle, FaSave, FaTimes } from 'react-icons/fa';

function FacturaPosForm(props) {
  const {
    crear,
    modulo,
    statusCaja,
    registrarAperturaCaja,
    registroCliente,
    buscarNit,
    nombreCli,
    buscarCliente,
    limpiarDatos,
    listarProductos,
    datos,
    saldo,
    productos,
    registroVenta,
  } = props;

  // MODALS
  const [estadoModalAperturaCaja, setEstadoModalAperturaCaja] = useState(true);
  const [estadoModalCorteCaja, setEstadoModalCorteCaja] = useState(false);
  const [estadoModalCliente, setEstadoModalCliente] = useState(false);
  const [estadoModalFormaPago, setEstadoModalFormaPago] = useState(false);

  const handleShowModalCliente = () => setEstadoModalCliente(true);
  const handleShowModalCorteCaja = () => setEstadoModalCorteCaja(true);
  const handleShowModalFormaPago = () => setEstadoModalFormaPago(true);
  const handleShowModalAperturaCaja = () => setEstadoModalAperturaCaja(true);

  const handleCloseModalCliente = () => setEstadoModalCliente(false);
  const handleCloseModalCorteCaja = () => setEstadoModalCorteCaja(false);
  const handleCloseModalFormaPago = () => setEstadoModalFormaPago(false);
  const handleCloseModalAperturaCaja = () => setEstadoModalAperturaCaja(false);


  const [image, setImage] = useState('');
  const [isActiveList, setIsActiveList] = useState(false)
  const [focus, setFocus] = useState(true)
  const [filter, setFilter] = useState('');
  const [cliente, setCliente] = useState('');

  // const [productos, setProductos] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editIndexAdd, setEditIndexAdd] = useState(null);
  const [editCantidad, setEditCantidad] = useState(0.00);
  const [editPrecio, setEditPrecio] = useState(0.00);
  const [editDescuento, setEditDescuento] = useState(0.00);
  const [editTotal, setEditTotal] = useState(0.00);
  const [editCambio, setEditCambio] = useState(0.00);

  const [iva, setIva] = useState(0.00);


  const [pagos, setPagos] = useState(
    [
      // { id: "1", tipo: "Efectivo", autorizacion: "0", importe: "125.00" },
      // { id: "2", tipo: "Tarjeta Credito", autorizacion: "1234566", importe: "125.00" },
      // { id: "3", tipo: "Tarjeta Débito", autorizacion: "1234566", importe: "125.00" },
      // { id: "4", tipo: "Transferencia", autorizacion: "1234566", importe: "125.00" },
    ]
  );

  const tipos = [
    {
      value: 'L',
      label: 'LOCAL'
    },
    {
      value: 'E',
      label: 'EXTERIOR'
    },
    {
      value: 'P',
      label: 'PERSONA'
    },
  ];

  // Status cliente
  const [idCliente, setIdCliente] = useState('');
  //const [nombreCli, setNombre] = useState('');
  const [activo, setActivo] = useState(true);

  useEffect(() => {
    listarProductos();
    statusCaja();
  }, [])

  // Buscardor de productos
  const filteredProducts = productos.filter((product) => {
    return (product.label.toLowerCase().includes(filter.toLowerCase()) || product.sku.toLowerCase().includes(filter.toLowerCase()));
  });

  // Load Image
  const imageSelected = (id) => {
    const [product] = productos.filter((item) => item.id === id)
    if (!id) {
      return
    }
    setImage(product)
  }

  // Elimidar detalle producto
  const deleteItemProduct = (id) => {
    if (!id) {
      return
    }
    const newDetails = detalle.filter((item) => item.id !== id)
    setDetalle(newDetails);
  }

  const clearDetails = () => {
    setDetalle([]);
    setEditIndex(null);
    setEditIndexAdd(null);
    setImage(null);
  }

  /** CHANGE EDIT DETAIL PRODUCTS ***/
  // Load editIndex
  useEffect(() => {
    if (editIndex !== null) {
      const editItem = detalle.filter((item) => item.id === editIndex);
      setEditCantidad(editItem[0].cantidad);
      setEditPrecio(editItem[0].precio);
      setEditDescuento(editItem[0].descuento);
      setEditTotal(editItem[0].total);
    } else {
      setEditCantidad('');
      setEditPrecio('');
      setEditDescuento('');
      setEditTotal('');
    }
  }, [editIndex, detalle]);
  // Load index Select
  useEffect(() => {
    if (editIndexAdd !== null) {
      const editItem = detalle.filter((item) => item.id === editIndexAdd);
      if (editItem.length > 0 && (editIndexAdd !== editItem[0].id)) {
        return
      } else {
        if (editItem.length > 0) {
          let cant = Number(editItem[0].cantidad) + 1;
          setEditCantidad(cant);
          setEditPrecio(editItem[0].precio);
          setEditDescuento(editItem[0].descuento);
          setEditTotal((cant) * editItem[0].precio);
        }
      }
    }
  }, [editIndexAdd, detalle]);

  // Index edition
  const startEditionChange = (index) => {
    setEditIndex(index);
  }
  // Index Add if exist
  const startEditionSelect = (index) => {
    const editItem = detalle.filter((item) => item.id === index);
    if (editItem.length > 0) {
      setEditIndexAdd(index);
    }
    return
  }

  // Manejador evento de cantidad
  const changeManageCantidad = (e) => {
    setEditCantidad(e.target.value);
    setEditTotal(e.target.value == 0 ? 0 : (editPrecio * e.target.value) - editDescuento);
  }
  // Manejador evento de Precio
  const changeManagePrecio = (e) => {
    setEditPrecio(e.target.value);
    setEditTotal(e.target.value == 0 ? 0 : (editCantidad * e.target.value) - editDescuento);
  }
  // Manejador evento de Precio
  const changeManageDescuento = (e) => {
    setEditDescuento(e.target.value);
    setEditTotal(e.target.value == 0 ? 0 : (editPrecio * editCantidad) - e.target.value);
  }
  // Manejador evento de Total
  const changeManageTotal = (e) => {
    setEditTotal((editCantidad * editPrecio) - editDescuento);
  }
  // Captura los cambios y guardar
  const saveChange = (index) => {
    const nuevoDetalle = detalle.map((item) => (item.id === index ? { ...item, cantidad: editCantidad, precio: editPrecio, descuento: editDescuento, total: editTotal } : item));
    setDetalle(nuevoDetalle);
    setEditIndex(null);
    setEditCantidad("");
    setEditPrecio("");
    setEditDescuento("");
    setEditTotal("");
  }
  // Guarda los cambios al agregrar un producto que ya existe
  const saveChangeSelect = (index) => {
    if (index === editIndexAdd) {
      const nuevoDetalle = detalle.map((item) => (item.id === index ? { ...item, cantidad: editCantidad, precio: editPrecio, descuento: editDescuento, total: editTotal } : item));
      setDetalle(nuevoDetalle);
      setEditIndexAdd(null);
      setEditCantidad("");
      setEditPrecio("");
      setEditDescuento("");
      setEditTotal("");
    }
  }

  // Agregar producto al carrito
  const handleOnSubmitProduct = id => {
    if (id) {
      const [item] = detalle.filter((item) => item.id === id)
      if (!item) {
        const [product] = productos.filter((item) => item.id === id)
        const newItem = {
          id: product.id,
          sku: product.sku,
          nombre: product.nombre,
          cantidad: 1,
          precio: product.precio,
          descuento: 0.00,
          total: (product.precio * 1),
          tipo: product.tipo
        }
        setEditCantidad("")
        setEditPrecio("")
        setEditDescuento("");
        setEditTotal("");
        setImage("");
        setDetalle([newItem, ...detalle]);
      }
      return
    }
  }
  // Agregar detalle al hacer doble click
  const onDobleClickSelect = (id) => {
    cliks.push(new Date().getTime());
    window.clearTimeout(time);
    time = window.setTimeout(() => {
      if (cliks.length > 1 && (cliks[cliks.length - 1] - cliks[cliks.length - 2]) < 500) {
        if (id === "") {
          return
        }

        if (id) {
          handleOnSubmitProduct(id);
          saveChangeSelect(id);
          setFilter("");
          setIsActiveList(false);
        }
      }
    }, 500)
    if (cliks.length === 1) {
      // Setea los valores si ya se encuetra en el detalle
      startEditionSelect(id)
    }
  }

  /** FORMA DE PAGOS ***/
  // Elimidar detalle de pago
  const deleteItemPago = (id) => {
    if (!id) {
      return
    }
    const newPagos = pagos.filter((item) => item.id !== id)
    setPagos(newPagos);
  }


  const editar = window.location.href.includes('editar');
  let imagenActive = true;
  let titulo = editar ? 'Modificar Venta' : 'Crear Venta';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Venta';
  }
  let autoFocus = true;
  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  const formaPago = [
    {
      value: 'EF',
      label: 'EFECTIVO'
    },
    {
      value: 'TD',
      label: 'TARJETA DEBITO'
    },
    {
      value: 'TC',
      label: 'TARJETA CREDITO'
    },
    {
      value: 'TE',
      label: 'TRANSFERENCIA'
    },
  ];

  // DOBLE CLIK
  let cliks = [];
  let time = "";

  // STYLES
  const heithPos = {
    height: "60vh",
  }
  const estilo = {
    background: "#333",
  };
  const styleSelect = {
    height: "200px",
    border: "none",
    "margin-left": "-10px",
  }

  const handleSubmitCorteCaja = (e) => {
    e.preventDefault
    console.log("data");
  }

  const handleSubmitFormaPago = (e) => {
    e.preventDefault
    console.log("data");
  }
  const handleAddPago = () => {
    const newItem = {
      id: new Date().getTime(),
      tipo: "EFECTIVO",
      autorizacion: 0,
      importe: "500"
    }
    setPagos([newItem, ...pagos]);
    handleCloseModalFormaPago()
  }

  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      {/* ************ PUNTO DE VENTA **************  */}
      <div className='row h-100 m-0 mt-2 text-white rounded' style={estilo} >
        {/* Cuando los buttons van dentro de la seccion de productos se colca "clase: pb-4" de lo contrario mo se coloca nada */}
        <div className='row m-0' >
          {/* BUSCAR PRODUCTO Y DETALLE PRODUCTOS */}
          <div className="col-12 col-lg-8 py-3">
            <div className="card p-3 bg-dark text-white h-100 rounded">
              <div className="row">
                <div className="col-12">
                  <h4 className="text-white fs-6 d-flex justify-content-around align-items-center mt-2">PUNTO DE VENTA</h4>
                  <div className="row">
                    {/* Input para ingresar producto */}
                    <div className="col-12 col-md-12">
                      <div className="row">
                        <div className="col-12">
                          <label className="fw-bold">INGRESE PRODUCTO
                          </label>
                          <input
                            autoFocus
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (e.target.value === "") {
                                  return (
                                    setIsActiveList(false),
                                    setFilter(""),
                                    setFocus(true),
                                    imageSelected(null),
                                    startEditionSelect(null)
                                  )
                                }
                                if (filteredProducts.length < 1) {
                                  return (
                                    setIsActiveList(false),
                                    setFilter(""),
                                    imageSelected(null),
                                    startEditionSelect(null)
                                  )
                                }
                                setFilter(e.target.value);
                                if (filteredProducts) {
                                  // Guarda nuevo item en el detalle
                                  // handleOnSubmitProduct(filteredProducts[0].id);
                                  // saveChangeSelect(filteredProducts[0].id);
                                  setIsActiveList(false)
                                }
                                setFocus(true)
                                e.target.value = "";
                                setFilter("");
                                handleOnSubmitProduct(null);
                                saveChangeSelect(null);
                                startEditionSelect(null);
                                imageSelected(null)
                              }
                            }}
                            value={filter}
                            onChange={(e) => {
                              if (e.target.value.length < 1) {
                                return (
                                  setFilter(""),
                                  setIsActiveList(false),
                                  handleOnSubmitProduct(null),
                                  startEditionSelect(null),
                                  imageSelected(null)
                                )
                              }
                              setFilter(e.target.value)
                              // Valida si ya se encuentra el item en el detalle
                              if (filteredProducts.length > 0) {
                                setIsActiveList(false)
                                // startEditionSelect(filteredProducts[0].id);
                              } else {
                                return
                              }
                              startEditionSelect(null);
                              setIsActiveList(true)
                            }}
                            type="text"
                            placeholder="Ingrese el código de barras o el nombre del producto"
                            className="form-control form-control-sm py-2 px-2"
                            id="iptCodigoVenta"
                            name="iptCodigoVenta"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                          />

                        </div>
                      </div>
                    </div>

                    {/* Select producto */}
                    {isActiveList &&
                      <div className="col-12 col-md-12">
                        <div className="row border m-0 rounded-bottom ">
                          <div className="col-12 col-lg-8">
                            {filteredProducts &&
                              <select
                                style={styleSelect}
                                multiple={true}
                                onClick={(e) => onDobleClickSelect(e.target.value)}
                                onBlur={(e) => {
                                  return (
                                    e.target.value = "",
                                    setImage(""),
                                    setIsActiveList(true)
                                  )
                                }}
                                onChange={(e) => {
                                  if (filteredProducts.length < 1) {
                                    return (
                                      e.target.value = "",
                                      setFilter(""),
                                      setIsActiveList(false),
                                      handleOnSubmitProduct(null),
                                      imageSelected(null)
                                    )
                                  }
                                  if (filteredProducts.length > 1) {
                                    setIsActiveList(true)
                                    imageSelected(e.target.value);
                                    startEditionSelect(e.target.value);
                                  }
                                  imageSelected(null)
                                  startEditionSelect(null)
                                }}
                                onKeyUp={(e) => {
                                  imageSelected(e.target.value);
                                  startEditionSelect(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                  if (e.target.value === "") {
                                    return
                                  }
                                  if (e.key === "Enter") {
                                    handleOnSubmitProduct(e.target.value);
                                    saveChangeSelect(e.target.value);
                                    e.target.value = "";
                                    setFilter("");
                                    setIsActiveList(false);
                                    imageSelected(null);
                                  }
                                }}
                                className="form-select bg-dark text-white"
                                aria-label="Default select example"
                              >
                                {filteredProducts.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                  > {`${item.label}`}
                                  </option>
                                ))}
                              </select>
                            }
                          </div>
                          {image &&
                            <div className="col-12 col-lg-4 text-center">
                              <img style={{ height: "190px", width: "200px", margin: "auto" }} src={image.url} className="rounded text-center border-secondary p-2" alt={image.nombre} />
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {/* Listado de productos al carrito*/}
                    {!isActiveList &&
                      <div className="col-12 mt-2">
                        <div className="row">
                          <div className="col-12 table-responsive">
                            <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                              <thead className="bg-main">
                                <tr>
                                  {/* <th>Tipo</th> */}
                                  <th>SKU</th>
                                  <th>Descripcion</th>
                                  <th className="text-center">Cantidad</th>
                                  <th className="text-center">Precio</th>
                                  <th className="text-center">Descuento</th>
                                  <th className="text-center">Total</th>
                                  <th className="text-center">Acciones</th>
                                </tr>
                              </thead>
                              <tbody className="small text-left">
                                {detalle.map((item) => (
                                  <tr key={item.id}>
                                    {/* <td>{item.tipo}</td> */}
                                    <td>{item.sku}</td>
                                    <td>{item.nombre}</td>
                                    {/* Cantidad */}
                                    <td className="text-center">
                                      <div className="m-0 p-0 d-flex justify-content-center">
                                        <input type="number"
                                          // className="bg-dark border-0 rounded text-center"
                                          className="form-control h-0 form-control-sm px-2 text-center m-0"
                                          value={editIndex === item.id ? editCantidad : item.cantidad}
                                          onChange={changeManageCantidad}
                                          onFocus={() => startEditionChange(item.id)}
                                          onBlur={() => saveChange(item.id)}
                                          style={{ width: "70px" }} />
                                      </div>
                                    </td>
                                    {/* Precio */}
                                    <td className="text-center">
                                      <div className="m-0 p-0 d-flex justify-content-center">
                                        <input type="text"
                                          className="form-control h-0 form-control-sm px-2 text-center m-0"
                                          // className="bg-dark border-0 rounded text-center"
                                          value={editIndex === item.id ? editPrecio : item.precio}
                                          onChange={changeManagePrecio}
                                          onFocus={() => startEditionChange(item.id)}
                                          onBlur={() => saveChange(item.id)}
                                          style={{ width: "70px" }}
                                        />
                                      </div>
                                    </td>
                                    {/* Descuento */}
                                    <td className="text-center">
                                      <div className="m-0 p-0 d-flex justify-content-center">
                                        <input type="text"
                                          className="form-control h-0 form-control-sm px-2 text-center m-0"
                                          value={editIndex === item.id ? editDescuento : item.descuento}
                                          onChange={changeManageDescuento}
                                          onFocus={() => startEditionChange(item.id)}
                                          onBlur={() => saveChange(item.id)}
                                          style={{ width: "70px" }}
                                        />
                                      </div>
                                    </td>

                                    <td className="text-center">
                                      <div className="m-0 p-0 d-flex justify-content-center">
                                        <input type="text"
                                          className="form-control h-0 form-control-sm px-2 text-center m-0"
                                          value={editIndex === item.id ? parseFloat(editTotal).toFixed(2) : parseFloat(item.total).toFixed(2)}
                                          onChange={changeManageTotal}
                                          onFocus={() => startEditionChange(item.id)}
                                          onBlur={() => saveChange(item.id)}
                                          disabled={true}
                                          style={{ width: "70px" }}
                                        />
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <button className="btn-success text-lg bg-dark text-white px-2" onClick={(e) => deleteItemProduct(item.id)} >X</button>
                                    </td>
                                  </tr>
                                ))}
                                {detalle.length < 1 &&
                                  <tr>
                                    <td colSpan={7} className="text-center">No hay productos</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <br />
                          {/* Resumen totales */}
                          <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-end align-items-center mt-2">
                            <div className="float-right">
                              <div className="row">
                                <div className="">
                                  <div className="row">
                                    <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                                      <span>TOTAL</span>
                                      <span className="float-right " id="resumen_total_venta">Q. {parseFloat(detalle.reduce((prod, item) => prod + item.total, 0)).toFixed(2)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Botones */}
                    <div className='col-12 col-lg-12 mt-2'>
                      <br></br>
                      <br></br>
                      <div className="col-12">
                        <div className="d-flex justify-content-center gap-3">
                          <button
                            className='btn mr-2 btn-primary'
                            onClick={() => registroVenta(detalle, datos, idCliente)}
                          >
                            <FaSave />  Guardar
                          </button>
                          <button
                            className="btn mr-2 btn-secondary"
                            onClick={e => clearDetails()}
                          >
                            <FaTimes />  Cancelar
                          </button>
                          <a href={`/#/punto_venta/${id_emp[5]}/`}
                            className='btn mr-2 btn-danger text-lg'
                          >
                            <FaArrowAltCircleLeft />  Salir
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Datos del cliente y formas de pago */}
          <div className="col-12 col-lg-4 py-3">
            <div className="card p-3 bg-dark text-white h-100 rounded">
              <div className="row">
                {/* Cuadre de caja */}
                <div className="col-12 text-center">
                  <div className="col-12 d-flex justify-content-center text-center">
                    <h4 className='text-secondary fs-600'>
                      <button
                        className='btn btn-info text-dark fw-bold p-2'
                        disabled={disabled}
                        onClick={handleShowModalCorteCaja}
                      >
                        <FaCashRegister />  Realizar cierre
                      </button>
                    </h4>
                  </div>
                </div>
                {/* Datos del cliente */}
                <div className="col-12">
                  <div className="col-12 p-3 text-center">
                    <h4 className='text-secondary'>
                      <span>Datos del cliente  </span>
                      {<button
                        className="btn btn-primary"
                        onClick={handleShowModalCliente}
                      >
                        <FaPlusCircle />  Agregar
                      </button>
                      }
                    </h4>
                    <div className="row border rounded ">
                      <label htmlFor="nitcliente" className="col-12 text-center col-lg-4 col-form-label text-lg-end">Nit del cliente:</label>
                      <div className="col-12 col-lg-8 align-content-center">
                        <input
                          type="text"
                          autocomplete="off"
                          className="text-center text-lg-left form-control bg-dark border-0 text-white fw-bold" name="nitcliente"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              let cl = buscarCliente(e.target.value)
                              cl.then(d => { 
                                if (d === undefined) {
                                  handleShowModalCliente();
                                  setCliente('NIT no encontrado');
                                } else {
                                  setCliente(d.nombre);
                                  setIdCliente(d.id)
                                }
                              }).catch((error) => {
                                setCliente('Error al buscar cliente');
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row border rounded text-center bg-secondary text-white">
                      <label htmlFor="nombrecliente" className="col-12 col-lg-4 col-form-label text-center text-lg-end">Nombre:</label>
                      <div className="col-12 text-center col-lg-8">
                        <input type="text" readOnly className="text-center text-lg-left form-control-plaintext text-white fw-bold" name="nombrecliente"
                          value={cliente}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Formas de pago */}
                {/* <!-- LISTADO Y RESUMEN FORMA PAGO --> */}
                <div className="col-12 mt-3">
                  <div className="col-12 table-responsive">
                    <div className="col-12 text-center">
                      <h4 className='text-secondary'>
                        <span>Forma de pago </span>
                        <button
                          className="btn btn-primary"
                          onClick={handleShowModalFormaPago}
                        >
                          <FaPlusCircle />  Agregar
                        </button>
                      </h4>
                    </div>
                    <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                      <thead className="bg-main text-center">
                        <tr>
                          <th>Tipo</th>
                          <th>Autorozación</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="small text-center">
                        {pagos.map((item) => (
                          <tr key={item.id}>
                            <td>{item.tipo}</td>
                            <td>{item.autorizacion}</td>
                            <td className="text-center">{item.importe}</td>
                            <td className="text-center">
                              <button className="btn-secondary text-lg bg-dark text-white px-1" onClick={(e) => deleteItemPago(item.id)} >X</button>
                            </td>
                          </tr>
                        ))}
                        {pagos.length < 1 &&
                          <tr>
                            <td colSpan={4} className="text-center">No hay pagos</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Totales */}
                  <div className="col-12 col-lg-12 col-md-12 mt-2">
                    <div className="float-center text-center">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <hr className='my-0' />
                        </div>
                        <div className="col-12 col-md-12 d-flex justify-content-between">
                          <span>TOTAL A PAGAR</span>
                          <span className="float-right" id="resumen_subtotal">Q. {parseFloat(detalle.reduce((prod, item) => prod + item.total, 0)).toFixed(2)}</span>
                        </div>
                        <div className="col-12 col-md-12 d-flex justify-content-between">
                          <span>PAGO RECIBIDO</span>
                          <span className="float-right" id="resumen_subtotal">Q. {parseFloat(pagos.reduce((a, c) => a + c.importe, 0)).toFixed(2)}</span>
                        </div>
                        <div className="col-12 col-md-12 d-flex justify-content-between fw-bold">
                          <span>CAMBIO</span>
                          <span className="float-right " id="resumen_total_venta">
                            Q. {
                              parseFloat(parseFloat(detalle.reduce((prod, item) => prod + item.total, 0)).toFixed(2)
                                -
                                parseFloat(pagos.reduce((a, c) => a + c.importe, 0)).toFixed(2)).toFixed(2)
                              }
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ************ MODAL CLIENTE **************  */}
      <Modal
        estadoModal={estadoModalCliente}
        handleClose={handleCloseModalCliente}
        titulo="Nuevo cliente"
        layoutColumn={6} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            <div className="col-12">
              <div className="row">
                {/* Identificador */}
                <div className="mb-2 col-md-4">
                  <Field
                    autoFocus={autoFocus}
                    name="identificadorCli"
                    placeholder="Identificador"
                    className="form-control"
                    component={renderField}
                    disabled={editar ? true : disabled}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        buscarNit(e.target.value);
                      }
                    }}
                    msj="Nit"
                  />
                </div>
              </div>
              <div className="row">
                {/* Nombre */}
                <div className="mb-2 col-md-8">
                  <div className="form-floating">
                    <input
                      name="nombre"
                      placeholder="Nombre"
                      className="form-control"
                      value={nombreCli}
                    />
                    <label className='form-label' htmlFor="nombre">
                      Nombre
                    </label>
                  </div>
                </div>

                {/* Telefono */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="telefonoCli"
                    placeholder="Telefono"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Telefono"
                  />
                </div>

              </div>
              <div className="row">
                {/* Direccion */}
                <div className="mb-2 col-md-8">
                  <Field
                    name="direccionCli"
                    placeholder="Direccion"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Dirección"
                  />
                </div>

                {/* Correo */}
                <div className="mb-2 col-md-4">
                  <Field
                    name="correo"
                    placeholder="Correo electrónico"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Correo electronico"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={
              (e) => {
                e.preventDefault();
                registroCliente(nombreCli);
                nombreCli ? handleCloseModalCliente() : '';
                limpiarDatos();
              }

            }
          >
            <FaSave />  Guardar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalCliente}
          >
            <FaArrowAltCircleLeft />  Salir
          </button>
        </div>
      </Modal>

      {/* ************ MODAL CORTE CAJA **************  */}
      <Modal
        estadoModal={estadoModalCorteCaja}
        handleClose={handleCloseModalCorteCaja}
        titulo="Detalle Corte de caja"
        layoutColumn={5} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            <div className="col-12">
              <h5 className='text-center fw-bold'>{`Del ${new Date().toLocaleDateString()} al ${new Date().toLocaleDateString()} `}</h5>
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS EN EFECTIVO</span>
                        <span className="float-right" id="resumen_subtotal">Q/ 500.00</span>
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS CON TARJETA</span>
                        <span className="float-right" id="resumen_total_igv">Q/ 2,500.00</span>
                      </div>
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>TOTAL VENTAS</span>
                        <span className="float-right " id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>EFECTIVO AL INICIO</span>
                        <span className="float-right" id="resumen_subtotal">Q/ 500.00</span>
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between">
                        <span>VENTAS EN EFECTIVO</span>
                        <span className="float-right" id="resumen_total_igv">Q/ 2,500.00</span>
                      </div>
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>TOTAL EFECTIVO</span>
                        <span className="float-right" id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Importe efectivo */}

                {/* <div className="m-0 p-0 d-flex justify-content-center">
                  <input type="text"
                    // className="bg-dark border-0 rounded text-center"
                    className="form-control h-0 form-control-sm px-2 text-center m-0"
                    value={editIndex === item.id ? editCantidad : item.cantidad}
                    onChange={changeManageCantidad}
                    onFocus={() => startEditionChange(item.id)}
                    onBlur={() => saveChange(item.id)}
                    style={{ width: "70px" }} />
                </div> */}


                <label htmlFor="efectivocontado" className="col-12 col-lg-9 col-md-9 col-form-label">EFECTIVO AL FINAL</label>
                {/* <div className="m-0 p-0 d-flex justify-content-center "> */}
                <div className="col-12 col-md-3 col-lg-3 py-0 fs-6 m-0 p-0">
                  <Field
                    name="efectivocontado"
                    placeholder="0.00"
                    className="form-control h-0 px-2 m-0 text-end"
                    component={renderCurrencyPuntoVenta}
                    disabled={disabled}
                    msj="Efectivo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 mt-2">
                  <div className="float-center text-center">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <hr className='my-0' />
                      </div>
                      <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                        <span>DIFERENCIA</span>
                        <span className="float-right " id="resumen_total_venta">Q/ 2,500.00</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={handleSubmitCorteCaja}
          >
            <FaSave />  Guardar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalCorteCaja}
          >
            <FaArrowAltCircleLeft />  Salir
          </button>
        </div>
      </Modal>

      {/* ************ MODAL FORMA PAGO **************  */}
      <Modal
        estadoModal={estadoModalFormaPago}
        handleClose={handleCloseModalFormaPago}
        titulo="Forma de pago"
        layoutColumn={3} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
      >
        {/* Modal body */}
        <div className="modal-body py-3">
          <div className="row bg-dark text-white rounded py-2">
            {/* <!-- LISTADO Y RESUMEN FORMA PAGO --> */}
            <div className="col-12 table-responsive">
              <div className="col-12 text-center">
                <h4 className='text-secondary'>
                  Saldo a pagar
                  <span className='text-white'> Q 2,500.00 </span>
                </h4>
              </div>
              <div className="row">
                {/* Forma pago */}
                {formaPago &&
                  <div className="mb-2 col-12 col-md-12 px-2">
                    <div className="form-floating">
                      <Field
                        autoFocus
                        name="formapago"
                        options={formaPago}
                        crear={crear}
                        dato={0}
                        component={SelectField}
                        className="form-select"
                        disabled={disabled}
                        msj="Forma pago"
                      />
                    </div>
                  </div>
                }
                {/* Autorizacion */}
                <div className="mb-2 col-12 col-md-12 px-2">
                  <Field
                    name="autorizacion"
                    placeholder="Autorización"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Autorizacion"
                  />
                </div>
                {/* Importe */}
                <div className="mb-2 col-12 col-md-12 px-2">
                  <Field
                    name="Importe"
                    placeholder="Importe"
                    className="form-control"
                    component={renderCurrencyFloat}
                    disabled={disabled}
                    msj="Importe"
                  />
                </div>
              </div>
              {/* <div className="col-12 text-center py-3">
                <button type="button" className="btn btn-primary mx-1 " onClick={handleAddPago}>
                  <FaPlusCircle />  Agregar
                </button>
              </div> */}

              {/* <table id="tbl_ListadoProductos" className="table table-striped table-hover table-dark text-nowrap table-bordered">
                <thead className="bg-main text-center">
                  <tr>
                    <th>Tipo</th>
                    <th>Autorozación</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="small text-center">
                  {pagos.map((item) => (
                    <tr key={item.id}>
                      <td>{item.tipo}</td>
                      <td>{item.autorizacion}</td>
                      <td className="text-center">{item.importe}</td>
                      <td className="text-center">
                        <button className="btn-secondary text-lg bg-dark text-white px-1" onClick={(e) => deleteItemPago(item.id)} >X</button>
                      </td>
                    </tr>
                  ))}
                  {pagos.length < 1 &&
                    <tr>
                      <td colSpan={4} className="text-center">No hay pagos</td>
                    </tr>
                  }
                </tbody>
              </table> */}
            </div>
            {/* Totales */}
            {/* <div className="col-12 col-lg-12 col-md-12 mt-2">
              <div className="float-center text-center">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <hr className='my-0' />
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between">
                    <span>TOTAL A PAGAR</span>
                    <span className="float-right" id="resumen_subtotal">Q/ 0.00</span>
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between">
                    <span>PAGO RECIBIDO</span>
                    <span className="float-right" id="resumen_total_igv">Q/ 0.00</span>
                  </div>
                  <div className="col-12 col-md-12 d-flex justify-content-between fw-bold text-secondary">
                    <span>CAMBIO</span>
                    <span className="float-right " id="resumen_total_venta">Q/ 0.00</span>
                  </div>
                </div>

              </div>
            </div> */}
          </div>
        </div>

        {/* Modal footer */}
        <div className='card-footer bg-dark text-center py-3'>
          <button
            className='btn mr-2 btn-primary'
            onClick={handleAddPago}
          >
            <FaPlusCircle />  Agregar
          </button>

          <button
            className='btn mr-2 btn-secondary'
            onClick={handleCloseModalFormaPago}
          >
            <FaTimes />  Cancelar
          </button>
        </div>
      </Modal>

      {/* ************ MODAL APERTURA CAJA **************  */}
      {(saldo.status === "false") &&
        < Modal
          estadoModal={estadoModalAperturaCaja}
          handleClose={handleCloseModalAperturaCaja}
          titulo="Apertura de caja"
          layoutColumn={3} /* layoutColumn = xl-12 lg-9 md-6 sm-3 */
        >
          {/* Modal body */}
          <div className="modal-body py-3">
            <div className="row bg-dark text-white rounded py-2">
              <div className="col-12">
                <p name="fechaCaja" className='text-center fw-bold m-0 p-0'>{`Fecha: ${new Date().toLocaleDateString()}`}</p>
                <div className="row">
                  {/* Importe efectivo */}
                  <label htmlFor="efectivoInicial" className="col-12 col-lg-12 col-md-8 col-form-label text-center">EFECTIVO INICIAL</label>
                  <div className="col-12 col-md-12 col-lg-12 py-0 fs-6  ">
                    <Field
                      autoFocus
                      name="efectivoInicial"
                      placeholder="Ingresar Efectivo"
                      className="form-control px-3 m-0 fs-6"
                      component={renderCurrencyPoliza}
                      disabled={disabled}
                      msj="Efectivo Apertura"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Modal footer */}
          <div className='card-footer bg-dark text-center py-3'>
            <button
              className='btn mr-2 btn-primary'
              onClick={(e) => {
                e.preventDefault();
                registrarAperturaCaja()
                statusCaja()
                if (saldo.status === "true") {
                  handleCloseModalAperturaCaja()
                }
              }}
            >
              <FaSave />  Guardar
            </button>

            <a
              href={`/#/punto_venta/${id_emp[5]}/`}
              // onClick={(e) => handleCloseModalAperturaCaja()}
              className='btn mr-2 btn-danger text-lg'
            >
              <FaArrowAltCircleLeft />  Salir
            </a>
          </div>
        </Modal>
      }

    </div >
  );
}

FacturaPosForm.propTypes = {
  registroCliente: PropTypes.func,
  registroVenta: PropTypes.func,
  crear: PropTypes.bool,
  modulo: PropTypes.object,
  datos: PropTypes.object,
  listarProductos: PropTypes.func,
  statusCaja: PropTypes.func,
  registrarAperturaCaja: PropTypes.func,
  saldo: PropTypes.object,
  productos: PropTypes.array,
  data: PropTypes.object,
  detalleProducto: PropTypes.object,
  eliminarLineaProducto: PropTypes.func,
  periodoD: PropTypes.object,
};

export default reduxForm({
  form: 'FormFacturaPdv',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      nombreCliente: validators.exists()('Este campo es requerido'),
    });
  },
})(FacturaPosForm);
