import React from 'react';
import {
	Route,
	Switch,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
const valEmp = __CONFIG__.login;
import { Login } from './common/components/LoginRegister';
import Registro from './common/components/LoginRegister/Register/RegisterContainer';
import RegistroH from './common/components/LoginRegister/Register/RegistroHomeContainer';
import LoginTools from './common/components/LoginRegister/LoginTools/LoginToolsContainer';
import LoginFage from './common/components/LoginRegister/LoginLaFage/LoginFageContainer';
/* ROUTES */
import ProtectedRouteAjustes from './ProtectedRouteAjustes';
import ProtectedRouteModulos from './ProtectedRouteModulos';
import ProtectedRouteConta from './ProtectedRouteContabilidadV2';
import ProtectedRouteEmpresa from './ProtectedRouteEmpresas';
import ProtectedRouteCompras from './ProtectedRouteComprasV2';
import ProtectedRouteBancos from './ProtectedRouteBancos';
import ProtectedRouteFacturacion from './ProtectedRouteFacturacion';
import ProtectedRouteInventario from './ProtectedRouteInventario';
import ProtectedRouteInmobiliaria from './ProtectedRouteInmobiliaria';
import ProtectedRouteProduccion from './ProtectedRouteProduccion';
import ProtectedRouteRRHH from './ProtectedRouteRecursosHumanos';
import ProtectedRouteTools from './ProtectedRouteTools';
import ProtectedRouteAutoVentas from './ProtectedRouteAutoVentas';
import ProtectedRouteSeguros from './ProtectedRouteSeguros';
import ProtectedRouteLaboratorio from './ProtectedRouteLaboratorio';
import ProtectedRouteFinanzas from './ProtectedRouteFinanzas';
import ProtectedRouteFinanzasEstadosFinancieros from './ProtectedRouteFinanzasEstadosFinancieros';
import ProtectedRouteFinanzasComprasVentas from "./ProtectedRouteFinanzasComprasVentas";
import ProtectedRouteRegistroSanitario from './ProtectedRouteRegistroSanitario'
import ProtectedRouteNomina from './ProtectedRouteNomina';
import ProtectedRouteLibrosContables from './ProtectedRouteLibrosContables';
import ProtectedRouteRegistro from './ProtectedRouteRegistro';
import ProtectedRouteRegistroMarca from './ProtectedRouteRegistroMarca';
import ProtectedRouteVisitadorMedico from './ProtectedRouteVisitadorMedico';
import ProtectedRoutePuntoVenta from './ProtectedRoutePuntoVenta';

/* PAGES */
import NotFound from './common/components/layout/NotFound/NotFound';
import Modulos from './common/components/Modulos/ModuloContainer';
import Empresas from './common/components/Empresas/EmpresaContainer';
import Estable from './common/components/Empresas/EstablecimientoContainer';
import Ajustes from './common/components/Ajustes/AjustesContainer';
import ListEmpresa from './common/components/Ajustes/EmpresasContainer';
import ListEstablecimiento from './common/components/Ajustes/EstablecimientoContainer';
import EmpresaContainer from './common/components/Ajustes/EmpresaFormContainer';
import EstablecimientoContainer from './common/components/Ajustes/EstablecimientoFormContainer';
import Conta from './common/components/Contabilidad/ContabilidadContainer';
import NivelesContainer from './common/components/Contabilidad/Niveles/NivelesFormContainer';
import CuentasContablesForm from './common/components/Contabilidad/CuentasContables/CuentasContablesContainer';
import CuentasContables from './common/components/Contabilidad/CuentasContables/CuentasContablesListContainer';
import Polizas from './common/components/Contabilidad/Polizas/PolizasListContainer';
import PolizaForm from './common/components/Contabilidad/Polizas/PolizasFormContainer';
import ListUsuarios from './common/components/Ajustes/UsuariosListContainer';
import ListPeriodos from './common/components/Periodos/PeriodosListContainer';
import PeriodoContainer from './common/components/Periodos/PeriodosContainer';
import SelPeriodo from './common/components/Periodos/SeleccionarPeriodoContainer';
import ParametrosContainer from './common/components/Parametros/ParametrosFormContainer';
import CentroCostoContainer from './common/components/Contabilidad/CentroCosto/CentroCostoListContainer';
import CentroCostoFormContainer from './common/components/Contabilidad/CentroCosto/CentroCostoContainer';
import UsuarioForm from './common/components/Ajustes/UsuarioContainer';
import Informes from './common/components/Contabilidad/InformesContainer';
import Compras from './common/components/Compras/ComprasContainer';
import InformesCompras from './common/components/Compras/InformesContainer';
import TipoDocumento from './common/components/Compras/TipoDocumento/TipoDocumentoListContainer';
import TipoDocumentoForm from './common/components/Compras/TipoDocumento/TipoDocumentoContainer';
import ProveedorList from './common/components/Compras/Proveedores/ProveedoresListContainer';
import ProveedorForm from './common/components/Compras/Proveedores/ProveedoresContainer';
import SelCompras from './common/components/Compras/Compras/SeleccionarPeriodoContainer';
import ComprasList from './common/components/Compras/Compras/ComprasListContainer';
import ComprasForm from './common/components/Compras/Compras/ComprasContainer';
import ConfigContaContainer from './common/components/Contabilidad/ConfigCuentasContainer';
import LiqGastosList from './common/components/Compras/LiquidacionGastos/LiquidacionGastosListContainer';
import LiqGastosForm from './common/components/Compras/LiquidacionGastos/LiquidacionGastosContainer';
import Bancos from './common/components/Bancos/BancosContainer';
import BancosList from './common/components/Bancos/Bancos/BancosListContainer';
import BancosForm from './common/components/Bancos/Bancos/BancosContainer';
import CuentasBancariasList from './common/components/Bancos/CuentaBancaria/CuentaBancariaListContainer';
import CuentasBancariasForm from './common/components/Bancos/CuentaBancaria/CuentaBancariaContainer';
import SelEgresos from './common/components/Bancos/Cheques/SeleccionarPeriodoContainer';
import ChequesForm from './common/components/Bancos/Cheques/ChequesContainer';
import ChequesList from './common/components/Bancos/Cheques/ChequesListContainer';
import SelCuentaB from './common/components/Bancos/Cheques/SeleccionarCuentaContainer';
import NDForm from './common/components/Bancos/NotaDebito/NDContainer';
import NDList from './common/components/Bancos/NotaDebito/NDListContainer';
import SelCuentaBND from './common/components/Bancos/NotaDebito/SeleccionarCuentaContainer';
import Facturacion from './common/components/Facturacion/FacturacionContainer';
import TipoDocumentoFac from './common/components/Facturacion/TipoDocumento/TipoDocumentoListContainer';
import TipoDocumentoFormFac from './common/components/Facturacion/TipoDocumento/TipoDocumentoContainer';
import ClienteList from './common/components/Facturacion/Clientes/ClientesListContainer';
import ClienteForm from './common/components/Facturacion/Clientes/ClientesContainer';
import SelFacturacion from './common/components/Facturacion/Facturas/SeleccionarPeriodoContainer';
import FacturacionList from './common/components/Facturacion/Facturas/FacturacionListContainer';
import FacturacionForm from './common/components/Facturacion/Facturas/FacturacionContainer';
import EnviarCorreoContainer from './common/components/LoginRegister/CambiarPassword/EnviarCorreoContainer';
import ConfirmacionEnvio from './common/components/LoginRegister/CambiarPassword/ConfirmacionEnvio';
import CambiarPasswordContainer from './common/components/LoginRegister/CambiarPassword/CambiarPasswordContainer';
import NCForm from './common/components/Bancos/NotaCredito/NCContainer';
import NCList from './common/components/Bancos/NotaCredito/NCListContainer';
import SelCuentaBNC from './common/components/Bancos/NotaCredito/SeleccionarCuentaContainer';
import SelIngresos from './common/components/Bancos/Depositos/SeleccionarPeriodoContainer';
import DepositoForm from './common/components/Bancos/Depositos/DepositosContainer';
import DepositoList from './common/components/Bancos/Depositos/DepositosListContainer';
import SelCuentaBDEP from './common/components/Bancos/Depositos/SeleccionarCuentaContainer';
import InformesVentas from './common/components/Facturacion/InformesContainer';
import ImportacionList from './common/components/Compras/Importaciones/ImportListContainer';
import ImportacionForm from './common/components/Compras/Importaciones/ImportacionContainer';
import ActivarCuenta from './common/components/LoginRegister/Register/ActivarCuentaContainer';
import ValidarCuenta from './common/components/Ajustes/UsuarioPassContainer';
import Inventario from './common/components/Inventario/InventarioContainer';
import Profile from './common/components/LoginRegister/Profile/ProfileContainer';
import CambiarPass from './common/components/LoginRegister/CambiarPassword/CambiarPassIntContainer';
import InformesBancos from './common/components/Bancos/InformesContainer';
import Bodegas from './common/components/Inventario/bodegas/bodegasContainer';
import BodegasList from './common/components/Inventario/bodegas/bodegasListContainer';
import TipoProducto from './common/components/Inventario/tipoProducto/tipoProductoContainer';
import TipoProductoList from './common/components/Inventario/tipoProducto/tipoProductoListContainer';
import PresentacionProducto from './common/components/Inventario/presentacionProducto/presentacionProductoContainer';
import PresentacionProductoList from './common/components/Inventario/presentacionProducto/presentacionProductoListContainer';
import Producto from './common/components/Inventario/producto/productoContainer';
import ProductoList from './common/components/Inventario/producto/productoListContainer';
import UnidadMedida from './common/components/Inventario/unidadMedida/unidadMedidaContainer';
import UnidadMedidaList from './common/components/Inventario/unidadMedida/unidadMedidaListContainer';
import Inmobiliaria from './common/components/Inmobiliaria/InmobiliariaContainer';
import InformesInmobiliaria from './common/components/Inmobiliaria/InformesContainer';
import Proyectos from './common/components/Inmobiliaria/Proyectos/proyectosContainer';
import ProyectosList from './common/components/Inmobiliaria/Proyectos/proyectosListContainer';
import TipoObra from './common/components/Inmobiliaria/TipoObra/tipoObraContainer';
import TipoObraList from './common/components/Inmobiliaria/TipoObra/tipoObraListContainer';
import SubProyecto from './common/components/Inmobiliaria/SubProyectos/subProyectosContainer';
import SubProyectoList from './common/components/Inmobiliaria/SubProyectos/subProyectosListContainer';
import Fase from './common/components/Inmobiliaria/Fases/fasesContainer';
import FaseList from './common/components/Inmobiliaria/Fases/fasesListContainer';
import Material from './common/components/Inmobiliaria/Materiales/materialesContainer';
import MaterialList from './common/components/Inmobiliaria/Materiales/materialesListContainer';
import BuscarFactura from './common/components/Compras/LiquidacionGastos/BuscarFacturaContainer';
import AsignarProyecto from './common/components/Compras/LiquidacionGastos/AgregarProyectoContainer';
import SelRegistro from './common/components/Inventario/entradas/SeleccionarPeriodoContainer';
import EntradasList from './common/components/Inventario/entradas/EntradasListContainer';
import EntradasForm from './common/components/Inventario/entradas/EntradasContainer';
import SaldosList from './common/components/Facturacion/Saldos/SaldosListContainer';
import SaldosForm from './common/components/Facturacion/Saldos/SaldosInicialesContainer';
import SelAbonos from './common/components/Facturacion/Abonos/SeleccionarPeriodoContainer';
import AbonosList from './common/components/Facturacion/Abonos/AbonosListContainer';
import Abonos from './common/components/Facturacion/Abonos/AbonoClienteContainer';
import SalidasList from './common/components/Inventario/salidas/SalidasListContainer';
import SalidasForm from './common/components/Inventario/salidas/SalidasContainer';
import InformesInventario from './common/components/Inventario/InformesContainer';
import FacturacionInvForm from './common/components/Facturacion/Facturas/InventarioBienes/FacturacionInventarioContainer';
import FacturacionServForm from './common/components/Facturacion/Facturas/Servicios/FacturaServiciosContainer';
import Combo from './common/components/Inventario/producto/productoComboContainer';
import SelAbonosPro from './common/components/Compras/Abonos/SeleccionarPeriodoContainer';
import AbonosListPro from './common/components/Compras/Abonos/AbonosListContainer';
import AbonosPro from './common/components/Compras/Abonos/AbonoProveedorContainer';
import Produccion from './common/components/Produccion/ProduccionContainer';
import CentroTrabajoList from './common/components/Produccion/CentroTrabajo/CentroTrabajoListContainer';
import CentroTrabajoForm from './common/components/Produccion/CentroTrabajo/CentroTrabajoContainer';
import FaseProList from './common/components/Produccion/Fase/FaseListContainer';
import FaseProForm from './common/components/Produccion/Fase/FaseContainer';
import InsumoList from './common/components/Produccion/Insumo/InsumoListContainer';
import InsumoForm from './common/components/Produccion/Insumo/InsumoContainer';
import TipoAbono from './common/components/Compras/TipoAbono/TipoAbonoListContainer';
import TipoAbonoForm from './common/components/Compras/TipoAbono/TipoAbonoContainer';
import SelProd from './common/components/Produccion/IngresoProduccion/SeleccionarPeriodoContainer';
import IngresoPRDList from './common/components/Produccion/IngresoProduccion/IngresoListContainer';
import IngresoPRDForm from './common/components/Produccion/IngresoProduccion/IngresoProduccionContainer';
import ListadoPrecioList from './common/components/Facturacion/ListadoPrecios/ListadoPreciosListContainer';
import ListadoPrecioForm from './common/components/Facturacion/ListadoPrecios/ListadoPreciosContainer';
import Tools from './common/components/Tools/ToolsHome';
import Productos from './common/components/Tools/Productos/SubirProductosContainer';
import ProductosClasificacion from './common/components/Tools/ProductosClasificacion/ProductosClasificacionContainer';
import Cuentas from './common/components/Tools/CuentasContables/SubirCuentasContablesContainer';
import SeriesPRDForm from './common/components/Produccion/Series/SerieContainer';
import SelEnvio from './common/components/Produccion/Envio/SeleccionarPeriodoContainer';
import EnvioPRDList from './common/components/Produccion/Envio/EnvioListContainer';
import EnvioPRDForm from './common/components/Produccion/Envio/EnvioContainer';
import InformesProduccion from './common/components/Produccion/InformesContainer';
import Proveedores from './common/components/Tools/Proveedores/SubirProveedorContainer';
import SelPeriodoTC from './common/components/TipoCambio/SeleccionarPeriodoContainer';
import ListTipoCambio from './common/components/TipoCambio/TipoCambioListContainer';
import TipoCambioForm from './common/components/TipoCambio/TipoCambioContainer';
import TipoAbonoFac from './common/components/Facturacion/TipoAbono/TipoAbonoListContainer';
import TipoAbonoFormFac from './common/components/Facturacion/TipoAbono/TipoAbonoContainer';
import Departamento from './common/components/RecursosHumanos/Departamento/DepartamentoContainer';
import DepartamentoList from './common/components/RecursosHumanos/Departamento/DepartamentoContainerList';
import Puesto from './common/components/RecursosHumanos/Puesto/PuestoContainer';
import PuestoList from './common/components/RecursosHumanos/Puesto/PuestoListContainer';
import Colaborador from './common/components/RecursosHumanos/Colaborador/ColaboradorContainer';
import ColaboradorList from './common/components/RecursosHumanos/Colaborador/ColaboradorListContainer';
import Pais from './common/components/RecursosHumanos/Pais/PaisContainer';
import PaisList from './common/components/RecursosHumanos/Pais/PaisListContainer';
import SelPais from './common/components/RecursosHumanos/Colaborador/SeleccionarPaisContainer';
import NotaCobroPRDSel from './common/components/Produccion/NotaCobro/SeleccionarPeriodoContainer';
import NotaCobroPRDList from './common/components/Produccion/NotaCobro/NotaCobroListContainer';
import NotaCobroPRDForm from './common/components/Produccion/NotaCobro/NotaCobroContainer';
import PilotoProList from './common/components/Produccion/Piloto/PilotoListContainer';
import PilotoProForm from './common/components/Produccion/Piloto/PilotoContainer';
import SelConsumo from './common/components/Produccion/Consumo/SeleccionarPeriodoContainer';
import ConsumoPRDList from './common/components/Produccion/Consumo/ConsumoListContainer';
import ConsumoPRDForm from './common/components/Produccion/Consumo/ConsumoContainer';
import FacturaPRDSel from './common/components/Produccion/Factura/SeleccionarPeriodoContainer';
import FacturaPRDList from './common/components/Produccion/Factura/FacturaListContainer';
import FacturaPRDForm from './common/components/Produccion/Factura/FacturaContainer';
import FacturacionFELForm from './common/components/Facturacion/Facturas/InventarioBienesProdFELEnvio/FacturaContainer';
import SaldosListCompras from './common/components/Compras/Saldos/SaldosListContainer';
import SaldosCompraForm from './common/components/Compras/Saldos/SaldosInicialesContainer';
import SaldosFacturas from './common/components/Tools/SaldosTemp/SaldosFacturasContainer';
import Ocupaciones from './common/components/Tools/Ocupaciones/SubirOcupacionesContainer';
import Paises from './common/components/Tools/Paises/SubirPaisesContainer';
import FacturacionFELBienesForm from './common/components/Facturacion/Facturas/InventarioBienesFEL/FacturaBienesContainer';
import RRHH from './common/components/RecursosHumanos/RecursosHumanosContainer';
import InformesRRHH from './common/components/RecursosHumanos/InformesContainer';
import SelNC from './common/components/Facturacion/NotasCreditoFEL/SeleccionarPeriodoContainer';
import NotaCreditoList from './common/components/Facturacion/NotasCreditoFEL/NCListContainer';
import NotaCreditoForm from './common/components/Facturacion/NotasCreditoFEL/NCClienteContainer';
import SelND from './common/components/Facturacion/NotasDebitoFEL/SeleccionarPeriodoContainer';
import NotaDebitoList from './common/components/Facturacion/NotasDebitoFEL/NDListContainer';
import NotaDebitoForm from './common/components/Facturacion/NotasDebitoFEL/NDClienteContainer';
import SelFactEspecial from './common/components/Compras/FacturaEspecialFEL/SeleccionarPeriodoContainer';
import FacturaEspecialList from './common/components/Compras/FacturaEspecialFEL/FacturaEspecialListContainer';
import FacturaEspecialForm from './common/components/Compras/FacturaEspecialFEL/FacturaEspecialContainer';
import FacturacionServFELForm from './common/components/Facturacion/Facturas/ServiciosFEL/FacturaServiciosContainer';
import SelPeriodoCosto from './common/components/Inventario/calcularCosto/SeleccionarPeriodoContainer';
import LineasPoliza from './common/components/Tools/LineasPoliza/LineasPolizaContainer';
import DevCajasList from './common/components/Produccion/DevolucionCajas/DevolucionListContainer';
import FormDevCajas from './common/components/Produccion/DevolucionCajas/DevolucionCajasContainer';
import AutoVentas from './common/components/AutoVentas/AutoVentasContainer';
import IngresosListContainer from './common/components/AutoVentas/Ingresos/IngresosListContainer';
import IngresosFormContainer from './common/components/AutoVentas/Ingresos/IngresosFormContainer';
import IngresosFormEditarContainer from './common/components/AutoVentas/Ingresos/IngresosFormEditarContainer';
import IngresosFormVerContainer from './common/components/AutoVentas/Ingresos/IngresosFormVerContainer';
import IngresosInformesContainer from './common/components/AutoVentas/Informes/InformesContainer'
import VendedoresListContainer from './common/components/AutoVentas/Vendedores/VendedoresListContainer';
import VendedoresFormContainer from './common/components/AutoVentas/Vendedores/VendedoresFormContainer';
import VendedoresFormEditarContainer from './common/components/AutoVentas/Vendedores/VendedoresFormEditarContainer';
import VendedoresFormVerContainer from './common/components/AutoVentas/Vendedores/VendedoresFormVerContainer';
import VentasListContainer from './common/components/AutoVentas/Ventas/VentasListContainer';
import VentasFormContainer from './common/components/AutoVentas/Ventas/VentasFormContainer';
import VentasFormVerContainer from './common/components/AutoVentas/Ventas/VentasFormVerContainer';
import VentasFormEditarContainer from "./common/components/AutoVentas/Ventas/VentasFormEditarContainer";
import MarcasListFormContainer from './common/components/AutoVentas/Marcas/MarcasListContainer';
import CombustibleListFormContainer from './common/components/AutoVentas/Combustible/CombustibleListContainer';
import LineaListFormContainer from './common/components/AutoVentas/Linea/LineaListContainer';
import TipoListFormContainer from './common/components/AutoVentas/Tipo/TipoListContainer';
import FinanzasContainer from './common/components/Finanzas/FinanzasContainer';
import FinanzasAgregarEmpresa from './common/components/Finanzas/agregar-empresa/AgregarEmpresaContainer';
import FinanzasEstadosFinancierosContainer from './common/components/Finanzas/estados-financieros/EstadosFinancierosContainer';
import FinanzasArchivosBalanceDeSaldosContainer from './common/components/Finanzas/estados-financieros/Archivos/BalanceDeSaldosContainer';
import FinanzasArchivosNomenclaturaContainer from './common/components/Finanzas/estados-financieros/Archivos/NomenclaturaContainer';
import FinanzasBalanceDeSaldosContainer from './common/components/Finanzas/estados-financieros/balance-de-saldos/BalanceDeSaldosContainer';
import FinanzasCierresParcialesContainer from "./common/components/Finanzas/estados-financieros/balance-de-saldos/CierresParcialesContainer";
import FinanzasComprasVentasHome from "./common/components/Finanzas/compras-ventas/ComprasVentasHomeContainer";
import FinanzasComprasVentasContribuyentes from "./common/components/Finanzas/compras-ventas/Archivos/ContribuyentesContainer";
import FinanzasCVArchivosLibroCompras from "./common/components/Finanzas/compras-ventas/Archivos/LibroComprasContainer";
import FinanzasCVLibroCompras from "./common/components/Finanzas/compras-ventas/libro-compras/LibroComprasContainer";
import SegurosHome from './common/components/Seguros/SegurosHome';
import SegurosFichaClientes from './common/components/Seguros/FichaClientes/FichaClientes';
import SegurosFichaClientesCrear from './common/components/Seguros/FichaClientes/Crear';
import SegurosFichaClientesEditar from './common/components/Seguros/FichaClientes/Editar';
import SegurosFichaClientesVer from './common/components/Seguros/FichaClientes/Ver';
import SegurosAgentesListado from './common/components/Seguros/Agentes/Listado';
import SegurosAgentesCrear from './common/components/Seguros/Agentes/Crear';
import SegurosAgentesEditar from './common/components/Seguros/Agentes/Editar';
import SegurosAgentesVer from './common/components/Seguros/Agentes/Ver';
import SegurosInformes from './common/components/Seguros/Informes/Informes';
import SegurosSeguroListado from './common/components/Seguros/Seguro/Listado';
import SegurosSeguroCrear from './common/components/Seguros/Seguro/Crear';
import SegurosSeguroEditar from './common/components/Seguros/Seguro/Editar';
import SegurosSeguroVer from './common/components/Seguros/Seguro/Ver';
import SegurosVehiculosListado from './common/components/Seguros/Vehiculos/Vehiculos';
import SegurosVehiculosEditar from './common/components/Seguros/Vehiculos/Editar';
import SegurosVehiculosVer from './common/components/Seguros/Vehiculos/Ver';
import SegurosVehiculosCrear from './common/components/Seguros/Vehiculos/Crear';
import SegurosAseguradorasPaginas from './common/components/Seguros/Aseguradoras';
import LaboratorioHome from './common/components/Laboratorio/LaboratorioHome';
import LaboratorioClientesPaginas from './common/components/Laboratorio/Clientes';
import LaboratorioPacientesPaginas from './common/components/Laboratorio/Pacientes';
import LaboratorioUnidadIgssPaginas from './common/components/Laboratorio/UnidadIgss';
import LaboratorioIngresoDePatologiaPaginas from './common/components/Laboratorio/IngresoDePatologia';
import LaboratorioEmpresa from './common/components/Laboratorio/Empresa';
import LaboratorioLaminas from './common/components/Laboratorio/Laminas';
import LaboratorioInformes from './common/components/Laboratorio/Informes';
import LaboratorioPeriodos from './common/components/Laboratorio/Periodos';
import LaboratorioAjustes from './common/components/Laboratorio/Ajustes';
import LaboratorioPermisos from './common/components/Laboratorio/Permisos'
import LaboratorioSeries from './common/components/Laboratorio/Series';
import LaboratorioTipoAbono from './common/components/Laboratorio/TipoAbono';
import LaboratorioAbono from './common/components/Laboratorio/Abonos';
import LaboratorioInformesMisc from './common/components/Laboratorio/InformesMisc/Informes';
import MonedaList from './common/components/Ajustes/Monedas/MonedaListContainer';
import MonedaForm from './common/components/Ajustes/Monedas/MonedaFormContainer';
import ImpuestoForm from './common/components/Ajustes/Impuestos/ImpuestoContainer';
import ImpuestoFormMod from './common/components/Ajustes/Impuestos/ImpuestoFormContainer';
import RegistroSanitario from './common/components/RegistrosSanitarios/RegistroSanitarioContainer';
import Fabricante from './common/components/RegistrosSanitarios/Fabricante/FabricanteContainer';
import FabricanteList from './common/components/RegistrosSanitarios/Fabricante/FabricanteListContainer';
import FormaList from './common/components/RegistrosSanitarios/FormaFarmaceutica/FormaListContainer';
import Forma from './common/components/RegistrosSanitarios/FormaFarmaceutica/FormaFormContainer';
import ComercializadorList from './common/components/RegistrosSanitarios/Comercializador/ComercializadorListContainer';
import Comercializador from './common/components/RegistrosSanitarios/Comercializador/ComercializadorFormContainer';
import EstatusList from './common/components/RegistrosSanitarios/Estatus/EstatusListContainer';
import Estatus from './common/components/RegistrosSanitarios/Estatus/EstatusFormContainer';
import ProductoRSList from './common/components/RegistrosSanitarios/Producto/ProductoListContainer';
import ProductoRSForm from './common/components/RegistrosSanitarios/Producto/ProductoFormContainer';
import ViaRSList from './common/components/RegistrosSanitarios/ViaAdministracion/ViaAdministracionListContainer';
import ViaRSForm from './common/components/RegistrosSanitarios/ViaAdministracion/ViaAdministracionFormContainer';
import GrupoRSList from './common/components/RegistrosSanitarios/Grupo/GrupoListContainer';
import GrupoRSForm from './common/components/RegistrosSanitarios/Grupo/GrupoFormContainer';
import BajaForm from './common/components/RecursosHumanos/BajaColaborador/BajaContainer';
import SelDevProd from './common/components/Produccion/DevolucionProducto/SeleccionarPeriodoContainer';
import DevProPRDList from './common/components/Produccion/DevolucionProducto/DevolucionProductoListContainer';
import DevProPRDForm from './common/components/Produccion/DevolucionProducto/DevolucionProductoContainer';
import AsignarTipoDocumento from './common/components/RecursosHumanos/TipoDocumento/TipoDocumentoContainer';
import SelPeriodoAnticipo from './common/components/Compras/Anticipos/SeleccionarPeriodoContainer';
import AnticipoList from './common/components/Compras/Anticipos/AnticipoListContainer';
import AnticipoForm from './common/components/Compras/Anticipos/AnticipoContainer';
import SelPeriodoAnticipoCXC from './common/components/Facturacion/Anticipos/SeleccionarPeriodoContainer';
import AnticipoListCXC from './common/components/Facturacion/Anticipos/AnticipoListContainer';
import AnticipoFormCXC from './common/components/Facturacion/Anticipos/AnticipoContainer';
import LiquidacionLaboralList from './common/components/RecursosHumanos/Liquidacion/LiquidacionListContainer';
import LiquidacionLaboralForm from './common/components/RecursosHumanos/Liquidacion/LiquidacionContainer';
import NCListPro from './common/components/Compras/NotaCredito/NCListContainer';
import NCFormPro from './common/components/Compras/NotaCredito/NCProveedorContainer';
import ConfigModuloContainer from './common/components/Ajustes/Modulos/ConfigModulosContainer';
import InvernaderoList from './common/components/Produccion/Invernaderos/InvernaderosListContainer';
import InvernaderoForm from './common/components/Produccion/Invernaderos/InvernaderosContainer';
import SelDevCajas from './common/components/Produccion/DevolucionCajas/SeleccionarPeriodoContainer';
import SelDistCajas from './common/components/Produccion/DistribucionCajas/SeleccionarPeriodoContainer';
import DistCajasList from './common/components/Produccion/DistribucionCajas/DistribucionListContainer';
import FormDistCajas from './common/components/Produccion/DistribucionCajas/DistribucionCajasContainer';
import IngCajasList from './common/components/Produccion/IngresoCajas/IngresoCajasListContainer';
import IngCajasForm from './common/components/Produccion/IngresoCajas/IngresoCajasContainer';
import SelIngCajas from './common/components/Produccion/IngresoCajas/SeleccionarPeriodoContainer';
import BajaCajasList from './common/components/Produccion/BajaCajas/BajaCajasListContainer';
import BajaCajasForm from './common/components/Produccion/BajaCajas/BajaCajasContainer';
import SelBajaCajas from './common/components/Produccion/BajaCajas/SeleccionarPeriodoContainer';
import AumentoSalarioForm from './common/components/RecursosHumanos/AumentoSalario/AumentoSalarioContainer';
import VacacionesForm from './common/components/RecursosHumanos/Vacaciones/VacacionesContainer';
import CambioPuestoForm from './common/components/RecursosHumanos/PromocionPuesto/PromocionPuestoContainer';
import CorteVacacionesForm from './common/components/RecursosHumanos/CorteVacaciones/SeleccionarPaisContainer';
import SiembraList from './common/components/Produccion/Cosecha/SiembraListContainer';
import SiembraForm from './common/components/Produccion/Cosecha/SiembraContainer';
import SelSiembra from './common/components/Produccion/Cosecha/SeleccionarPeriodoContainer';
import FechaSiembra from './common/components/Produccion/FechaCosecha/FechaCosechaContainer';
import FechaFinSiembra from './common/components/Produccion/FechaFinalCosecha/FechaFinalCosechaContainer';
import TipoGastoList from './common/components/Produccion/TipoGasto/tipoGastoListContainer';
import TipoGastoForm from './common/components/Produccion/TipoGasto/tipoGastoContainer';
import GastoIList from './common/components/Produccion/Gasto/GastoListContainer';
import GastoIForm from './common/components/Produccion/Gasto/GastoContainer';
import SelGastoI from './common/components/Produccion/Gasto/SeleccionarPeriodoContainer';
import Conciliacion from './common/components/Bancos/Conciliacion/ConciliacionContainer';
import Nomina from './common/components/Nomina/NominaContainer';
import FrecuenciaPago from './common/components/Nomina/FrecuenciaPago/FrecuenciaPagoContainer';
import PeriodoNominaList from './common/components/Nomina/Periodo/PeriodoListContainer';
import PeriodoNominaForm from './common/components/Nomina/Periodo/PeriodoContainer';
import SelPeriodoNomina from './common/components/Nomina/Periodo/SeleccionarPeriodoContainer';
import SelNomina from './common/components/Nomina/Nomina/SeleccionarPeriodoNominaContainer';
import NominaList from './common/components/Nomina/Nomina/ListNominaContainer';
import SelPeriodoNom from './common/components/Nomina/Nomina/SeleccionarPeriodoContainer';
import NominaForm from './common/components/Nomina/Nomina/NominaContainer';
import ListTipoDescuento from './common/components/Nomina/TipoDescuento/TipoDescuentoListContainer';
import FormTipoDescuento from './common/components/Nomina/TipoDescuento/TipoDescuentoFormContainer';
import SeleccionarPeriodoISR from './common/components/RecursosHumanos/ISR/SeleccionarPeriodoContainer';
import ListIsr from './common/components/RecursosHumanos/ISR/ListISRContainer';
import FormIsr from './common/components/RecursosHumanos/ISR/ContainerFormISR';
import SelPaisBaja from './common/components/RecursosHumanos/BajaColaborador/SeleccionarPaisContainer';
import ColaboradorBajaList from './common/components/RecursosHumanos/BajaColaborador/ColaboradorListContainer';
import SelPeriodoHorasExtras from './common/components/Nomina/HorasExtras/SeleccionarPeriodoNominaContainer';
import ListHorasExtras from './common/components/Nomina/HorasExtras/HorasExtrasListContainer';
import FormHorasExtras from './common/components/Nomina/HorasExtras/HorasExtrasContainer';
import SelPeriodoDescuentos from './common/components/Nomina/Descuentos/SeleccionarPeriodoNominaContainer';
import ListDescuentos from './common/components/Nomina/Descuentos/DescuentosListContainer';
import FormDescuentoNomina from './common/components/Nomina/Descuentos/DescuentosContainer';
import ListTipoDescuentoRH from './common/components/RecursosHumanos/TipoDescuento/TipoDescuentoListContainer';
import FormTipoDescuentoRH from './common/components/RecursosHumanos/TipoDescuento/TipoDescuentoFormContainer';
import ListDescuentoFijo from './common/components/RecursosHumanos/DescuentosFijos/DescuentoFijoListContainer';
import FormDescuentoFijo from './common/components/RecursosHumanos/DescuentosFijos/DescuentoFijoContainer';
import LineaList from './common/components/RegistrosSanitarios/Linea/LineaListContainer';
import LineaForm from './common/components/RegistrosSanitarios/Linea/LineaFormContainer';
import FamiliaList from './common/components/RegistrosSanitarios/Familia/FamiliaListContainer';
import FamiliaForm from './common/components/RegistrosSanitarios/Familia/FamiliaFormContainer';
import AcondicionadorList from './common/components/RegistrosSanitarios/Acondicionador/AcondicionadorListContainer';
import AcondicionadorForm from './common/components/RegistrosSanitarios/Acondicionador/AcondicionadorContainer';
import PresentacionList from './common/components/RegistrosSanitarios/Presentacion/PresentacionListContainer';
import PresentacionForm from './common/components/RegistrosSanitarios/Presentacion/PresentacionFormContainer';
import ClasificacionList from './common/components/RegistrosSanitarios/Clasificacion/ClasificacionListContainer';
import ClasificacionForm from './common/components/RegistrosSanitarios/Clasificacion/ClasificacionFormContainer';
import PrincipioActivoList from './common/components/RegistrosSanitarios/PrincipioActivo/PrincipioListContainer';
import PrincipioActivoForm from './common/components/RegistrosSanitarios/PrincipioActivo/PrincipioActivoFormContainer';
import ConcentracionList from './common/components/RegistrosSanitarios/Concentracion/ConcentracionListContainer';
import ConcentracionForm from './common/components/RegistrosSanitarios/Concentracion/ConcentracionFormContainer';
import PaisRSList from './common/components/RegistrosSanitarios/Pais/PaisListContainer';
import PaisRSFormList from './common/components/RegistrosSanitarios/Pais/PaisContainer';
import SelRSPais from './common/components/RegistrosSanitarios/RegistroSanitario/SeleccionarPaisContainer';
import RegistroSanitarioList from './common/components/RegistrosSanitarios/RegistroSanitario/RegistroListContainer';
import RegistroSanitarioForm from './common/components/RegistrosSanitarios/RegistroSanitario/RegistroSanitarioFormContainer';
import BajaIgssList from './common/components/RecursosHumanos/BajaSuspensionIgss/BajaListContainer';
import BajaIgssForm from './common/components/RecursosHumanos/BajaSuspensionIgss/BajaFormContainer';
import AltaIgssList from './common/components/RecursosHumanos/AltaSuspensionIgss/AltaListContainer';
import AltaIgssForm from './common/components/RecursosHumanos/AltaSuspensionIgss/AltaFormContainer';
import PrestamoList from './common/components/RecursosHumanos/Prestamos/PrestamoListContainer';
import PrestamoForm from './common/components/RecursosHumanos/Prestamos/PrestamoFormContainer';
import SeleccionarPeriodoFalta from './common/components/RecursosHumanos/Falta/SeleccionarPeriodoContainer';
import FaltaList from './common/components/RecursosHumanos/Falta/FaltaListContainer';
import FaltaForm from './common/components/RecursosHumanos/Falta/FaltaFormContainer';
import JudicialList from './common/components/RecursosHumanos/Judicial/JudicialListContainer';
import JudicialForm from './common/components/RecursosHumanos/Judicial/JudicialFormContainer';
import LibroLC from './common/components/LibrosContables/LibrosContablesContainer';
import TrasladoLC from './common/components/LibrosContables/TrasladoPolizas/TrasladoContainer';
import PolizasLC from './common/components/LibrosContables/Polizas/PolizasListLCContainer';
import PolizaFormLC from './common/components/LibrosContables/Polizas/PolizasFormLCContainer';
import InformesLC from './common/components/LibrosContables/InformesContainerLC';
import LicenciaList from './common/components/RegistrosSanitarios/Licencia/LicenciaListContainer';
import LicenciaForm from './common/components/RegistrosSanitarios/Licencia/LicenciaContainer';
import TipoRegistroList from './common/components/RegistrosSanitarios/TipoRegistro/TipoRegistroListContainer';
import TipoRegistroForm from './common/components/RegistrosSanitarios/TipoRegistro/TipoRegistroContainer';
import IntercambioCuentaLC from './common/components/LibrosContables/IntercambioCuentas/IntercambioCuentasContainer';
import SelNominaOtrosIngresos from './common/components/Nomina/OtrosIngresos/SeleccionarPeriodoNominaContainer';
import NominaOtrosList from './common/components/Nomina/OtrosIngresos/ListNominaOtrosContainer';
import NominaOtrosForm from './common/components/Nomina/OtrosIngresos/NominaOtrosContainer';
import SelPeriodoOtrosNom from './common/components/Nomina/OtrosIngresos/SeleccionarPeriodoContainer';
import InformesNomina from './common/components/Nomina/InformesContainer';
import CierreMesNomina from './common/components/Nomina/CierreMes/CierreMesContainer';
import InformesRegistroSanitario from './common/components/RegistrosSanitarios/InformesContainer';
import SelReciboPrd from './common/components/Produccion/ReciboCaja/SeleccionarPeriodoContainer';
import ReciboCajaList from './common/components/Produccion/ReciboCaja/ReciboCajaListContainer';
import ReciboCajaForm from './common/components/Produccion/ReciboCaja/ReciboCajaFormContainer';
import SelCorteCajaPrd from './common/components/Produccion/CorteCaja/SeleccionarPeriodoContainer';
import CorteCajaList from './common/components/Produccion/CorteCaja/CorteCajaListContainer';
import CorteCaja from './common/components/Produccion/CorteCaja/CorteCajaContainer';
import SelCuentaDepRecibo from './common/components/Bancos/DepositoRecibo/SeleccionarCuentaContainer';
import FormDepReciboProd from './common/components/Bancos/DepositoRecibo/DepositoReciboContainer';
import DepositoReciboList from './common/components/Bancos/DepositoRecibo/DepositoReciboListContainer';
import LoginRegistro from './common/components/LoginRegister/Register/LoginRegistroContainer';
import SelEmpresaT from './common/components/Tools/ConsultaDocumentos/SeleccionarEmpresaContainer';
import FormDocumentoT from './common/components/Tools/ConsultaDocumentos/DocumentoContainer';
import RegistroMarca from './common/components/RegistroMarca/RegistroMarcaContainer';
import AgenteList from './common/components/RegistroMarca/Agente/AgenteListContainer';
import FormAgente from './common/components/RegistroMarca/Agente/AgenteFormContainer';
import StatusList from './common/components/RegistroMarca/Estatus/EstatusListContainer';
import FormStatus from './common/components/RegistroMarca/Estatus/EstatusFormContainer';
import TitularList from './common/components/RegistroMarca/Titular/TitularListContainer';
import FormTitular from './common/components/RegistroMarca/Titular/TitularFormContainer';
import FormObservacionRM from './common/components/RegistroMarca/Observaciones/ObservacionesFormContainer';
import ComercialList from './common/components/RegistroMarca/Comercializada/ComercializadaListContainer';
import FormComercial from './common/components/RegistroMarca/Comercializada/ComercializadaFormContainer';
import PrincipioActivoRMList from './common/components/RegistroMarca/PrincipioActivo/PrincipioListContainer';
import FormPrincipioActivoRM from './common/components/RegistroMarca/PrincipioActivo/PrincipioActivoFormContainer';
import BienesServiciosFELForm from './common/components/Facturacion/Facturas/BienesServiciosFEL/FacturaBienesServiciosContainer';
import FacturacionINVBFELForm from './common/components/Facturacion/Facturas/InventarioBienesFarmFEL/FacturaBienesContainer';
import FacturacionSerFELForm from './common/components/Facturacion/Facturas/ServiciosFELFarm/FacturaServiciosContainer';
import SelPeriodoNC from './common/components/Facturacion/NotasCredito/SeleccionarPeriodoContainer';
import NotaCreForm from './common/components/Facturacion/NotasCredito/NCClienteContainer';
import SelTrasladoCajas from './common/components/Produccion/TrasladoCajas/SeleccionarPeriodoContainer';
import TrasladoCajasList from './common/components/Produccion/TrasladoCajas/TrasladoListContainer';
import TrasladoCajasForm from './common/components/Produccion/TrasladoCajas/TrasladoCajasContainer';
import RegistroMarcaList from './common/components/RegistroMarca/RegistroMarca/RegistroMarcaListContainer';
import FormRegistroMarca from './common/components/RegistroMarca/RegistroMarca/RegistroMarcaFormContainer';
import FormRegistroMarcaVer from './common/components/RegistroMarca/RegistroMarca/RegistroMarcaFormVerContainer';
import CalculoBono14Nomina from './common/components/Nomina/Bono14/SeleccionarPeriodo';
import FormCalculoBono14Nomina from './common/components/Nomina/Bono14/BonoContainer';
import ELZMList from './common/components/RegistrosSanitarios/ELZM/ElzmListContainer';
import ELZMForm from './common/components/RegistrosSanitarios/ELZM/ElzmFormContainer';
import FacturacionINVBForm from './common/components/Facturacion/Facturas/InventarioBienesFarm/FacturaBienesContainer';
import SelRCPais from './common/components/RegistrosSanitarios/RegistroCoempaque/SeleccionarPaisContainer';
import RegistroCoempaqueList from './common/components/RegistrosSanitarios/RegistroCoempaque/RegistroCoempaqueListContainer';
import RegistroCoempaqueForm from './common/components/RegistrosSanitarios/RegistroCoempaque/RegistroCoempaqueFormContainer';
import ListEmpresasTools from './common/components/Tools/Empresas/EmpresasContainer';
import VisitadorMedico from './common/components/VisitadorMedico/VisitadorMedicoContainer';
import SelPeriodoCompra from './common/components/VisitadorMedico/Compras/SeleccionarPeriodoContainer';
import SubirComprasVM from './common/components/VisitadorMedico/Compras/SubirCompraContainer';
import ComprasListVM from './common/components/VisitadorMedico/Compras/CompraListContainer';
import TipoVMList from './common/components/VisitadorMedico/Tipo/TipoListContainer';
import TipoVMForm from './common/components/VisitadorMedico/Tipo/TipoFormContainer';
import CentroCostoVMList from './common/components/VisitadorMedico/CentroCosto/CentroCostoListContainer';
import CentroCostoVMForm from './common/components/VisitadorMedico/CentroCosto/CentroCostoFormContainer';
import DepartamentoVMList from './common/components/VisitadorMedico/Departamento/DepartamentoListContainer';
import DepartamentoVMForm from './common/components/VisitadorMedico/Departamento/DepartamentoFormContainer';
import ProductoVMList from './common/components/VisitadorMedico/Producto/ProductoListContainer';
import ProductoVMForm from './common/components/VisitadorMedico/Producto/ProductoFormContainer';
import VisitadorForm from './common/components/VisitadorMedico/Visitador/VisistadorFormContainer';
import SelPeriodoLiquidacion from './common/components/VisitadorMedico/Liquidacion/SeleccionarPeriodoContainer';
import SubirLiquidacionVM from './common/components/VisitadorMedico/Liquidacion/LiquidacionFormContainer';
import LiquidacionVMList from './common/components/VisitadorMedico/Liquidacion/LiquidacionListContainer';
import LiquidacionForm from './common/components/VisitadorMedico/Liquidacion/LiquidacionDetalleContainer';
import SelPeriodoCompraConsulta from './common/components/VisitadorMedico/Compras/SeleccionarPeriodoContainerCons';
import SelOrdenCompra from './common/components/Compras/OrdenCompra/SeleccionarPeriodoContainer';
import OrdenCompraList from './common/components/Compras/OrdenCompra/OrdenCompraListContainer';
import OrdenCompraForm from './common/components/Compras/OrdenCompra/OrdenCompraFormContainer';
import IngresoInventarioList from './common/components/Inventario/ingresoInventario/IngresoInventarioListContainer';
import IngresoInventarioForm from './common/components/Inventario/ingresoInventario/IngresoInventarioFormContainer';
import SelOrdenServicio from './common/components/Inventario/ordenServicio/SeleccionarPeriodoContainer';
import OrdenServicioList from './common/components/Inventario/ordenServicio/OrdenServicioListContainer';
import OrdenServicioForm from './common/components/Inventario/ordenServicio/OrdenServicioFormContainer';
import TipoGastoINV from './common/components/Inventario/tipoGasto/tipoGastoContainer';
import TipoGastoListINV from './common/components/Inventario/tipoGasto/tipoGastoListContainer';
import SelGastoProyecto from './common/components/Inventario/gastoProyecto/SeleccionarPeriodoContainer';
import GastoProyectoList from './common/components/Inventario/gastoProyecto/GastoProyectoListContainer';
import GastoProyectoForm from './common/components/Inventario/gastoProyecto/GastoProyectoFormContainer';
import CierreProyectoINV from './common/components/Inventario/cierreProyecto/cierreProyectoFormContainer';
import ConsumoINVList from './common/components/Inventario/consumoProducto/ConsumoProductoListContainer';
import ConsumoINVForm from './common/components/Inventario/consumoProducto/ConsumoProductoFormContainer';
import SelPeriodoOtrosGastos from './common/components/VisitadorMedico/OtrosGastos/SeleccionarPeriodoContainer';
import SubirOtrosGastosVM from './common/components/VisitadorMedico/OtrosGastos/OtrosGastosFormContainer';
import OtrosGastosVMList from './common/components/VisitadorMedico/OtrosGastos/OtrosGastosListContainer';
import OtrosGastosForm from './common/components/VisitadorMedico/OtrosGastos/OtrosGastosDetalleContainer';
import CuentaContableVMForm from './common/components/VisitadorMedico/CuentaContable/CuentaContableContainer';
import GenerarPolizaVM from './common/components/VisitadorMedico/Poliza/GenerarPolizaContainer';
import SelPolizaParqueoFac from './common/components/Facturacion/PolizaParqueo/SeleccionarPeriodoContainer';
import ListPolizaParqueoFac from './common/components/Facturacion/PolizaParqueo/PolizaListContainer';
import GenerarPolizaParqueoForm from './common/components/Facturacion/PolizaParqueo/PolizaParqueoContainer';
import CuentaContablePRD from './common/components/Produccion/CuentasSIncluir/CuentaContableContainer';
import InformesVM from './common/components/VisitadorMedico/InformesContainer';
import SelConsolidacionEnvio from './common/components/Produccion/Consolidacion/SeleccionarPeriodoContainer';
import ConsolidacionEnvioList from './common/components/Produccion/Consolidacion/ConsolidacionListContainer';
import ConsolidacionEnvioForm from './common/components/Produccion/Consolidacion/ConsolidacionContainer';
import InformesRM from './common/components/RegistroMarca/InformesContainer';
import CalculoAguinaldoNomina from './common/components/Nomina/Aguinaldo/SeleccionarPeriodo';
import FormCalculoAguinaldoNomina from './common/components/Nomina/Aguinaldo/AguinaldoContainer';
import ListadoPrecioInvList from './common/components/Inventario/ListadoPrecios/ListadoPreciosListContainer';
import ListadoPrecioInvForm from './common/components/Inventario/ListadoPrecios/ListadoPreciosContainer';

/* PUNTO DE VENTA */
import PuntoVenta from './common/components/PuntoVenta/PuntoVentaContainer';
import ClienteListPDV from './common/components/PuntoVenta/Clientes/ClientesListContainer';
import ClienteFormPDV from './common/components/PuntoVenta/Clientes/ClientesContainer';
import BodegasListPDV from './common/components/PuntoVenta/Bodegas/BodegasListContainer';
import BodegasFormPDV from './common/components/PuntoVenta/Bodegas/BodegasContainer';
import ProductosListPDV from './common/components/PuntoVenta/Producto/productoListContainer';
import ProductosFormPDV from './common/components/PuntoVenta/Producto/productoContainer';
import PresentacionListPDV from './common/components/PuntoVenta/PresentacionProducto/presentacionProductoListContainer';
import PresentacionFormPDV from './common/components/PuntoVenta/PresentacionProducto/presentacionProductoContainer';
import TipoProductoListPDV from './common/components/PuntoVenta/TipoProducto/tipoProductoListContainer';
import TipoProductoFormPDV from './common/components/PuntoVenta/TipoProducto/tipoProductoContainer';
import UnidadMedidaListPDV from './common/components/PuntoVenta/UnidadMedida/unidadMedidaListContainer';
import UnidadMedidaFormPDV from './common/components/PuntoVenta/UnidadMedida/unidadMedidaContainer';
import FacturacionPos from './common/components/PuntoVenta/FacturacionPos/FacturacionPosContainer';
import SelPeriodoComanda from './common/components/PuntoVenta/Comandas/SeleccionarPeriodoContainer';
import ComandaList from './common/components/PuntoVenta/Comandas/ComandasListContainer';
import SelPeriodoFactura from './common/components/PuntoVenta/Facturas/SeleccionarPeriodoContainer';
import FacturaList from './common/components/PuntoVenta/Facturas/FacturasListContainer';
import InformesPDV from './common/components/PuntoVenta/InformesContainer';

// import TipoDocumentoFacPDV from './common/components/PuntoVenta/TipoDocumento/TipoDocumentoListContainer';
// import TipoDocumentoFormFacPDV from './common/components/PuntoVenta/TipoDocumento/TipoDocumentoContainer';
// import SelFacturacion from './common/components/PuntoVenta/Facturas/SeleccionarPeriodoContainer';
// import FacturacionList from './common/components/PuntoVenta/Facturas/FacturacionListContainer';
// import FacturacionForm from './common/components/PuntoVenta/Facturas/FacturacionContainer';

/* ASSETS */
import '../assets/fonts/fonts.css';
require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';

/* SYTLES */
require('../style/index.css');

module.exports = (
	<div>
		<div className="container__content">
			<Switch>
				<Route exact path="/login" component={valEmp === 'Fage' ? LoginFage : Login} />
				<Route exact path="/registro" component={Registro} />
				<Route exact path="/pro/login_registro" component={LoginRegistro} />
				<Route exact path="/cambiar-password" component={EnviarCorreoContainer} />
				<Route exact path="/confirmacion-envio" component={ConfirmacionEnvio} />
				<Route exact path="/validar/:id/:token" component={CambiarPasswordContainer} />
				<Route exact path="/activar/:id/:token" component={ActivarCuenta} />
				<Route exact path="/verificar/:id/:token" component={ValidarCuenta} />
				<Route exact path="/tools" component={LoginTools} />

				<ProtectedRouteRegistro exact path="/pro/registro" component={RegistroH} />

				<ProtectedRouteEmpresa exact path="/" component={Empresas} />
				<ProtectedRouteEmpresa exact path="/empresas" component={Empresas} />
				<ProtectedRouteEmpresa exact path="/establecimiento/:id" component={Estable} />
				<ProtectedRouteModulos exact path="/empresas/:empresa/modulos" component={Modulos} />

				<ProtectedRouteTools exact path="/tools/home" component={Tools} />
				<ProtectedRouteTools exact path="/tools/home/producto" component={Productos} />
				<ProtectedRouteTools exact path="/tools/home/producto_clasificacion" component={ProductosClasificacion} />
				<ProtectedRouteTools exact path="/tools/home/cuenta_contable" component={Cuentas} />
				<ProtectedRouteTools exact path="/tools/home/proveedor" component={Proveedores} />
				<ProtectedRouteTools exact path="/tools/home/saldos" component={SaldosFacturas} />
				<ProtectedRouteTools exact path="/tools/home/ocupaciones" component={Ocupaciones} />
				<ProtectedRouteTools exact path="/tools/home/paises" component={Paises} />
				<ProtectedRouteTools exact path="/tools/home/lineas_poliza" component={LineasPoliza} />
				<ProtectedRouteTools exact path="/tools/home/empresas" component={ListEmpresasTools} />
				<ProtectedRouteTools exact path="/tools/home/seleccionar_empresa" component={SelEmpresaT} />
				<ProtectedRouteTools exact path="/tools/home/consulta_documento/:id" component={FormDocumentoT} />

				<ProtectedRouteFinanzas exact path="/tools/home/finanzas/" component={FinanzasContainer} />
				<ProtectedRouteFinanzas exact path="/tools/finanzas/empresas" component={FinanzasAgregarEmpresa} />
				<ProtectedRouteFinanzasEstadosFinancieros exact path="/tools/finanzas/estados-financieros" component={FinanzasEstadosFinancierosContainer} />
				<ProtectedRouteFinanzasEstadosFinancieros exact path="/tools/finanzas/estados-financieros/archivos/balance-de-saldos" component={FinanzasArchivosBalanceDeSaldosContainer} />
				<ProtectedRouteFinanzasEstadosFinancieros exact path="/tools/finanzas/estados-financieros/archivos/nomenclatura" component={FinanzasArchivosNomenclaturaContainer} />
				<ProtectedRouteFinanzasEstadosFinancieros exact path="/tools/finanzas/estados-financieros/balance-de-saldos" component={FinanzasBalanceDeSaldosContainer} />
				<ProtectedRouteFinanzasEstadosFinancieros exact path="/tools/finanzas/estados-financieros/cierres-parciales" component={FinanzasCierresParcialesContainer} />
				<ProtectedRouteFinanzasComprasVentas exact path="/tools/finanzas/compras-ventas" component={FinanzasComprasVentasHome} />
				<ProtectedRouteFinanzasComprasVentas exact path="/tools/finanzas/compras-ventas/archivos/contribuyentes" component={FinanzasComprasVentasContribuyentes} />
				<ProtectedRouteFinanzasComprasVentas exact path="/tools/finanzas/compras-ventas/archivos/libro-compras" component={FinanzasCVArchivosLibroCompras} />
				<ProtectedRouteFinanzasComprasVentas exact path="/tools/finanzas/compras-ventas/libro-compras" component={FinanzasCVLibroCompras} />

				{/* ***AJUSTES*** /> */}

				<ProtectedRouteAjustes exact path="/ajustes/:empresa" component={Ajustes} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/empresas" component={ListEmpresa} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/establecimientos" component={ListEstablecimiento} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/usuarios" component={ListUsuarios} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/empresas/crear" component={EmpresaContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/empresas/:id" component={EmpresaContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/empresas/:id/editar" component={EmpresaContainer} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/establecimientos/crear" component={EstablecimientoContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/establecimientos/:id" component={EstablecimientoContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/establecimientos/:id/editar" component={EstablecimientoContainer} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/parametros" component={ParametrosContainer} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/usuarios/crear" component={UsuarioForm} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/usuarios/:id" component={UsuarioForm} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/usuarios/:id/editar" component={UsuarioForm} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/monedas" component={MonedaList} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/monedas/crear" component={MonedaForm} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/monedas/:id" component={MonedaForm} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/monedas/:id/editar" component={MonedaForm} />

				<ProtectedRouteAjustes exact path="/ajustes/:empresa/impuestos" component={ImpuestoForm} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/impuestos/:id/editar" component={ImpuestoFormMod} />
				<ProtectedRouteAjustes exact path="/ajustes/:empresa/ajuste_modulo" component={ConfigModuloContainer} />

				{/* ***CONTABILIDAD*** /> */}

				<ProtectedRouteConta exact path="/contabilidad/:empresa" component={Conta} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/niveles" component={NivelesContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/cuentas_contables" component={CuentasContables} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/cuentas_contables/crear" component={CuentasContablesForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/cuentas_contables/:id" component={CuentasContablesForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/cuentas_contables/:id/editar" component={CuentasContablesForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/centro_costo" component={CentroCostoContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/centro_costo/crear" component={CentroCostoFormContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/centro_costo/:id" component={CentroCostoFormContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/centro_costo/:id/editar" component={CentroCostoFormContainer} />

				<ProtectedRouteConta exact path="/contabilidad/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/periodos/:id/editar" component={PeriodoContainer} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/config" component={ConfigContaContainer} />

				<ProtectedRouteConta exact path="/contabilidad/:empresa/polizas/:periodo" component={Polizas} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/seleccionar" component={SelPeriodo} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/polizas/:periodo/crear" component={PolizaForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/polizas/:periodo/:id" component={PolizaForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/polizas/:periodo/:id/editar" component={PolizaForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/polizas/:periodo/:id/editar/:id_detalle" component={PolizaForm} />
				<ProtectedRouteConta exact path="/contabilidad/:empresa/informes" component={Informes} />

				{/* ***LIBROS CONTABLES*** /> */}

				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa" component={LibroLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/traslado/" component={TrasladoLC} />

				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/polizas/:periodo" component={PolizasLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/seleccionar" component={SelPeriodo} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/polizas/:periodo/crear" component={PolizaFormLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/polizas/:periodo/:id" component={PolizaFormLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/polizas/:periodo/:id/editar" component={PolizaFormLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/polizas/:periodo/:id/editar/:id_detalle" component={PolizaFormLC} />
				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/informes" component={InformesLC} />

				<ProtectedRouteLibrosContables exact path="/libros_contables/:empresa/intercambiar_cuentas" component={IntercambioCuentaLC} />

				{/* ***COMPRAS*** /> */}

				<ProtectedRouteCompras exact path="/compras/:empresa" component={Compras} />
				<ProtectedRouteCompras exact path="/compras/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteCompras exact path="/compras/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteCompras exact path="/compras/:empresa/informes" component={InformesCompras} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipos" component={TipoDocumento} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipos/crear" component={TipoDocumentoForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipos/:id" component={TipoDocumentoForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipos/:id/editar" component={TipoDocumentoForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteCompras exact path="/compras/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteCompras exact path="/compras/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteCompras exact path="/compras/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteCompras exact path="/compras/:empresa/proveedores" component={ProveedorList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/proveedores/crear" component={ProveedorForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/proveedores/:id" component={ProveedorForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/proveedores/:id/editar" component={ProveedorForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/seleccionar" component={SelCompras} />
				<ProtectedRouteCompras exact path="/compras/:empresa/compras/:periodo" component={ComprasList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/compras/:periodo/crear" component={ComprasForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/compras/:periodo/:id" component={ComprasForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/compras/:periodo/:id/editar" component={ComprasForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/liquidacion_gastos/:periodo" component={LiqGastosList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/liquidacion_gastos/:periodo/crear" component={LiqGastosForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/liquidacion_gastos/:periodo/:id" component={LiqGastosForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/liquidacion_gastos/:periodo/:id/editar" component={LiqGastosForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/importaciones/:periodo" component={ImportacionList} />
				<ProtectedRouteCompras exact path="/compras/:empreas/importaciones/:periodo/crear" component={ImportacionForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/importaciones/:periodo/:id" component={ImportacionForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/importaciones/:periodo/:id/editar" component={ImportacionForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/buscar_factura" component={BuscarFactura} />
				<ProtectedRouteCompras exact path="/compras/:empresa/asignar_proyecto/:id/editar" component={AsignarProyecto} />

				<ProtectedRouteCompras exact path="/compras/:empresa/abonos" component={SelAbonosPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/abonos/:periodo" component={AbonosListPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/abonos/:periodo/crear" component={AbonosPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/abonos/:periodo/:id" component={AbonosPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/abonos/:periodo/:id/editar" component={AbonosPro} />

				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_abono" component={TipoAbono} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_abono/crear" component={TipoAbonoForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_abono/:id" component={TipoAbonoForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_abono/:id/editar" component={TipoAbonoForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_cambio" component={SelPeriodoTC} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_cambio/:periodo" component={ListTipoCambio} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_cambio/:periodo/crear" component={TipoCambioForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/tipo_cambio/:periodo/:id/editar" component={TipoCambioForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/seleccionar" component={SelFactEspecial} />
				<ProtectedRouteCompras exact path="/compras/:empresa/factura_especial_fel/:periodo" component={FacturaEspecialList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/factura_especial_fel/:periodo/crear/:estable" component={FacturaEspecialForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/factura_especial_fel/:periodo/:id" component={FacturaEspecialForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/factura_especial_fel/:periodo/:id/editar" component={FacturaEspecialForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/saldos" component={SaldosListCompras} />
				<ProtectedRouteCompras exact path="/compras/:empresa/saldos/crear" component={SaldosCompraForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/saldos/:id" component={SaldosCompraForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/saldos/:id/editar" component={SaldosCompraForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/anticipos" component={SelPeriodoAnticipo} />
				<ProtectedRouteCompras exact path="/compras/:empresa/anticipos/:periodo" component={AnticipoList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/anticipos/:periodo/crear" component={AnticipoForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/anticipos/:periodo/:id" component={AnticipoForm} />

				<ProtectedRouteCompras exact path="/compras/:empresa/nota_credito/:periodo" component={NCListPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/nota_credito/:periodo/crear" component={NCFormPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/nota_credito/:periodo/:id" component={NCFormPro} />
				<ProtectedRouteCompras exact path="/compras/:empresa/nota_credito/:periodo/:id/editar" component={NCFormPro} />

				<ProtectedRouteCompras exact path="/compras/:empresa/seleccionar_orden" component={SelOrdenCompra} />
				<ProtectedRouteCompras exact path="/compras/:empresa/orden_compra/:periodo" component={OrdenCompraList} />
				<ProtectedRouteCompras exact path="/compras/:empresa/orden_compra/:periodo/crear" component={OrdenCompraForm} />
				<ProtectedRouteCompras exact path="/compras/:empresa/orden_compra/:periodo/:id" component={OrdenCompraForm} />

				{/* ***BANCOS*** /> */}

				<ProtectedRouteBancos exact path="/bancos/:empresa" component={Bancos} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/periodos/:id/editar" component={PeriodoContainer} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/bancos" component={BancosList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/bancos/crear" component={BancosForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/bancos/:id" component={BancosForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/bancos/:id/editar" component={BancosForm} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/cuenta_bancaria" component={CuentasBancariasList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cuenta_bancaria/crear" component={CuentasBancariasForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cuenta_bancaria/:id" component={CuentasBancariasForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cuenta_bancaria/:id/editar" component={CuentasBancariasForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/seleccionar_egreso" component={SelEgresos} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/cheques/:periodo" component={ChequesList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cheques/:periodo/seleccionar_cuenta" component={SelCuentaB} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cheques/:periodo/crear/:cuenta" component={ChequesForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cheques/:periodo/:id" component={ChequesForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/cheques/:periodo/:id/editar" component={ChequesForm} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_debito/:periodo" component={NDList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_debito/:periodo/seleccionar_cuenta" component={SelCuentaBND} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_debito/:periodo/crear/:cuenta" component={NDForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_debito/:periodo/:id" component={NDForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_debito/:periodo/:id/editar" component={NDForm} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_credito/:periodo" component={NCList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_credito/:periodo/seleccionar_cuenta" component={SelCuentaBNC} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_credito/:periodo/crear/:cuenta" component={NCForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_credito/:periodo/:id" component={NCForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/nota_credito/:periodo/:id/editar" component={NCForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/seleccionar_ingreso" component={SelIngresos} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito/:periodo" component={DepositoList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito/:periodo/seleccionar_cuenta" component={SelCuentaBDEP} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito/:periodo/crear/:cuenta" component={DepositoForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito/:periodo/:id" component={DepositoForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito/:periodo/:id/editar" component={DepositoForm} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/informes" component={InformesBancos} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/conciliacion" component={Conciliacion} />

				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito_recibo/:periodo" component={DepositoReciboList} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito_recibo/:periodo/seleccionar_cuenta" component={SelCuentaDepRecibo} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito_recibo/:periodo/crear/:cuenta" component={FormDepReciboProd} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito_recibo/:periodo/:id" component={FormDepReciboProd} />
				<ProtectedRouteBancos exact path="/bancos/:empresa/deposito_recibo/:periodo/:id/editar" component={FormDepReciboProd} />

				{/* ***FACTURACION*** /> */}

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa" component={Facturacion} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/informes" component={InformesVentas} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipos" component={TipoDocumentoFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipos/crear" component={TipoDocumentoFormFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipos/:id" component={TipoDocumentoFormFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipos/:id/editar" component={TipoDocumentoFormFac} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empreas/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/clientes" component={ClienteList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/clientes/crear" component={ClienteForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/clientes/:id" component={ClienteForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/clientes/:id/editar" component={ClienteForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas" component={SelFacturacion} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo" component={FacturacionList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/crear/:estable" component={FacturacionForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id" component={FacturacionForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id/editar" component={FacturacionForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/crear/:estable/bienes" component={FacturacionInvForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id/bienes" component={FacturacionInvForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id/editar/bienes" component={FacturacionInvForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/crear/:estable/servicios" component={FacturacionServForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id/servicios" component={FacturacionServForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/facturas/:periodo/:id/editar/servicios" component={FacturacionServForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/servicios_fel" component={FacturacionServFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/servicios_fel" component={FacturacionServFELForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/bienes_envio" component={FacturacionFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/bienes_envio" component={FacturacionFELForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/bienes_farm_fel" component={FacturacionINVBFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/bienes_farm_fel" component={FacturacionINVBFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/servicios_farm_fel" component={FacturacionSerFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/servicios_farm_fel" component={FacturacionSerFELForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/bienes_s_envio" component={FacturacionFELBienesForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/bienes_s_envio" component={FacturacionFELBienesForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/bienes_farm" component={FacturacionINVBForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/bienes_farm" component={FacturacionINVBForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/editar/bienes_farm" component={FacturacionINVBForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/servicios_farm" component={FacturacionSerFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/servicios_farm" component={FacturacionSerFELForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/saldos" component={SaldosList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/saldos/crear/:estable" component={SaldosForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/saldos/:id" component={SaldosForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/saldos/:id/editar" component={SaldosForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/abonos" component={SelAbonos} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/abonos/:periodo" component={AbonosList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/abonos/:periodo/crear" component={Abonos} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/abonos/:periodo/:id" component={Abonos} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/abonos/:periodo/:id/editar" component={Abonos} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/listado_precios" component={ListadoPrecioList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/listado_precios/crear" component={ListadoPrecioForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/listado_precios/:id" component={ListadoPrecioForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/listado_precios/:id/editar" component={ListadoPrecioForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_cambio" component={SelPeriodoTC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_cambio/:periodo" component={ListTipoCambio} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_cambio/:periodo/crear" component={TipoCambioForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_cambio/:periodo/:id/editar" component={TipoCambioForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_abono" component={TipoAbonoFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_abono/crear" component={TipoAbonoFormFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_abono/:id" component={TipoAbonoFormFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/tipo_abono/:id/editar" component={TipoAbonoFormFac} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_credito" component={SelNC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_credito/:periodo" component={NotaCreditoList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_credito/:periodo/crear/:estable" component={NotaCreditoForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_credito/:periodo/:id" component={NotaCreditoForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_credito/:periodo/:id/editar" component={NotaCreditoForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/notas_credito" component={SelPeriodoNC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/notas_credito/:periodo/crear/:estable" component={NotaCreForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/notas_credito/:periodo/:id" component={NotaCreForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/notas_credito/:periodo/:id/editar" component={NotaCreForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_debito" component={SelND} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_debito/:periodo" component={NotaDebitoList} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_debito/:periodo/crear/:estable" component={NotaDebitoForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_debito/:periodo/:id" component={NotaDebitoForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/nota_debito/:periodo/:id/editar" component={NotaDebitoForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/anticipos_cxc" component={SelPeriodoAnticipoCXC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/anticipos_cxc/:periodo" component={AnticipoListCXC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/anticipos_cxc/:periodo/crear" component={AnticipoFormCXC} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/anticipos_cxc/:periodo/:id" component={AnticipoFormCXC} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/crear/:estable/fel" component={BienesServiciosFELForm} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/factura/:periodo/:id/fel" component={BienesServiciosFELForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/sel_poliza_parqueo" component={SelPolizaParqueoFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/poliza_parqueo/:periodo" component={ListPolizaParqueoFac} />
				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/poliza_parqueo_diario/:periodo" component={GenerarPolizaParqueoForm} />

				<ProtectedRouteFacturacion exact path="/facturacion/:empresa/poliza_parqueo_diario/:periodo/:id" component={PolizaForm} />


				{/* ***INVENTARIO*** /> */}

				<ProtectedRouteInventario exact path="/inventario/:empresa" component={Inventario} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/bodegas" component={BodegasList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/bodegas/crear" component={Bodegas} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/bodegas/:id" component={Bodegas} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/bodegas/:id/editar" component={Bodegas} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/unidad_medida" component={UnidadMedidaList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/unidad_medida/crear" component={UnidadMedida} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/unidad_medida/:id" component={UnidadMedida} />
				<ProtectedRouteInventario exact path="/inventario/:empreas/unidad_medida/:id/editar" component={UnidadMedida} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_producto" component={TipoProductoList} />
				<ProtectedRouteInventario exact path="/inventario/:empreas/tipo_producto/crear" component={TipoProducto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_producto/:id" component={TipoProducto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_producto/:id/editar" component={TipoProducto} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/presentacion_producto" component={PresentacionProductoList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/presentacion_producto/crear" component={PresentacionProducto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/presentacion_producto/:id" component={PresentacionProducto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/presentacion_producto/:id/editar" component={PresentacionProducto} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/producto" component={ProductoList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/producto/crear" component={Producto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/producto/:id" component={Producto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/producto/:id/editar" component={Producto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/producto/:id/combo" component={Combo} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/seleccionar" component={SelRegistro} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/entradas/:periodo" component={EntradasList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/entradas/:periodo/crear" component={EntradasForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/entradas/:periodo/:id" component={EntradasForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/entradas/:periodo/:id/editar" component={EntradasForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/seleccionar" component={SelRegistro} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/salidas/:periodo" component={SalidasList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/salidas/:periodo/crear" component={SalidasForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/salidas/:periodo/:id" component={SalidasForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/salidas/:periodo/:id/editar" component={SalidasForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/informes" component={InformesInventario} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/costo" component={SelPeriodoCosto} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/ingreso_inventario/:periodo" component={IngresoInventarioList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/ingreso_inventario/:periodo/crear" component={IngresoInventarioForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/ingreso_inventario/:periodo/:id" component={IngresoInventarioForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/seleccionar_orden_servicio" component={SelOrdenServicio} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/orden_servicio/:periodo" component={OrdenServicioList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/orden_servicio/:periodo/crear" component={OrdenServicioForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/orden_servicio/:periodo/:id" component={OrdenServicioForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/orden_servicio/:periodo/:id/editar" component={OrdenServicioForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_gasto" component={TipoGastoListINV} />
				<ProtectedRouteInventario exact path="/inventario/:empreas/tipo_gasto/crear" component={TipoGastoINV} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_gasto/:id" component={TipoGastoINV} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/tipo_gasto/:id/editar" component={TipoGastoINV} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/seleccionar_gastos_proyecto" component={SelGastoProyecto} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/gastos_proyecto/:periodo" component={GastoProyectoList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/gastos_proyecto/:periodo/crear" component={GastoProyectoForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/gastos_proyecto/:periodo/:id" component={GastoProyectoForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/gastos_proyecto/:periodo/:id/editar" component={GastoProyectoForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/consumo_producto/:periodo" component={ConsumoINVList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/consumo_producto/:periodo/crear" component={ConsumoINVForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/consumo_producto/:periodo/:id" component={ConsumoINVForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/listado_precios" component={ListadoPrecioInvList} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/listado_precios/crear" component={ListadoPrecioInvForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/listado_precios/:id" component={ListadoPrecioInvForm} />
				<ProtectedRouteInventario exact path="/inventario/:empresa/listado_precios/:id/editar" component={ListadoPrecioInvForm} />

				<ProtectedRouteInventario exact path="/inventario/:empresa/cierre_proyecto" component={CierreProyectoINV} />

				{/* ***INMOBILIARIO*** /> */}

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa" component={Inmobiliaria} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/cambiar-pass" component={CambiarPass} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/informes" component={InformesInmobiliaria} />

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/proyectos" component={ProyectosList} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/proyectos/crear" component={Proyectos} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/proyectos/:id" component={Proyectos} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/proyectos/:id/editar" component={Proyectos} />

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/tipo_obra" component={TipoObraList} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/tipo_obra/crear" component={TipoObra} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/tipo_obra/:id" component={TipoObra} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/tipo_obra/:id/editar" component={TipoObra} />

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/subproyectos" component={SubProyectoList} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/subproyectos/crear" component={SubProyecto} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/subproyectos/:id" component={SubProyecto} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/subproyectos/:id/editar" component={SubProyecto} />

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/fases" component={FaseList} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/fases/crear" component={Fase} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/fases/:id" component={Fase} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/fases/:id/editar" component={Fase} />

				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/materiales" component={MaterialList} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/materiales/crear" component={Material} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/materiales/:id" component={Material} />
				<ProtectedRouteInmobiliaria exact path="/inmobiliaria/:empresa/materiales/:id/editar" component={Material} />

				{/* ***PRODUCCION*** /> */}

				<ProtectedRouteProduccion exact path="/produccion/:empresa" component={Produccion} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/centro_trabajo" component={CentroTrabajoList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/centro_trabajo/crear" component={CentroTrabajoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/centro_trabajo/:id" component={CentroTrabajoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/centro_trabajo/:id/editar" component={CentroTrabajoForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/fase" component={FaseProList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/fase/crear" component={FaseProForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/fase/:id" component={FaseProForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/fase/:id/editar" component={FaseProForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/insumo" component={InsumoList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/insumo/crear" component={InsumoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/insumo/:id" component={InsumoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/insumo/:id/editar" component={InsumoForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_ingreso" component={SelProd} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/ingreso/:periodo" component={IngresoPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/ingreso/:periodo/crear" component={IngresoPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/ingreso/:periodo/:id" component={IngresoPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/ingreso/:periodo/:id/editar" component={IngresoPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_envio" component={SelEnvio} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/envio/:periodo" component={EnvioPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/envio/:periodo/crear" component={EnvioPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/envio/:periodo/:id" component={EnvioPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/envio/:periodo/:id/editar" component={EnvioPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/series" component={SeriesPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/informes" component={InformesProduccion} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_devolucion_cajas" component={SelDevCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_cajas/:periodo/" component={DevCajasList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_cajas/:periodo/crear" component={FormDevCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_cajas/:periodo/:id" component={FormDevCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_cajas/:periodo/:id/editar" component={FormDevCajas} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/invernaderos" component={InvernaderoList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/invernaderos/crear" component={InvernaderoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/invernaderos/:id" component={InvernaderoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/invernaderos/:id/editar" component={InvernaderoForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_distribucion_cajas" component={SelDistCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/distribucion_cajas/:periodo/" component={DistCajasList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/distribucion_cajas/:periodo/crear" component={FormDistCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/distribucion_cajas/:periodo/:id" component={FormDistCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/distribucion_cajas/:periodo/:id/editar" component={FormDistCajas} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_compra_cajas" component={SelIngCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/compra_cajas/:periodo/" component={IngCajasList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/compra_cajas/:periodo/crear" component={IngCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/compra_cajas/:periodo/:id" component={IngCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/compra_cajas/:periodo/:id/editar" component={IngCajasForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_baja_cajas" component={SelBajaCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/baja_cajas/:periodo/" component={BajaCajasList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/baja_cajas/:periodo/crear" component={BajaCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/baja_cajas/:periodo/:id" component={BajaCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/baja_cajas/:periodo/:id/editar" component={BajaCajasForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_siembra" component={SelSiembra} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/siembra/:periodo/" component={SiembraList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/siembra/:periodo/crear" component={SiembraForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/siembra/:periodo/:id" component={SiembraForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/siembra/:periodo/:id/editar" component={SiembraForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/fecha_cosecha" component={FechaSiembra} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/fecha_final" component={FechaFinSiembra} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/tipo_gasto" component={TipoGastoList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/tipo_gasto/crear" component={TipoGastoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/tipo_gasto/:id" component={TipoGastoForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/tipo_gasto/:id/editar" component={TipoGastoForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_gasto" component={SelGastoI} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/gasto/:periodo/" component={GastoIList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/gasto/:periodo/crear" component={GastoIForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/gasto/:periodo/:id" component={GastoIForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/gasto/:periodo/:id/editar" component={GastoIForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_nota" component={NotaCobroPRDSel} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/nota_cobro/:periodo" component={NotaCobroPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/nota_cobro/:periodo/crear" component={NotaCobroPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/nota_cobro/:periodo/:id" component={NotaCobroPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/nota_cobro/:periodo/:id/editar" component={NotaCobroPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/piloto" component={PilotoProList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/piloto/crear" component={PilotoProForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/piloto/:id" component={PilotoProForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/piloto/:id/editar" component={PilotoProForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_consumo" component={SelConsumo} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consumo/:periodo" component={ConsumoPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consumo/:periodo/crear" component={ConsumoPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consumo/:periodo/:id" component={ConsumoPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consumo/:periodo/:id/editar" component={ConsumoPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_factura" component={FacturaPRDSel} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/factura/:periodo" component={FacturaPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/factura/:periodo/crear/:estable" component={FacturaPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/factura/:periodo/:id" component={FacturaPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/factura/:periodo/:id/editar" component={FacturaPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_devolucion_producto" component={SelDevProd} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_producto/:periodo" component={DevProPRDList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_producto/:periodo/crear" component={DevProPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_producto/:periodo/:id" component={DevProPRDForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/devolucion_producto/:periodo/:id/editar" component={DevProPRDForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_recibo_caja" component={SelReciboPrd} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/recibo_caja/:periodo" component={ReciboCajaList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/recibo_caja/:periodo/crear" component={ReciboCajaForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/recibo_caja/:periodo/:id" component={ReciboCajaForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_corte_caja/" component={SelCorteCajaPrd} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/corte_caja/:periodo" component={CorteCajaList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/corte_caja/:periodo/crear" component={CorteCaja} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/corte_caja/:periodo/:id" component={CorteCaja} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_traslado_cajas" component={SelTrasladoCajas} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/traslado_cajas/:periodo/" component={TrasladoCajasList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/traslado_cajas/:periodo/crear" component={TrasladoCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/traslado_cajas/:periodo/:id" component={TrasladoCajasForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/traslado_cajas/:periodo/:id/editar" component={TrasladoCajasForm} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/cuentas_sin_incluir" component={CuentaContablePRD} />

				<ProtectedRouteProduccion exact path="/produccion/:empresa/seleccionar_periodo_consolidacion_envio" component={SelConsolidacionEnvio} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consolidacion_envio/:periodo" component={ConsolidacionEnvioList} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consolidacion_envio/:periodo/crear" component={ConsolidacionEnvioForm} />
				<ProtectedRouteProduccion exact path="/produccion/:empresa/consolidacion_envio/:periodo/:id" component={ConsolidacionEnvioForm} />

				{/* ***RRHH*** /> */}

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa" component={RRHH} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/departamento" component={DepartamentoList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/departamento/crear" component={Departamento} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/departamento/:id" component={Departamento} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/departamento/:id/editar" component={Departamento} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/puesto" component={PuestoList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/puesto/crear" component={Puesto} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/puesto/:id" component={Puesto} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/puesto/:id/editar" component={Puesto} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/seleccionar" component={SelPais} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/ingreso/:pais" component={ColaboradorList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/ingreso/:pais/crear" component={Colaborador} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/ingreso/:pais/:id" component={Colaborador} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/ingreso/:pais/:id/editar" component={Colaborador} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/pais" component={PaisList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/pais/crear" component={Pais} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/pais/:id" component={Pais} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/pais/:id/editar" component={Pais} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/asignar_tipo_documento/:id/editar" component={AsignarTipoDocumento} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/seleccionar_pais_baja" component={SelPaisBaja} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja/:pais" component={ColaboradorBajaList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja/:pais/crear" component={BajaForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja/:pais/:id" component={Colaborador} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/liquidacion_laboral" component={LiquidacionLaboralList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/liquidacion_laboral/crear" component={LiquidacionLaboralForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/liquidacion_laboral/:id" component={LiquidacionLaboralForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/liquidacion_laboral/:id/editar" component={LiquidacionLaboralForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/aumento_salario" component={AumentoSalarioForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/vacaciones" component={VacacionesForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/cambio_puesto" component={CambioPuestoForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/corte_vacaciones" component={CorteVacacionesForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/informes" component={InformesRRHH} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/seleccionar_periodo_isr" component={SeleccionarPeriodoISR} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/isr/:anio" component={ListIsr} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/isr/:anio/crear" component={FormIsr} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/isr/:anio/:id" component={FormIsr} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/isr/:anio/:id/editar" component={FormIsr} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/tipo_descuento_rr_hh" component={ListTipoDescuentoRH} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/tipo_descuento_rr_hh/crear" component={FormTipoDescuentoRH} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/tipo_descuento_rr_hh/:id" component={FormTipoDescuentoRH} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/tipo_descuento_rr_hh/:id/editar" component={FormTipoDescuentoRH} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/descuentos_fijos" component={ListDescuentoFijo} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/descuentos_fijos/crear" component={FormDescuentoFijo} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/descuentos_fijos/:id" component={FormDescuentoFijo} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/descuentos_fijos/:id/editar" component={FormDescuentoFijo} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja_igss" component={BajaIgssList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja_igss/crear" component={BajaIgssForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja_igss/:id" component={BajaIgssForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/baja_igss/:id/editar" component={BajaIgssForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/alta_igss" component={AltaIgssList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/alta_igss/crear" component={AltaIgssForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/alta_igss/:id" component={AltaIgssForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/alta_igss/:id/editar" component={AltaIgssForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/prestamos" component={PrestamoList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/prestamos/crear" component={PrestamoForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/prestamos/:id" component={PrestamoForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/prestamos/:id/editar" component={PrestamoForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/seleccionar_periodo_faltas" component={SeleccionarPeriodoFalta} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/faltas/:periodo" component={FaltaList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/faltas/:periodo/crear" component={FaltaForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/faltas/:periodo/:id" component={FaltaForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/faltas/:periodo/:id/editar" component={FaltaForm} />

				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/judicial" component={JudicialList} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/judicial/crear" component={JudicialForm} />
				<ProtectedRouteRRHH exact path="/rr_hh/:empresa/judicial/:id" component={JudicialForm} />

				{/* ***AUTOVENTAS*** /> */}

				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa" component={AutoVentas} />

				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ingresos" component={IngresosListContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ingresos/crear" component={IngresosFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ingresos/editar/:ingresoId" component={IngresosFormEditarContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ingresos/ver/:ingresoId" component={IngresosFormVerContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ingresos/ver/:ingresoId" component={IngresosFormVerContainer} />

				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/vendedores" component={VendedoresListContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/vendedores/crear" component={VendedoresFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/vendedores/editar/:vendedorId" component={VendedoresFormEditarContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/vendedores/ver/:vendedorId" component={VendedoresFormVerContainer} />

				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ventas" component={VentasListContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ventas/crear" component={VentasFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ventas/ver/:ventaId" component={VentasFormVerContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/ventas/editar/:ventaId" component={VentasFormEditarContainer} />

				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/marcas" component={MarcasListFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/lineas" component={LineaListFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/tipo" component={TipoListFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/combustible" component={CombustibleListFormContainer} />
				<ProtectedRouteAutoVentas exact path="/auto_ventas/:empresa/informes" component={IngresosInformesContainer} />

				{/* ***SEGUROS*** */}
				<ProtectedRouteSeguros exact path="/seguros/:empresa/" component={SegurosHome} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/ficha-clientes" component={SegurosFichaClientes} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/ficha-clientes/crear" component={SegurosFichaClientesCrear} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/ficha-clientes/ver/:clienteId" component={SegurosFichaClientesVer} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/ficha-clientes/editar/:clienteId" component={SegurosFichaClientesEditar} />

				<ProtectedRouteSeguros exact path="/seguros/:empresa/seguro" component={SegurosSeguroListado} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/seguro/crear" component={SegurosSeguroCrear} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/seguro/ver/:seguroId" component={SegurosSeguroVer} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/seguro/editar/:seguroId" component={SegurosSeguroEditar} />

				<ProtectedRouteSeguros exact path="/seguros/:empresa/vehiculos/" component={SegurosVehiculosListado} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/vehiculos/crear" component={SegurosVehiculosCrear} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/vehiculos/editar/:vehiculoId" component={SegurosVehiculosEditar} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/vehiculos/ver/:vehiculoId" component={SegurosVehiculosVer} />

				<ProtectedRouteSeguros exact path="/seguros/:empresa/aseguradoras/" component={SegurosAseguradorasPaginas.Listado} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/aseguradoras/crear" component={SegurosAseguradorasPaginas.Crear} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/aseguradoras/editar/:aseguradoraId" component={SegurosAseguradorasPaginas.Editar} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/aseguradoras/ver/:aseguradoraId" component={SegurosAseguradorasPaginas.Ver} />

				<ProtectedRouteSeguros exact path="/seguros/:empresa/agentes" component={SegurosAgentesListado} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/agentes/crear" component={SegurosAgentesCrear} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/agentes/ver/:agenteId" component={SegurosAgentesVer} />
				<ProtectedRouteSeguros exact path="/seguros/:empresa/agentes/editar/:agenteId" component={SegurosAgentesEditar} />

				<ProtectedRouteSeguros exact path="/seguros/:empresa/informes" component={SegurosInformes} />


				{/* ***LABORATORIO*** */}
				<ProtectedRouteLaboratorio exact title="" path="/laboratorio/:empresa" component={LaboratorioHome} />
				<ProtectedRouteLaboratorio exact title="Clientes" path="/laboratorio/:empresa/clientes" component={LaboratorioClientesPaginas.Listado} />
				<ProtectedRouteLaboratorio exact title="Clientes" path="/laboratorio/:empresa/clientes/crear" component={LaboratorioClientesPaginas.Crear} />
				<ProtectedRouteLaboratorio exact title="Clientes" path="/laboratorio/:empresa/clientes/editar/:clienteId" component={LaboratorioClientesPaginas.Editar} />
				<ProtectedRouteLaboratorio exact title="Clientes" path="/laboratorio/:empresa/clientes/ver/:clienteId" component={LaboratorioClientesPaginas.Ver} />

				<ProtectedRouteLaboratorio exact title="Empresa" path="/laboratorio/:empresa/empresa/" component={LaboratorioEmpresa.Crear} />
				<ProtectedRouteLaboratorio exact title="Ajustes" path="/laboratorio/:empresa/ajustes/" component={LaboratorioAjustes.Crear} />
				<ProtectedRouteLaboratorio exact title="Permisos" path="/laboratorio/:empresa/permisos/" component={LaboratorioPermisos.Editar} />

				<ProtectedRouteLaboratorio exact title="Pacientes" path="/laboratorio/:empresa/pacientes" component={LaboratorioPacientesPaginas.Listado} />
				<ProtectedRouteLaboratorio exact title="Pacientes" path="/laboratorio/:empresa/pacientes/crear" component={LaboratorioPacientesPaginas.Crear} />
				<ProtectedRouteLaboratorio exact title="Pacientes" path="/laboratorio/:empresa/pacientes/editar/:pacienteId" component={LaboratorioPacientesPaginas.Editar} />
				<ProtectedRouteLaboratorio exact title="Pacientes" path="/laboratorio/:empresa/pacientes/ver/:pacienteId" component={LaboratorioPacientesPaginas.Ver} />

				<ProtectedRouteLaboratorio exact title="Unidad IGSS" path="/laboratorio/:empresa/unidad-igss" component={LaboratorioUnidadIgssPaginas.Listado} />
				<ProtectedRouteLaboratorio exact title="Unidad IGSS" path="/laboratorio/:empresa/unidad-igss/crear" component={LaboratorioUnidadIgssPaginas.Crear} />
				<ProtectedRouteLaboratorio exact title="Unidad IGSS" path="/laboratorio/:empresa/unidad-igss/editar/:unidadId" component={LaboratorioUnidadIgssPaginas.Editar} />
				<ProtectedRouteLaboratorio exact title="Unidad IGSS" path="/laboratorio/:empresa/unidad-igss/ver/:unidadId" component={LaboratorioUnidadIgssPaginas.Ver} />

				<ProtectedRouteLaboratorio exact title="Ingreso de patologia" path="/laboratorio/:empresa/ingreso-de-patologia" component={LaboratorioIngresoDePatologiaPaginas.Listado} />
				<ProtectedRouteLaboratorio exact title="Ingreso de patologia" path="/laboratorio/:empresa/ingreso-de-patologia/crear" component={LaboratorioIngresoDePatologiaPaginas.Crear} />
				<ProtectedRouteLaboratorio exact title="Ingreso de patologia" path="/laboratorio/:empresa/ingreso-de-patologia/editar/:ingresoId" component={LaboratorioIngresoDePatologiaPaginas.Editar} />
				<ProtectedRouteLaboratorio exact title="Ingreso de patologia" path="/laboratorio/:empresa/ingreso-de-patologia/ver/:ingresoId" component={LaboratorioIngresoDePatologiaPaginas.Ver} />

				<ProtectedRouteLaboratorio exact title="Laminas" path="/laboratorio/:empresa/laminas" component={LaboratorioLaminas.Listado} />
				<ProtectedRouteLaboratorio exact title="Laminas" path="/laboratorio/:empresa/laminas/crear" component={LaboratorioLaminas.Crear} />
				<ProtectedRouteLaboratorio exact title="Laminas" path="/laboratorio/:empresa/laminas/editar/:laminaId" component={LaboratorioLaminas.Editar} />
				<ProtectedRouteLaboratorio exact title="Laminas" path="/laboratorio/:empresa/laminas/ver/:laminaId" component={LaboratorioLaminas.Ver} />

				<ProtectedRouteLaboratorio exact title="Informes de patologia" path="/laboratorio/:empresa/informes-patologicos" component={LaboratorioInformes.Listado} />
				<ProtectedRouteLaboratorio exact title="Informes de patologia" path="/laboratorio/:empresa/informes-patologicos/crear/afiliada/" component={LaboratorioInformes.Afiliada.Crear} />
				<ProtectedRouteLaboratorio exact title="Informes de patologia" path="/laboratorio/:empresa/informes-patologicos/editar/afiliada/:informeId" component={LaboratorioInformes.Afiliada.Editar} />
				<ProtectedRouteLaboratorio exact title="Informes de patologia" path="/laboratorio/:empresa/informes-patologicos/ver/afiliada/:informeId" component={LaboratorioInformes.Afiliada.Ver} />

				<ProtectedRouteLaboratorio exact title="Informe citologia" path="/laboratorio/:empresa/informes-patologicos/crear/citologia/" component={LaboratorioInformes.Citologia.Crear} />
				<ProtectedRouteLaboratorio exact title="Informe citologia" path="/laboratorio/:empresa/informes-patologicos/editar/citologia/:informeId" component={LaboratorioInformes.Citologia.Editar} />
				<ProtectedRouteLaboratorio exact title="Informe citologia" path="/laboratorio/:empresa/informes-patologicos/ver/citologia/:informeId" component={LaboratorioInformes.Citologia.Ver} />

				<ProtectedRouteLaboratorio exact title="Informe inmunohistoquimica" path="/laboratorio/:empresa/informes-patologicos/crear/inmunohistoquimica/" component={LaboratorioInformes.Inmunohistoquimica.Crear} />
				<ProtectedRouteLaboratorio exact title="Informe inmunohistoquimica" path="/laboratorio/:empresa/informes-patologicos/editar/inmunohistoquimica/:informeId" component={LaboratorioInformes.Inmunohistoquimica.Editar} />
				<ProtectedRouteLaboratorio exact title="Informe inmunohistoquimica" path="/laboratorio/:empresa/informes-patologicos/ver/inmunohistoquimica/:informeId" component={LaboratorioInformes.Inmunohistoquimica.Ver} />

				<ProtectedRouteLaboratorio exact title="Informe privada" path="/laboratorio/:empresa/informes-patologicos/crear/privada/" component={LaboratorioInformes.Privada.Crear} />
				<ProtectedRouteLaboratorio exact title="Informe privada" path="/laboratorio/:empresa/informes-patologicos/editar/privada/:informeId" component={LaboratorioInformes.Privada.Editar} />
				<ProtectedRouteLaboratorio exact title="Informe privada" path="/laboratorio/:empresa/informes-patologicos/ver/privada/:informeId" component={LaboratorioInformes.Privada.Ver} />

				<ProtectedRouteLaboratorio exact path="/laboratorio/:empresa/laminas/editar/:laminaId" component={LaboratorioInformes.Editar} />

				<ProtectedRouteLaboratorio exact title="Periodos" path="/laboratorio/:empresa/periodos" component={LaboratorioPeriodos.Listado} />
				<ProtectedRouteLaboratorio exact title="Periodos" path="/laboratorio/:empresa/periodos/crear" component={LaboratorioPeriodos.Crear} />
				<ProtectedRouteLaboratorio exact title="Periodos" path="/laboratorio/:empresa/periodos/editar/:periodoId" component={LaboratorioPeriodos.Editar} />
				<ProtectedRouteLaboratorio exact title="Periodos" path="/laboratorio/:empresa/periodos/ver/:periodoId" component={LaboratorioPeriodos.Ver} />

				<ProtectedRouteLaboratorio exact title="Series" path="/laboratorio/:empresa/series" component={LaboratorioSeries.Listado} />
				<ProtectedRouteLaboratorio exact title="Series" path="/laboratorio/:empresa/series/crear" component={LaboratorioSeries.Crear} />
				<ProtectedRouteLaboratorio exact title="Series" path="/laboratorio/:empresa/series/editar/:serieId" component={LaboratorioSeries.Editar} />
				<ProtectedRouteLaboratorio exact title="Series" path="/laboratorio/:empresa/series/ver/:serieId" component={LaboratorioSeries.Ver} />

				<ProtectedRouteLaboratorio exact title="Tipo de abono" path="/laboratorio/:empresa/tipo-abono" component={LaboratorioTipoAbono.Listado} />
				<ProtectedRouteLaboratorio exact title="Tipo de abono" path="/laboratorio/:empresa/tipo-abono/crear" component={LaboratorioTipoAbono.Crear} />
				<ProtectedRouteLaboratorio exact title="Tipo de abono" path="/laboratorio/:empresa/tipo-abono/editar/:tipoAbonoId" component={LaboratorioTipoAbono.Editar} />
				<ProtectedRouteLaboratorio exact title="Tipo de abono" path="/laboratorio/:empresa/tipo-abono/ver/:tipoAbonoId" component={LaboratorioTipoAbono.Ver} />

				<ProtectedRouteLaboratorio exact title="Abonos" path="/laboratorio/:empresa/abonos" component={LaboratorioAbono.Listado} />
				<ProtectedRouteLaboratorio exact title="Abonos" path="/laboratorio/:empresa/abonos/crear" component={LaboratorioAbono.Crear} />
				<ProtectedRouteLaboratorio exact title="Abonos" path="/laboratorio/:empresa/abonos/editar/:abonoId" component={LaboratorioAbono.Editar} />
				<ProtectedRouteLaboratorio exact title="Abonos" path="/laboratorio/:empresa/abonos/ver/:abonoId" component={LaboratorioAbono.Ver} />

				<ProtectedRouteLaboratorio exact title="Abonos" path="/laboratorio/:empresa/informes" component={LaboratorioInformesMisc} />




				{/* ***REGISTRO SANITARIO*** /> */}

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa" component={RegistroSanitario} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/fabricante" component={FabricanteList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/fabricante/crear" component={Fabricante} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/fabricante/:id" component={Fabricante} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/fabricante/:id/editar" component={Fabricante} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/acondicionador" component={AcondicionadorList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/acondicionador/crear" component={AcondicionadorForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/acondicionador/:id" component={AcondicionadorForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/acondicionador/:id/editar" component={AcondicionadorForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/forma" component={FormaList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/forma/crear" component={Forma} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/forma/:id" component={Forma} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/forma/:id/editar" component={Forma} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/linea" component={LineaList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/linea/crear" component={LineaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/linea/:id" component={LineaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/linea/:id/editar" component={LineaForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/familia" component={FamiliaList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/familia/crear" component={FamiliaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/familia/:id" component={FamiliaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/familia/:id/editar" component={FamiliaForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/presentacion" component={PresentacionList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/presentacion/crear" component={PresentacionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/presentacion/:id" component={PresentacionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/presentacion/:id/editar" component={PresentacionForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/comercializador" component={ComercializadorList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/comercializador/crear" component={Comercializador} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/comercializador/:id" component={Comercializador} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/comercializador/:id/editar" component={Comercializador} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/clasificacion" component={ClasificacionList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/clasificacion/crear" component={ClasificacionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/clasificacion/:id" component={ClasificacionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/clasificacion/:id/editar" component={ClasificacionForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/principio_activo" component={PrincipioActivoList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/principio_activo/crear" component={PrincipioActivoForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/principio_activo/:id" component={PrincipioActivoForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/principio_activo/:id/editar" component={PrincipioActivoForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/concentracion" component={ConcentracionList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/concentracion/crear" component={ConcentracionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/concentracion/:id" component={ConcentracionForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/concentracion/:id/editar" component={ConcentracionForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/pais" component={PaisRSList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/pais/crear" component={PaisRSFormList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/pais/:id" component={PaisRSFormList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/pais/:id/editar" component={PaisRSFormList} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/estatus" component={EstatusList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/estatus/crear" component={Estatus} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/estatus/:id" component={Estatus} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/estatus/:id/editar" component={Estatus} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/via_administracion" component={ViaRSList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/via_administracion/crear" component={ViaRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/via_administracion/:id" component={ViaRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/via_administracion/:id/editar" component={ViaRSForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/grupo_terapeutico" component={GrupoRSList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/grupo_terapeutico/crear" component={GrupoRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/grupo_terapeutico/:id" component={GrupoRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/grupo_terapeutico/:id/editar" component={GrupoRSForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/producto" component={ProductoRSList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/producto/crear" component={ProductoRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/producto/:id" component={ProductoRSForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/producto/:id/editar" component={ProductoRSForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/seleccionar_registro" component={SelRSPais} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro/:pais" component={RegistroSanitarioList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro/:pais/crear" component={RegistroSanitarioForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro/:pais/:id" component={RegistroSanitarioForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro/:pais/:id/editar" component={RegistroSanitarioForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/licencia" component={LicenciaList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/licencia/crear" component={LicenciaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/licencia/:id" component={LicenciaForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/licencia/:id/editar" component={LicenciaForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/tipo_registro" component={TipoRegistroList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/tipo_registro/crear" component={TipoRegistroForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/tipo_registro/:id" component={TipoRegistroForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/tipo_registro/:id/editar" component={TipoRegistroForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/elzm" component={ELZMList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/elzm/crear" component={ELZMForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/elzm/:id" component={ELZMForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/elzm/:id/editar" component={ELZMForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/seleccionar_registro_coempaque" component={SelRCPais} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro_coempaque/:pais" component={RegistroCoempaqueList} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro_coempaque/:pais/crear" component={RegistroCoempaqueForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro_coempaque/:pais/:id" component={RegistroCoempaqueForm} />
				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/registro_coempaque/:pais/:id/editar" component={RegistroCoempaqueForm} />

				<ProtectedRouteRegistroSanitario exact path="/registro_sanitario/:empresa/informes" component={InformesRegistroSanitario} />

				{/* ***NOMINA*** /> */}

				<ProtectedRouteNomina exact path="/nomina/:empresa" component={Nomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/frecuencia_pago" component={FrecuenciaPago} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_periodo_nomina" component={SelPeriodoNomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodo_nomina/:periodo" component={PeriodoNominaList} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodo_nomina/crear/:periodo" component={PeriodoNominaForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodo_nomina/:periodo/:id" component={PeriodoNominaForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/periodo_nomina/:periodo/:id/editar" component={PeriodoNominaForm} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_nomina_periodo" component={SelNomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/listar_nomina/:periodo" component={NominaList} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_nomina/:periodo" component={SelPeriodoNom} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina/:periodo/crear" component={NominaForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina/:periodo" component={NominaForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina/:periodo/editar" component={NominaForm} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/tipo_descuento" component={ListTipoDescuento} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/tipo_descuento/crear" component={FormTipoDescuento} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/tipo_descuento/:id" component={FormTipoDescuento} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/tipo_descuento/:id/editar" component={FormTipoDescuento} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_periodo_horas_extras" component={SelPeriodoHorasExtras} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/horas_extras/:periodo" component={ListHorasExtras} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/horas_extras/:periodo/crear" component={FormHorasExtras} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/horas_extras/:periodo/:id" component={FormHorasExtras} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/horas_extras/:periodo/:id/editar" component={FormHorasExtras} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_periodo_descuentos" component={SelPeriodoDescuentos} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/descuentos/:periodo" component={ListDescuentos} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/descuentos/:periodo/crear" component={FormDescuentoNomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/descuentos/:periodo/:id" component={FormDescuentoNomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/descuentos/:periodo/:id/editar" component={FormDescuentoNomina} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_nomina_otros_periodo" component={SelNominaOtrosIngresos} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/listar_nomina_otros/:periodo" component={NominaOtrosList} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_nomina_otros/:periodo" component={SelPeriodoOtrosNom} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina_otros/:periodo/crear" component={NominaOtrosForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina_otros/:periodo" component={NominaOtrosForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/nomina_otros/:periodo/editar" component={NominaOtrosForm} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/seleccionar_cierre_mes" component={CierreMesNomina} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/calcular_bono" component={CalculoBono14Nomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/bono/:year" component={FormCalculoBono14Nomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/calcular_aguinaldo" component={CalculoAguinaldoNomina} />
				<ProtectedRouteNomina exact path="/nomina/:empresa/aguinaldo/:year" component={FormCalculoAguinaldoNomina} />

				<ProtectedRouteNomina exact path="/nomina/:empresa/informes" component={InformesNomina} />

				{/* ***REGISTRO DE MARCAS*** /> */}

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa" component={RegistroMarca} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/agente" component={AgenteList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/agente/crear" component={FormAgente} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/agente/:id" component={FormAgente} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/agente/:id/editar" component={FormAgente} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/status" component={StatusList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/status/crear" component={FormStatus} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/status/:id" component={FormStatus} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/status/:id/editar" component={FormStatus} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/titular" component={TitularList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/titular/crear" component={FormTitular} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/titular/:id" component={FormTitular} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/titular/:id/editar" component={FormTitular} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/observaciones" component={FormObservacionRM} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/comercializada" component={ComercialList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/comercializada/crear" component={FormComercial} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/comercializada/:id" component={FormComercial} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/comercializada/:id/editar" component={FormComercial} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/principio_activo" component={PrincipioActivoRMList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/principio_activo/crear" component={FormPrincipioActivoRM} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/principio_activo/:id" component={FormPrincipioActivoRM} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/principio_activo/:id/editar" component={FormPrincipioActivoRM} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/marca" component={RegistroMarcaList} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/marca/crear" component={FormRegistroMarca} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/marca/:id" component={FormRegistroMarcaVer} />
				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/marca/:id/editar" component={FormRegistroMarca} />

				<ProtectedRouteRegistroMarca exact path="/registro_marca/:empresa/informes" component={ InformesRM } />

				{/* ***VISITADORES MEDICOS*** /> */}

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa" component={VisitadorMedico} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/editar-perfil" component={Profile} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/cambiar-pass" component={CambiarPass} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/periodos" component={ListPeriodos} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/periodos/crear" component={PeriodoContainer} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/periodos/:id" component={PeriodoContainer} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/periodos/:id/editar" component={PeriodoContainer} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/seleccionar_periodo_compra" component={SelPeriodoCompra} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/compras/:periodo" component={SubirComprasVM} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/seleccionar_periodo_compra_consulta" component={SelPeriodoCompraConsulta} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/compras_consulta/:periodo" component={ComprasListVM} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/tipo" component={TipoVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/tipo/crear" component={TipoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/tipo/:id" component={TipoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/tipo/:id/editar" component={TipoVMForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/centro_costo" component={CentroCostoVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/centro_costo/crear" component={CentroCostoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/centro_costo/:id" component={CentroCostoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/centro_costo/:id/editar" component={CentroCostoVMForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/departamento" component={DepartamentoVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/departamento/crear" component={DepartamentoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/departamento/:id" component={DepartamentoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/departamento/:id/editar" component={DepartamentoVMForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/producto" component={ProductoVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/producto/crear" component={ProductoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/producto/:id" component={ProductoVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/producto/:id/editar" component={ProductoVMForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/visitador" component={VisitadorForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/seleccionar_periodo_liquidacion" component={SelPeriodoLiquidacion} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/liquidaciones/:periodo" component={LiquidacionVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/liquidaciones_archivo/:periodo" component={SubirLiquidacionVM} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/liquidaciones/:periodo/:id" component={LiquidacionForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/seleccionar_periodo_otros_gastos" component={SelPeriodoOtrosGastos} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/otros_gastos/:periodo" component={OtrosGastosVMList} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/otros_gastos_archivo/:periodo" component={SubirOtrosGastosVM} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/otros_gastos/:periodo/:id" component={OtrosGastosForm} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/cuenta_contable" component={CuentaContableVMForm} />
				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/generar_poliza" component={GenerarPolizaVM} />

				<ProtectedRouteVisitadorMedico exact path="/visitador_medico/:empresa/informes" component={InformesVM} />

				{/* ***PUNTO DE VENTA*** /> */}
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa" component={PuntoVenta} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/clientes" component={ClienteListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/clientes/crear" component={ClienteFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/clientes/:id" component={ClienteFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/clientes/:id/editar" component={ClienteFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/bodegas" component={BodegasListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/bodegas/crear" component={BodegasFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/bodegas/:id" component={BodegasFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/bodegas/:id/editar" component={BodegasFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/producto" component={ProductosListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/producto/crear" component={ProductosFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/producto/:id" component={ProductosFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/producto/:id/editar" component={ProductosFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/presentacion_producto" component={PresentacionListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/presentacion_producto/crear" component={PresentacionFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/presentacion_producto/:id" component={PresentacionFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/presentacion_producto/:id/editar" component={PresentacionFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/tipo_producto" component={TipoProductoListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/tipo_producto/crear" component={TipoProductoFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/tipo_producto/:id" component={TipoProductoFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/tipo_producto/:id/editar" component={TipoProductoFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/unidad_medida" component={UnidadMedidaListPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/unidad_medida/crear" component={UnidadMedidaFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/unidad_medida/:id" component={UnidadMedidaFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/unidad_medida/:id/editar" component={UnidadMedidaFormPDV} />

				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/facturacion" component={FacturacionPos} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/seleccionar_comanda" component={SelPeriodoComanda} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/comandas/:id" component={ComandaList} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/seleccionar_factura" component={SelPeriodoFactura} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/facturas/:id" component={FacturaList} />
				
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/informes" component={InformesPDV} />
				{/* <ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/facturacion/crear" component={UnidadMedidaFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/facturacion/:id" component={UnidadMedidaFormPDV} />
				<ProtectedRoutePuntoVenta exact path="/punto_venta/:empresa/facturacion/:id/editar" component={UnidadMedidaFormPDV} /> */}

				<Route component={NotFound} />
			</Switch>
		</div>
		<NotificationContainer />
	</div>
);
