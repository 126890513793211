import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
} from "../../Utils/renderField/renderField";

function ClientesForm(props) {
  const { registroCliente, modificarCliente,
    crear, datos, modulo, buscarNit, nombreCli  } = props;
  
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Cliente' : 'Crear Cliente';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Cliente'
  }
  
  let autoFocus = true;

  let ruta = window.location.href;
  let id_emp = ruta.split('/');

  return (
    <div className="container">
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className="row">
              {/* Identificador */}
              <div className="mb-2 col-md-4">
                <Field
                  autoFocus={autoFocus}
                  name="identificadorCli"
                  placeholder="Identificador"
                  className="form-control"
                  component={renderField}
                  disabled={editar ? true : disabled}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      buscarNit(e.target.value);
                      setNombre(nombreCli);
                    }
                  }}
                  msj="Nit"
                />
              </div>
            </div>
            {crear === true &&
              <div className="row">
                {/* Nombre */}
                <div className="mb-2 col-md-8">
                  <div className="form-floating">
                    <input
                      name="nombre"
                      placeholder="Nombre"
                      className="form-control"
                      value={nombreCli}
                    />
                    <label className='form-label' htmlFor="nombre">
                      Nombre
                    </label>
                  </div>
                </div>
              </div>
            }
            {editar === true &&
              <div className="row">
                <div className="mb-2 col-md-8">
                  <Field
                    name="nombre"
                    placeholder="Nombre"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Nombre"
                  />
                </div>
              </div>
            }
            {crear == false && editar === false &&
              <div className="row">
                <div className="mb-2 col-md-8">
                  <Field
                    name="nombre"
                    placeholder="Nombre"
                    className="form-control"
                    component={renderField}
                    disabled={disabled}
                    msj="Nombre"
                  />
                </div>
              </div>
            }
            <div className="row">
              {/* Direccion */}
              <div className="mb-2 col-md-8">
                <Field
                  name="direccionCli"
                  placeholder="Direccion"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Dirección"
                />
              </div>
              {/* Telefono */}
              <div className="mb-2 col-md-4">
                <Field
                  name="telefonoCli"
                  placeholder="Telefono"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Telefono"
                />
              </div>
            </div>
            <div className="row">
              {/* Correo */}
              <div className="mb-2 col-md-4">
                <Field
                  name="correo"
                  placeholder="Correo electrónico"
                  className="form-control"
                  component={renderField}
                  disabled={disabled}
                  msj="Correo electronico"
                />
              </div>
            </div>
            {/* Buttons */}
            <div className="d-flex flex-row">
              {disabled == false && editar === false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => registroCliente(nombreCli)}
                >
                  Guardar
                </button>
              }
              {disabled == false && editar === true &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={() => modificarCliente()}
                >
                  Modificar
                </button>
              }

              <a
                href={`/#/punto_venta/${id_emp[5]}/clientes`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ClientesForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  listaPaises: PropTypes.object,
  datos: PropTypes.object,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormClientePDV',
  validate: (data) => {
    return validate(data, {
      identificador: validators.exists()('Este campo es requerido'),
      nombre: validators.exists()('Este campo es requerido'),
      tipo_proveedor: validators.exists()('Seleccione tipo'),
    });
  },
})(ClientesForm);
