import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const GUARDAR_PRODUCTO = 'GUARDAR_PRODUCTO';
const LISTADO_TIPOS = 'LISTADO_TIPOS';
const LISTADO_PRESENTACION = 'LISTADO_PRESENTACION';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const LISTADO_UNIDADES = 'LISTADO_UNIDADES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    //const params = { page, id };
    const params = { id };
    api.get('/producto', params).then((response)=>{
        dispatch({ type: LISTADO_PRODUCTO, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProducto = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    console.log(data)
    let tipo = data.tipo_producto;
    if (tipo === undefined || tipo === '0'){
        tipo = '';
    }else{
        tipo = data.tipo_producto;
    }
    let presentacion = data.presentacion_producto;
    if (presentacion === undefined || presentacion === '0'){
        presentacion = '';
    }else{
        presentacion = data.presentacion_producto;
    }
    let clasificacion = data.clasificacion;
    if (clasificacion === undefined || clasificacion === '0'){
        clasificacion = '';
    }else{
        clasificacion = data.clasificacion;
    }
    let medida = data.unidad_medida;
    if (medida === undefined || medida === '0'){
        medida = '';
    }else{
        medida = data.unidad_medida;
    }

    const formData = {
        SKU: data.SKU,
        producto: data.producto,
        tipo: data.tipo,
        maximo: data.maximo,
        minimo: data.minimo,
        tipo_producto: tipo,
        presentacion_producto: presentacion,
        empresa: id_emp[5],
        clasificacion: clasificacion,
        unidadMedida: medida
    }
    dispatch(setLoader(true));
    api.postAttachments('/producto/', formData, attachments).then(() => {
        NotificationManager.success(
        'Producto creado',
        'Exito',
        3000
    );
    let ruta = `/inventario/${id_emp[5]}/producto`
    dispatch(push(ruta));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const modificarProducto = (data={},attachments=[]) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const id = data.id;
    let tipo = data.tipo_producto;
    if (tipo === undefined || tipo === '0'){
        tipo = '';
    }else{
        tipo = data.tipo_producto;
    }
    let presentacion = data.presentacion_producto;
    if (presentacion === undefined || presentacion === '0'){
        presentacion = '';
    }else{
        presentacion = data.presentacion_producto;
    }
    let clasificacion = data.clasificacion;
    if (clasificacion === undefined || clasificacion === '0'){
        clasificacion = '';
    }else{
        clasificacion = data.clasificacion;
    }
    let medida = data.unidad_medida;
    if (medida === undefined || medida === '0'){
        medida = '';
    }else{
        medida = data.unidad_medida;
    }
    const formData = {
        id: id,
        SKU: data.SKU,
        producto: data.producto,
        tipo: data.tipo,
        activo: data.activo,
        maximo: data.maximo,
        minimo: data.minimo,
        tipo_producto: tipo,
        presentacion_producto: presentacion,
        empresa: id_emp[5],
        clasificacion: clasificacion,
        unidadMedida: medida
    }
    dispatch(setLoader(true));
    api.putAttachments(`/producto/${id}/`, formData, attachments).then(() => {
        NotificationManager.success(
            'Producto modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/inventario/${id_emp[5]}/producto`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar el producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/producto/${id}`).then((response) => {
        dispatch({type: GUARDAR_PRODUCTO, lectura: response });
        dispatch(initializeForm('FormProducto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarTipos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/tipo_producto', params).then((response) => {
        const tipos = [];
        response.forEach(item => {
            tipos.push({
                value: item.id,
                label: item.tipo
            })
        })
        dispatch({ type: LISTADO_TIPOS, tipo: tipos });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar tipos de producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarPresentacion = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/presentacion_producto', params).then((response) => {
        const presentacion = [];
        response.forEach(item => {
            presentacion.push({
                value: item.id,
                label: item.presentacion
            })
        })
        dispatch({ type: LISTADO_PRESENTACION, presentacion: presentacion });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar presentacion de producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerCombo = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/producto/${id}`).then((response) => {
        dispatch({type: GUARDAR_PRODUCTO, lectura: response });
        dispatch(initializeForm('FormComboProducto', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

const leerDetalleCombo = (id, producto) => (dispatch) => {
    const params = { id, producto }
    api.get('producto/producto_combo_list', params).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroCombo = () => (dispatch, getStore) => {
    const data = getStore().form.FormComboProducto.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const formData = {
        producto: id_emp[7],
        producto_combo: data.producto_combo.value,
        cantidad: data.cantidad,
        empresa: id_emp[5]
    }
    dispatch(setLoader(true));
    api.post('/producto/producto_combo/', formData).then((response) => {
        NotificationManager.success(
            'Producto ingresado correctamente',
            'Exito',
            3000
        );
        dispatch(leerDetalleCombo(id_emp[5], id_emp[7]));
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const confirmarCombo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    NotificationManager.success(
        'Combo guardado correctamente',
        'Exito',
        3000
    );
    let url = `/inventario/${id_emp[5]}/producto`;
    dispatch(push(url));
}

export const eliminarLineaProducto = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = id_emp[5];
    const params = {
        id: id, 
        empresa: empresa
    }
    dispatch(setLoader(true));
    api.post('producto/producto_combo_delete', params).then(() => {
        dispatch(leerDetalleCombo(id_emp[5], id_emp[7]));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const reporteProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'productos';
    const params = { id, reporte }
    api.getPdf('/producto/reportes', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_productos.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.error(
                "No se encontraron datos",
                "Error",
                2000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
  
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarUnidades = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/unidad_medida', params).then((response) => {
        const unidades = [];
        response.forEach(item => {
            unidades.push({
                value: item.id,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_UNIDADES, unidadMedida: unidades });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar presentacion de producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroProducto,
    modificarProducto,
    listarTipos,
    listarPresentacion,
    leer,
    getModulo,
    leerCombo,
    listarProductos,
    leerDetalleCombo,
    registroCombo,
    confirmarCombo,
    eliminarLineaProducto,
    reporteProductos,
    parametrosEmpresa,
    listarUnidades
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_PRODUCTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_TIPOS]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [LISTADO_PRESENTACION]: (state, { presentacion }) => {
        return {
            ...state,
            presentacion,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_UNIDADES]: (state, { unidadMedida }) => {
        return {
            ...state,
            unidadMedida,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    detalleProducto: [],
    data: [],
    page: 1,
    datos: [],
    unidadMedida: []
};

export default handleActions(reducers, initialState)
