import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { validate, validators } from 'validate-redux-form';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
  renderField,
  renderFieldCheck,
  renderNumber,
  SelectField,
  renderFilePicker
} from "../../Utils/renderField/renderField";

function ProductoForm(props) {
  const { handleSubmit, crear, tipo, unidadMedida, 
    presentacion, lectura, setLogo, modulo, datos } = props;
  const editar = window.location.href.includes('editar');
  let titulo = editar ? 'Modificar Producto' : 'Crear Producto';
  let disabled = false;
  if (crear === false && editar === false) {
    disabled = true;
    titulo = 'Ver Producto'
  }
    
  let autoFocus = true;
  const tipoPro = [
    { label: "PRODUCTO", value: "P" },
    { label: "SERVICIO", value: "S" },
    { label: "COMBO", value: "C" }
  ];
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  
  const clasificacion = [
    { label: "FERTILIZANTES SOLUBLES", value: 1 },
    { label: "AGROQUÍMICOS", value: 2 },
    { label: "COLMENAS", value: 3 },
    { label: "OTROS INSUMOS", value: 4 },
    { label: "SEMILLAS", value: 5 },
    { label: "MATERIAL DE EMPAQUE", value: 6 },
  ];

  return (
    <div>
      <NavbarModulo
        modulo={modulo}
        titulo={titulo}
      />
      <br />
      <div className="mb-4 card card-small text-white bg-dark mb-3">
        <div className="p-0 pt-3 d-flex flex-column flex-md-row">
          <div className="d-flex flex-column flex-1 mx-4">
            <div className='row mb-2 '>
              <div className='col-3'>
                <Field
                  autoFocus={autoFocus}
                  name="SKU"
                  placeholder="SKU"
                  className="form-control"
                  component={renderField}
                  disabled={editar ? true : disabled}
                  msj="SKU"
                />
              </div>
              <div className='col-3'>
                <div className="form-floating">
                  <Field
                    name="tipo"
                    options={tipoPro}
                    crear={crear}
                    dato={crear === true ? 0 : lectura.tipo}
                    component={SelectField}
                    className="form-select"
                    disabled={disabled}
                    msj="Tipo"
                  />
                </div>
              </div>
              <div className='col-3 mt-3'>
                {editar &&
                  <Field
                    name="activo"
                    label="Activo"
                    component={renderFieldCheck}
                />
                }
                {crear === false && editar === false &&
                  <Field
                    name="activo"
                    label="Activo"
                    disabled={true}
                    component={renderFieldCheck}
                />
                }  
              </div>
              
            </div>
            <div className='mb-2'>
              <Field
                name="producto"
                placeholder="Producto"
                className="form-control"
                component={renderField}
                disabled={disabled}
                msj="Producto"
              />
            </div>
            <div className='row mb-2'>
              <div className='col-3'>
                <Field
                  name="minimo"
                  placeholder="Minimo"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Minimo"
                />
              </div>
              <div className='col-3'>
                <Field
                  name="maximo"
                  placeholder="Maximo"
                  className="form-control"
                  component={renderNumber}
                  disabled={disabled}
                  msj="Maximo"
                />
              </div>
            </div>
            <div className='row'>
              {tipo &&
                <div className='mb-2 col-3'>
                  <div className="form-floating">
                    <Field
                      name="tipo_producto"
                      options={tipo}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.tipo_producto}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Tipo producto"
                    />
                  </div>
                </div>
              }
              {presentacion &&
                <div className='mb-2 col-3'>
                  <div className="form-floating">
                    <Field
                      name="presentacion_producto"
                      options={presentacion}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.presentacion}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Presentación producto"
                    />
                  </div>
                </div>
              }
              {unidadMedida &&
                <div className='mb-2 col-3'>
                  <div className="form-floating">
                    <Field
                      name="unidad_medida"
                      options={unidadMedida}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.unidad_medida}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Unidad de medida"
                    />
                  </div>
                </div>
              }
              {datos.agricola === 'S' &&
                <div className='col-3'>
                  <div className="form-floating">
                    <Field
                      name="clasificacion"
                      options={clasificacion}
                      crear={crear}
                      dato={crear === true ? 0 : lectura.clasificacion}
                      component={SelectField}
                      className="form-select"
                      disabled={disabled}
                      msj="Clasificación"
                    />
                  </div>
                </div>
              }
            </div>
            {(crear === false && editar == false) && lectura.imagen === null &&
              <div className="col-4">
                <label htmlFor="logo">Imagen de producto</label>
                  <Field
                    photo={null}
                    setFile={setLogo}
                    name="logo"
                    component={renderFilePicker}
                  />
              </div>
            }
            {(crear === false && editar == false) && lectura.imagen &&
             <div className="col-4">
              <label htmlFor="logo">Imagen de producto</label>
                <Field
                  photo={lectura.imagen}
                  setFile={setLogo}
                  name="logo"
                  disabled="true"
                  component={renderFilePicker}
                />
              </div>
            } 
            {(crear === true) &&
              <div className="col-4">
                <label htmlFor="logo">Imagen de producto</label>
                  <Field
                    photo={null}
                    setFile={setLogo}
                    name="logo"
                    component={renderFilePicker}
                  />
              </div>
            }
            {(editar === true) && lectura.imagen === null &&
              <div className="col-4">
                <label htmlFor="logo">Imagen de producto</label>
                  <Field
                    photo={null}
                    setFile={setLogo}
                    name="logo"
                    component={renderFilePicker}
                  />
              </div>
            } 
            {(editar === true) && lectura.imagen &&
              <div className="col-4">
                <label htmlFor="logo">Imagen de producto</label>
                  <Field
                    photo={lectura.imagen}
                    setFile={setLogo}
                    name="logo"
                    component={renderFilePicker}
                  />
              </div>
            } 
            <div className='d-flex flex-row mt-3'>
              {disabled == false &&
                <button
                  className='btn mr-2 mb-3 btn-primary'
                  onClick={handleSubmit}
                >
                  {editar ? 'Modificar' : 'Guardar'}
                </button>
              }
              <a
                href={`/#/inventario/${id_emp[5]}/producto`}
                className='btn btn-secondary mb-3'
              >
                Regresar
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

ProductoForm.propTypes = {
  handleSubmit: PropTypes.func,
  crear: PropTypes.bool,
  lectura: PropTypes.object,
  tipo: PropTypes.array,
  presentacion: PropTypes.array,
  modulo: PropTypes.object,
};

export default reduxForm({
  form: 'FormProducto',
  validate: (data) => {
    return validate(data, {
      SKU: validators.exists()('Este campo es requerido'),
      producto: validators.exists()('Este campo es requerido')
    });
  },
})(ProductoForm);
