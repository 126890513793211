import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/punto_venta/facturacionPos';
import PuntoVenta from './PuntoVenta';


const ms2p = (state) => {
  return {
    ...state.facturacionPos_pv,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PuntoVenta);