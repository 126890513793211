import React, { Component } from 'react';
import Formulario from './FacturacionPosForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class FacturacionPos extends Component {
    //deshabilita el boton crear
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, parametrosEmpresa, getModulo, limpiarDatos} = this.props;
        const id = match.params.id;
        if (id) {
            this.setState({ crear: false });
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        parametrosEmpresa();
        limpiarDatos();
    }

    render() {
        const { loader, modulo, saldo, datosEmpresa, statusCaja, 
            listarProductos,productosSelect,buscarNit,nombreCli, 
            limpiarDatos, registroCliente,buscarCliente,searchCliente, 
            registrarAperturaCaja, registroVenta, idCliente} = this.props;
        const { crear } = this.state;
        return (
            <LoadMask loading={loader} dark>
                <Formulario
                    crear={crear}
                    modulo={modulo}
                    datos={datosEmpresa}
                    statusCaja={statusCaja}
                    saldo={saldo}
                    productos={productosSelect}
                    registrarAperturaCaja={registrarAperturaCaja}
                    listarProductos={listarProductos}
                    registroCliente={registroCliente}
                    buscarNit={buscarNit}
                    nombreCli={nombreCli}
                    buscarCliente={buscarCliente}
                    searchCliente={searchCliente}
                    limpiarDatos={limpiarDatos}
                    registroVenta={registroVenta}
                    idCliente={idCliente}
                />
            </LoadMask>
        );
    }
}

export default FacturacionPos;
