import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        label: 'inicio',
        path: '/inventario',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Periodos',
        label: 'periodos',
        path: '/inventario/periodos',
        icon: <BsIcons.BsFillCalendarDateFill />,
    },
    {
        title: 'Catalogos',
        label: 'catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Bodegas',
                path: '/inventario',
                path_s: 'bodegas',
                icon: <FaIcons.FaWarehouse />,
            },
            {
                title: 'Productos',
                path: '/inventario',
                path_s: 'producto',
                icon: <FaIcons.FaDiceD6 />,
            }
        ]
    },
    {
        title: 'Propiedades',
        label: 'propiedades',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Unidad de medida',
                path: '/inventario',
                path_s: 'unidad_medida',
                icon: <FaIcons.FaRulerCombined />,
            },
            {
                title: 'Tipo producto',
                path: '/inventario',
                path_s: 'tipo_producto',
                icon: <FaIcons.FaArchive />,
            },
            {
                title: 'Presentación',
                path: '/inventario',
                path_s: 'presentacion_producto',
                icon: <FaIcons.FaArchive />,
            },
        ]
    },
    {
        title: 'Listado de precios',
        label: 'listado_precios',
        path: '/inventario/listado_precios',
        icon: <FaIcons.FaBook />,
    },
    {
        title: 'Registros',
        label: 'registros',
        path: '/inventario/seleccionar',
        icon: <FaIcons.FaDollyFlatbed />,
    },
    {
        title: 'Orden de servicio',
        label: 'orden_servicio',
        path: '#',
        icon: <FaIcons.FaListUl />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Orden de servicio',
                path: '/inventario',
                path_s: 'seleccionar_orden_servicio',
                icon: <FaIcons.FaListUl />,
            },
            {
                title: 'Tipo de gasto',
                path: '/inventario',
                path_s: 'tipo_gasto',
                icon: <BsIcons.BsFillGrid3X2GapFill />,
            },
            {
                title: 'Gastos de proyecto',
                path: '/inventario',
                path_s: 'seleccionar_gastos_proyecto',
                icon: <BsIcons.BsFillFolderFill />,
            },
            {
                title: 'Cierre de proyecto',
                path: '/inventario',
                path_s: 'cierre_proyecto',
                icon: <FaIcons.FaClipboardCheck />,
            },
        ]
    },
    {
        title: 'Calcular costo',
        label: 'calcular_costo',
        path: '/inventario/costo',
        icon: <FaIcons.FaCogs />,
    },
    {
        title: 'Informes',
        label: 'informes',
        path: '/inventario/informes',
        icon: <FaIcons.FaClipboard />,
    }
]

