import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import moment from 'moment';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';
import Swal from 'sweetalert2';

const seleccionarTipoFac = (id_estab) => {
    let ruta = window.location.href;
    let datos_emp = ruta.split('/');
    Swal.fire({
        title: 'Tipo de factura',
        html:
            '<div class="mx-auto mt-4" style="width: 200px;">' +
            `<select class="form-control" id="lista" data-style="btn-warning" data-live-search="true" >
                <option value="B" selected>Bienes</option>
                <option value="S">Servicios</option>
            </select> ` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let op = document.getElementById('lista').value
            if (op){
                if (op === 'B'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/facturas/${datos_emp[7]}/crear/${id_estab}/bienes`;
                }
                if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/facturas/${datos_emp[7]}/crear/${id_estab}/servicios`;
                }
            }
            if (!op) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
};

const seleccionarTipoFacFel_INV = (id_estab) => {
    let ruta = window.location.href;
    let datos_emp = ruta.split('/');
    Swal.fire({
        title: 'Tipo de factura',
        html:
            '<div class="mx-auto mt-4" style="width: 200px;">' +
            `<select class="form-control" id="lista" data-style="btn-warning" data-live-search="true" >
                <option value="B" selected>Bienes</option>
                <option value="S">Servicios</option>
            </select> ` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let op = document.getElementById('lista').value
            if (op){
                if (op === 'B'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/bienes_s_envio`;
                }
                if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/servicios_fel`;
                }
            }
            if (!op) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
};


const seleccionarTipoFacPRDFEL = (id_estab) => {
    let ruta = window.location.href;
    let datos_emp = ruta.split('/');
    Swal.fire({
        title: 'Tipo de factura',
        html:
            '<div class="mx-auto mt-4" style="width: 200px;">' +
            `<select class="form-control" id="lista" data-style="btn-warning" data-live-search="true" >
                <option value="BE" selected>Bienes con envío</option>
                <option value="B">Bienes sin envío</option>
                <option value="S">Servicios</option>
            </select> ` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let op = document.getElementById('lista').value
            if (op){
                if (op === 'BE'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/bienes_envio`;
                }
                if (op === 'B'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/bienes_s_envio`;
                }
                if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/servicios_fel`;
                }
            }
            if (!op) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
};

const seleccionarTipoFacFEL = (id_estab) => {
    let ruta = window.location.href;
    let datos_emp = ruta.split('/');
    Swal.fire({
        title: 'Tipo de factura',
        html:
            '<div class="mx-auto mt-4" style="width: 200px;">' +
            `<select class="form-control" id="lista" data-style="btn-warning" data-live-search="true" >
                <option value="B" selected>Bienes</option>
                <option value="S">Servicios</option>
            </select> ` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let op = document.getElementById('lista').value
            if (op){
                if (op === 'B'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/bienes_farm_fel`;
                }
                if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/servicios_farm_fel`;
                }
            }
            if (!op) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
};

const seleccionarTipoFacFarm = (id_estab) => {
    let ruta = window.location.href;
    let datos_emp = ruta.split('/');
    Swal.fire({
        title: 'Tipo de factura',
        html:
            '<div class="mx-auto mt-4" style="width: 200px;">' +
            `<select class="form-control" id="lista" data-style="btn-warning" data-live-search="true" >
                <option value="B" selected>Bienes</option>
                <option value="S">Servicios</option>
            </select> ` +
            '</div',
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#4040ff",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#646464",
        showCloseButton: true,
        customClass: {
            title: 'sweet_titleImportant',
        },
        preConfirm: () => {
            let op = document.getElementById('lista').value
            if (op){
                if (op === 'B'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/bienes_farm`;
                }
                if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/facturas/${datos_emp[7]}/crear/${id_estab}/servicios`;
                }
                /*if (op === 'S'){
                    window.location.href = `/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${id_estab}/servicios_farm`;
                }*/
            }
            if (!op) {
                Swal.showValidationMessage(
                    'Este campo es requerido'
                )
            }
        }
    })
};


class FacturacionList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };
    
    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo, 
            listarEstablecimientos, parametrosEmpresa } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
        listarEstablecimientos();
        parametrosEmpresa();
    }
    
    render(){
        const { data, loader, anularFacFel, imprimirFactura, eliminarFacturaSFEL,
            periodoD, modulo, listaEs, datos, anularFac,
            imprimirFacturaCDS, descargarFacturaCDS } = this.props;
        let ruta = window.location.href;
        let datos_emp = ruta.split('/');
        
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodoD}
                    modulo = {modulo}
                />
                <br />
                {datos.fel === 'S' &&
                    <h3 className="p-2 bg-secondary title">Facturas [ FEL ]</h3>
                }
                {datos.fel === 'N' &&
                    <h3 className="p-2 bg-secondary title">Facturas</h3>
                }
                <br />
                {periodoD.estado == 'A' &&
                <div>
                    {datos.inventario === 'N' && datos.fel === 'N' && datos.agricola === 'N' && datos.farmaceutica === 'N' && 
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <a
                                href={`/#/facturacion/${datos_emp[5]}/facturas/${datos_emp[7]}/crear/${listaEs.id}/`}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </a>
                        
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'N' && datos.agricola === 'N' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFac(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'S' && datos.agricola === 'N' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFacFel_INV(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'S' && datos.agricola === 'S' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFacPRDFEL(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        </div>
                    }
                    {datos.inventario === 'N' && datos.fel === 'N' && datos.agricola === 'S' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <a
                                href={`/#/facturacion/${datos_emp[5]}/facturas/${datos_emp[7]}/crear/${listaEs.id}/`}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </a>
                        
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'N' && datos.agricola === 'S' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFac(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        
                        </div>
                    }
                    {datos.inventario === 'N' && datos.fel === 'S' && datos.agricola === 'N' && datos.farmaceutica === 'N' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <a
                                href={`/#/facturacion/${datos_emp[5]}/factura/${datos_emp[7]}/crear/${listaEs.id}/fel`}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </a>
                        
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'S' && datos.agricola === 'N' && datos.farmaceutica === 'S' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFacFEL(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        </div>
                    }
                    {datos.inventario === 'S' && datos.fel === 'N' && datos.agricola === 'N' && datos.farmaceutica === 'S' &&
                        <div className="d-flex flex-row justify-content-start mb-2">
                            <button
                                onClick={() => seleccionarTipoFacFarm(listaEs.id)}
                                className='btn btn-primary'
                            >
                                Crear Factura
                            </button>
                        </div>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'S' && datos.agricola === 'S' && periodoD.estado == 'A' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 1 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            } 
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }   
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'S' && datos.agricola === 'S' && periodoD.estado == 'C' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 1 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }   
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'S' && periodoD.estado == 'A' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 1 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            } 
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }   
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'S' && periodoD.estado == 'C' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 1 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }   
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'N' && datos.agricola === 'N' && periodoD.estado == 'A' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 2 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_fel: "factura",
                                        imprimirFactura: imprimirFactura,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'N' && datos.agricola === 'N' && periodoD.estado == 'C' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 2 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado == 'A' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 2 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_inventario_fel: "factura",
                                        imprimirFactura: imprimirFactura,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_inventario_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado == 'C' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                width="100"
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="interno"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Interno
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serieDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numeroDoc"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            {parseInt(datos.certificador) === 2 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_inventario_fel: "factura",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                            {parseInt(datos.certificador) === 3 &&
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_fac_inventario_fel: "factura",
                                        imprimirFactura: imprimirFacturaCDS,
                                        descargarFactura: descargarFacturaCDS,
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            }
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado === 'A' && datos.farmaceutica === 'S' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                width="100"
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_fac_inventario_sfel: "factura", 
                                    ver_fac_inventario_sfel: "factura",
                                    eliminarFac: eliminarFacturaSFEL,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac: anularFac
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado === 'C' && datos.farmaceutica === 'S' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_fac_inventario_sfel: "factura",
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'N' && datos.agricola === 'N' && periodoD.estado === 'A' && datos.farmaceutica === 'N' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_inv: "facturas",
                                    editar_inv: "facturas",
                                    eliminarFac: eliminarFacturaSFEL,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac: anularFac
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                    
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'N' && datos.agricola === 'N' && periodoD.estado === 'C' && datos.farmaceutica === 'N' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_inv: "facturas",
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    }
                    
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado === 'A' && datos.farmaceutica === 'N' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    editar_inv: "facturas", 
                                    ver_inv: "facturas",
                                    eliminarFac: eliminarFacturaSFEL,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac: anularFac
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'N' && datos.inventario === 'S' && datos.agricola === 'N' && periodoD.estado === 'C' && datos.farmaceutica === 'N' &&
                <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                width="100"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombreCli"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_inv: "facturas",
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }

            </React.Fragment>
        );
    }
}

export default FacturacionList;
