import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const MODULO = 'MODULO';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LOADER = 'LOADER';

const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const listarCuentas = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/cuentas/empresa_cuentas/', params).then(data => {
    const list_cuentas = [];
    if (data) {
      data.forEach(item => {
        if (item.tipo === 'A') {
          list_cuentas.push({
            value: item.id,
            label: item.cuenta + ' ' + item.nombre,
            isDisabled: true
          })
        } else {
          list_cuentas.push({
            value: item.id,
            label: item.cuenta + ' ' + item.nombre
          })
        }
      })
    }
    return list_cuentas;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const listarCC = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id, search }
  return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
    const list_cc = [];
    if (data) {
      data.forEach(item => {
        list_cc.push({
          value: item.id,
          label: item.codigo + ' ' + item.descripcion
        })
      })
    }
    return list_cc;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const reporteListaFacturas = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let mes = '';
  if (data === undefined) {
    mes = 'TODAS';
  } else {
    mes = data.mes;
  }
  let mesLetras = renderSwitch(data.mes)
  let year = data.year;
  let reporte = 'listaFacturas';
  const params = { id, reporte, mesLetras, year };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_facturas.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteListadoCompras = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let proveedor = data.proveedor ? data.proveedor.value : '';
  let reporte = "listado_compras"
  const params = { id, reporte, fechaI, fechaF, proveedor };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_compras.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}
export const reporteListadoAbonos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let proveedor = data.proveedor ? data.proveedor.value : '';
  let reporte = "listado_abonos"
  const params = { id, reporte, fechaI, fechaF, proveedor };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_abonos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

function renderSwitch(param) {
  switch (param) {
    case '01':
      return 'ENERO';
    case '02':
      return 'FEBRERO';
    case '03':
      return 'MARZO';
    case '04':
      return 'ABRIL';
    case '05':
      return 'MAYO';
    case '06':
      return 'JUNIO';
    case '07':
      return 'JULIO';
    case '08':
      return 'AGOSTO';
    case '09':
      return 'SEPTIEMBRE';
    case '10':
      return 'OCTUBRE';
    case '11':
      return 'NOVIEMBRE';
    case '12':
      return 'DICIEMBRE';

    default:
      return 'Seleccione un mes';
  }
}

export const reporteLibroCompras = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let mes = '';
  if (data === undefined) {
    mes = 'TODAS';
  } else {
    mes = data.mes;
  }
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLC');
  let year = data.year;
  let reporte = 'libroCompras';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_listado_compras.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const excelLibroCompras = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let mesLetras = renderSwitch(data.mes)
  let folio = localStorage.getItem('folioLC');
  let year = data.year;
  let reporte = 'excelLibroCompras';
  const params = { id, reporte, year, mesLetras, folio };
  dispatch(setLoader(true));
  api.getExcel('/compra', params).then((response) => {

    const blob = response;
    const fileName = 'libro_de_compras.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);

  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  })
    .finally(() => {
      dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
  const params = { id, modulo }
  dispatch(setLoader(true));
  api.get('/modulos/modulo/', params).then((response) => {
    dispatch({ type: MODULO, modulo: response.modulos });
  }).catch(() => {
    NotificationManager.error(
      'Ocurrió un error en buscar modulo',
      'Error',
      2000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const listarMonedas = () => (dispatch) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const params = { id };
  api.get('/monedas/moneda_empresa', params).then((response) => {
    const moneda = [];
    response.forEach(item => {
      moneda.push({
        value: item.id,
        label: item.moneda + ' - ' + item.simbolo
      })
    })
    dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
  }).catch((error) => {
    NotificationManager.error(
      'Ocurrió un error al listar monedas',
      'Error',
      3000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const listarProveedores = (search) => () => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  let tipo = 'reporte';
  const params = { id, search, tipo }
  return api.get('/proveedor/empresa_proveedores', params).then(data => {
    const list_proveedor = [];
    if (data) {
      data.forEach(item => {
        list_proveedor.push({
          value: item.id,
          label: item.identificador + ' ' + item.nombre
        })
      })
    }
    return list_proveedor;
  }).catch((error) => {
    console.log("error: ", error);
    return [];
  })
}

export const reporteEstadoCuenta = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = 'T';
  let reporte = 'estado_cuenta';
  let proveedor = data.proveedor ? data.proveedor.value : '';
  const params = { id, reporte, fechaI, fechaF, moneda, tipo, proveedor };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_antiguedad_saldos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteAntiguedadSaldos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fecha = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = 'T';
  let reporte = 'antiguedad_saldos';
  let proveedor = data.proveedor ? data.proveedor.value : '';
  let detallado = data.detallado ? data.detallado : '';
  const params = { id, reporte, fecha, moneda, tipo, proveedor, detallado };
  dispatch(setLoader(true));
  api.getPdf('/compra', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_antiguedad_saldos.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Informacion',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }

  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteProveedores = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let tipo_prov = '';
  if (data === undefined) {
    tipo_prov = 'TODOS';
  } else {
    tipo_prov = data.tipo_proveedor;
  }
  let reporte = 'reporteProveedores';
  const params = { id, reporte, tipo_prov };
  dispatch(setLoader(true));
  api.getPdf('/proveedor', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_proveedores.pdf';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.info(
        error.body.msj,
        'Información',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        2000
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const reporteAnticipos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;

  let reporte = "anticipos";
  let proveedor = data.proveedor ? data.proveedor.value : '';
  let fecha = data.fechaFinal;
  const params = { id, reporte, proveedor, fecha };
  dispatch(setLoader(true));
  api.getPdf('/anticipo_cxp', params).then((response) => {
    if (response.headers['content-type'] == 'application/pdf') {
      var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'reporte_anticipos';
      window.open(link, '_blank');
      return false;
    }
  }).catch((error) => {
    if (error.status === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Error',
        5000
      );
    }
    if (error.status === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const excelAntiguedadSaldos = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fecha = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = 'T';
  let reporte = 'excel_antiguedad_saldos';
  let proveedor = data.proveedor ? data.proveedor.value : '';
  let detallado = data.detallado ? data.detallado : '';
  const params = { id, reporte, fecha, moneda, tipo, proveedor, detallado };
  dispatch(setLoader(true));
  api.getExcel('/compra', params).then((response) => {

    const blob = response;
    const fileName = 'Antiguedad_saldos_proveedores.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);

  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const excelEstadoCuenta = () => (dispatch, getStore) => {
  let ruta = window.location.href;
  let id_emp = ruta.split('/');
  let id = id_emp[5];
  const data = getStore().form.FormInformesC.values;
  let fechaI = data.fechaInicial;
  let fechaF = data.fechaFinal;
  let moneda = data.moneda;
  let tipo = 'T';
  let reporte = 'excel_estado_cuenta';
  let proveedor = data.proveedor ? data.proveedor.value : '';
  const params = { id, reporte, fechaI, fechaF, moneda, tipo, proveedor };
  dispatch(setLoader(true));
  api.getExcel('/compra', params).then((response) => {

    const blob = response;
    const fileName = 'Estado_cuenta_proveedores.xlsx';

    // Descargar el archivo utilizando la función saveAs de file-saver
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, fileName);

  }).catch(error => {
    if (error === 404) {
      NotificationManager.error(
        "No se encontraron datos",
        'Informacion',
        5000
      );
    }
    if (error === 500) {
      NotificationManager.error(
        'Ocurrió un error al generar reporte',
        'Error',
        0
      );
    }
  })
    .finally(() => {
      dispatch(setLoader(false));
    });
}

export const actions = {
  listarCuentas,
  listarCC,
  reporteListaFacturas,
  reporteLibroCompras,
  excelLibroCompras,
  getModulo,
  listarMonedas,
  listarProveedores,
  reporteAntiguedadSaldos,
  reporteEstadoCuenta,
  reporteProveedores,
  reporteListadoCompras,
  reporteListadoAbonos,
  reporteAnticipos,
  excelAntiguedadSaldos,
  excelEstadoCuenta
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MODULO]: (state, { modulo }) => {
    return {
      ...state,
      modulo,
    };
  },
  [LISTADO_MONEDAS]: (state, { monedas }) => {
    return {
      ...state,
      monedas,
    };
  },
};

export const initialState = {
  loader: false,
  modulo: [],
  monedas: [],
};

export default handleActions(reducers, initialState)