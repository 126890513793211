import React, { Component } from 'react';
import Formulario from './ClientesForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Clientes extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, parametrosEmpresa, limpiarDatos } = this.props;
        const id = match.params.id;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        parametrosEmpresa();
        getModulo(modulo[5],modulo[4]);
        limpiarDatos();
    }
    
    render(){
        const { registroCliente, modificarCliente, loader, lectura, 
            listaPaises, datos, modulo, buscarNit, nombreCli } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    registroCliente = {registroCliente}
                    modificarCliente = {modificarCliente}
                    crear = {crear}
                    lectura = {lectura}
                    listaPaises = { listaPaises }
                    datos = {datos}
                    modulo = {modulo}
                    buscarNit = {buscarNit}
                    nombreCli = {nombreCli}
                />
            </LoadMask>
        );
    }
}

export default Clientes
