
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ORDEN = 'LISTADO_ORDEN';
const LISTADO_PROVEEDOR = 'LISTADO_PROVEEDOR';
const GUARDAR_ABONO = 'GUARDAR_ABONO';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const DETALLE = 'DETALLE';
const NUMERO_ORDEN = 'NUMERO_ORDEN';
const DETALLE_ORDEN = 'DETALLE_ORDEN';
const LECTURA = 'LECTURA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/orden_compra', params).then((response) => {
        dispatch({ type: LISTADO_ORDEN, data: response });
    
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar ordenes de compra',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id+'#'+item.SKU+'#'+item.producto,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'compras';
    const params = {id, search, tipo}
    return api.get('/proveedor/empresa_proveedores',params).then(data=>{
        const list_proveedor = [];
        if(data){
            data.forEach(item=>{
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormOrdenCompraCMP.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Orden de compra',
            text: 'Debe de ingresar datos de orden de compra',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha_orden_compra)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha_orden_compra === undefined) {
                Swal.fire({
                    title: 'Orden de compra',
                    text: 'Debe de ingresar fecha de orden de compra',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha_orden_compra;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Orden de compra',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormOrdenCompraCMP.values;

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_orden_compra);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === 0.00 || data.cantidad === undefined) {
            Swal.fire({
                title: 'Orden de compra',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        }else{
            if (data.precio_unitario === 0.00 || data.precio_unitario === undefined) {
                Swal.fire({
                    title: 'Orden de compra',
                    text: 'Debe de ingresar precio unitario',
                    type: 'error',
                })
            } else {
                if (detalle.length > 0) {
                    if (data.tipo_linea === 'B') {
                        let prod = data.producto.value.split("#");
                        const dato = detalle.find(item => item.id === prod[0].trim());
                        if (dato) {
                            NotificationManager.error(
                                'Producto ya fue ingresado',
                                'Error',
                                3000
                            );
                        } else {
                            let descuento = data.descuento ? data.descuento : 0.00;
                            let total = parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio_unitario)).toFixed(2);
                            let totalDesc = parseFloat(total - descuento).toFixed(2);
                            detalle.push({
                                id: prod[0].trim(),
                                tipo: data.tipo_linea,
                                cantidad: data.cantidad,
                                idPro: prod[0].trim(),
                                sku: prod[1].trim(),
                                productoN: prod[2].trim(),
                                precio: data.precio_unitario,
                                descuento: descuento,
                                total: totalDesc
                            })
                            data.cantidad = 0.00;
                            data.precio_unitario = 0.00;
                            data.descuento = 0.00;
                            data.detalleD = detalle;
                            NotificationManager.success(
                                'Producto ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }
                    }else{
                        const dato = detalle.find(item => item.id === data.descripcion_serv.trim());
                        if (dato) {
                            NotificationManager.error(
                                'Producto ya fue ingresado',
                                'Error',
                                3000
                            );
                        } else {
                            let descuento = data.descuento ? data.descuento : 0.00;
                            let total = parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio_unitario)).toFixed(2);
                            let totalDesc = parseFloat(total - descuento).toFixed(2);
                            detalle.push({
                                id: data.descripcion_serv.trim(),
                                tipo: data.tipo_linea,
                                cantidad: data.cantidad,
                                idPro: '',
                                sku: '',
                                productoN: data.descripcion_serv.trim(),
                                precio: data.precio_unitario,
                                descuento: descuento,
                                total: totalDesc
                            })
                            data.cantidad = 0.00;
                            data.precio_unitario = 0.00;
                            data.descuento = 0.00;
                            data.descripcion_serv = '';
                            data.detalleD = detalle;
                            NotificationManager.success(
                                'Servicio ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }
                    }
                } else {
                    if (data.tipo_linea === 'B') {
                        let prod = data.producto.value.split("#");
                        let descuento = data.descuento ? data.descuento : 0.00;
                        let total = parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio_unitario)).toFixed(2);
                        let totalDesc = parseFloat(total - descuento).toFixed(2);
                        detalle.push({
                            id: prod[0].trim(),
                            tipo: data.tipo_linea,
                            cantidad: data.cantidad,
                            idPro: prod[0].trim(),
                            sku: prod[1].trim(),
                            productoN: prod[2].trim(),
                            precio: data.precio_unitario,
                            descuento: descuento,
                            total: totalDesc
                        })
                        data.cantidad = 0.00;
                        data.precio_unitario = 0.00;
                        data.descuento = 0.00;
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Producto ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }else{
                        let descuento = data.descuento ? data.descuento : 0.00;
                        let total = parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio_unitario)).toFixed(2);
                        let totalDesc = parseFloat(total - descuento).toFixed(2);
                        detalle.push({
                            id: data.descripcion_serv,
                            tipo: data.tipo_linea,
                            cantidad: data.cantidad,
                            idPro: '',
                            sku: '',
                            productoN: data.descripcion_serv,
                            precio: data.precio_unitario,
                            descuento: descuento,
                            total: totalDesc
                        })
                        data.cantidad = 0.00;
                        data.precio_unitario = 0.00;
                        data.descuento = 0.00;
                        data.descripcion_serv = '';
                        data.detalleD = detalle;
                        NotificationManager.success(
                            'Servicio ingresado correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }
                }
            }
        }
    }else {
        Swal.fire({
            title: 'Orden de compra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormOrdenCompraCMP.values;
    dispatch(initializeForm('FormOrdenCompraCMP', data));
    dispatch({ type: DETALLE, detalleOrden: data.detalleD })
};

const limpiarOrden = () => (dispatch) => {
    detalle = [];
    dispatch({ type: DETALLE, detalleOrden: detalle })
};


export const registroOrden = (periodoD, moneda, tipoCambio, numeroOrden) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormOrdenCompraCMP.values;
    console.log(data)
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha_orden_compra)
    if (fecha>=fechaI && fecha<=fechaF){
        const formData = {
            fecha_orden_compra: data.fecha_orden_compra,
            fecha_compra: data.fecha_compra,
            numero: numeroOrden,
            tipo_pedido: data.tipo_pedido,
            incoterm: data.incoterm,
            envio: data.medio_envio,
            embarcador: data.embarcador,
            seguro: data.seguro,
            solicitante: data.solicitante,
            autorizado: data.autorizado,
            condicion_pago: data.tipo_pago,
            observaciones: data.observaciones,
            proveedor: data.proveedor.value,
            moneda: moneda,
            tipo_cambio: tipoCambio,
            detalleOrdenCompra: data.detalleD,
            iva: data.iva ? data.iva : false,
            isr: data.isr ? data.isr : false,
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
            
        dispatch(setLoader(true));
        api.post('/orden_compra/', formData).then((response) => {
            NotificationManager.success(
                'Orden de compra guardada correctamente',
                'Exito',
                3000
            );
            Swal.fire({
                title: '¿Imprimir orden de compra?',
                text: '¡Desea imprimir orden de compra!',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, imprimir!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    let reporte = 'imprimirOrden';
                    let idOrden = response.id;
                    let id = id_emp[5];
                    const params = { id, reporte, idOrden };
                    dispatch(setLoader(true));
                    api.getPdf('/orden_compra', params).then((response)=>{
                        let ruta = `/compras/${id_emp[5]}/orden_compra/${id_emp[7]}`
                        dispatch(push(ruta));
                        if (response.headers['content-type'] == 'application/pdf'){
                            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'cheque.pdf';
                            window.open(link, '_blank');
                            return false;
                        }
                        
                    }).catch((error)=>{
                        NotificationManager.error(
                            'Ocurrió un error al generar reporte',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }else {
                    window.location.reload(true);
                }
            });
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Orden de compra',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })    
    }
}

export const numeroOrdenCompra = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    
    api.get('/orden_compra/numero_orden/', params).then((response) => {
        dispatch({ type: NUMERO_ORDEN, numeroOrden: parseInt(response.numero)+1 });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de orden',
            'Error',
            2000
        );
    })
}


export const imprimirOrden = (idOrden) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'imprimirOrden';
    const params = { id, reporte, idOrden };
    dispatch(setLoader(true));
    api.getPdf('/orden_compra', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'orden_compra.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleOrden = id => (dispatch) => {
    api.get(`/orden_compra_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_ORDEN, detalleOrd: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/orden_compra/${id}`).then((response) => {
        dispatch({type: LECTURA, lectura: response});
        dispatch(leerDetalleOrden(response.id));
        response.tipo_pago = response.condicion_pago;
        dispatch(initializeForm('FormOrdenCompraCMP', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar orden de compra',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLineaProductoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormOrdenCompraCMP.values;
    
    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    data.detalleD = detalle;
    dispatch(actualizar());
};

export const anularOrdenCompra = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idOrden = id;
    const params = { idE, idOrden };
    dispatch(setLoader(true));
    api.put('/orden_compra/anular/', params).then(() => {
        NotificationManager.success(
            'Orden de compra anulado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}



export const actions = {
    listar,
    registroOrden,
    leer,
    limpiarOrden,
    tipoCambio,
    listarProductos,
    listarMonedas,
    listarProveedores,
    leerPeriodoD,
    getModulo,
    parametrosEmpresa,
    agregarLinea,
    numeroOrdenCompra,
    imprimirOrden,
    eliminarLineaProductoNuevo,
    anularOrdenCompra
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ORDEN]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LISTADO_PROVEEDOR]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [GUARDAR_ABONO]: (state, { lecturaA }) => {
        return {
            ...state,
            lecturaA,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE]: (state, { detalleOrden }) => {
        return {
            ...state,
            detalleOrden,
        };
    },
    [NUMERO_ORDEN]: (state, { numeroOrden }) => {
        return {
            ...state,
            numeroOrden,
        };
    },
    [DETALLE_ORDEN]: (state, { detalleOrd }) => {
        return {
            ...state,
            detalleOrd,
        };
    },
    [LECTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
};

export const initialState = {
    loader: false,
    tipos: [],
    pro: [],
    periodoS: null,
    monedas: [],
    modulo: [],
    periodoD: [],
    data: [],
    detalleOrden: [],
    numeroOrden: '',
    detalleOrd: [],
    lectura: [],
};

export default handleActions(reducers, initialState)