import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from '../Sidebar/SideBarDataInventario';
import { SidebarDataUserInventario } from '../Sidebar/SideBarDataUserInventario';
import { IconContext } from 'react-icons';
import styled from 'styled-components';
import SubMenu from '../Sidebar/SubmenuInventario';
import PropTypes from 'prop-types';
const defaultAvatar = require("assets/img/avatar-placeholder.png");
import MenuItems from './NavbarMenu';
import MenuItemsUser from './NavbarMenuUser';

function NavbarInventario(props) {
    const {
        logOut,
        user,
        empresaData,
    } = props;

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => {
        setSidebar(!sidebar);
    }
    
    const filtrarMenu = (menu, empresaData) => {
        return menu.filter(item => {
            if (item.label === 'listado_precios' && empresaData.punto_venta === 'N') {
                return false;
            }
            if (item.label === 'orden_servicio' && empresaData.ordenes_servicio === 'N') {
                return false;
            }
            return true;
        });
    };

    const menuFiltrado = filtrarMenu(SidebarData, empresaData);
    return (
        <React.Fragment>
            <IconContext.Provider value={{ color: '#fff' }}>
                <NavBar>
                    <NavIcon to="#">
                        <FaIcons.FaBars onClick={showSidebar} />
                    </NavIcon>
                    <div className="imageBoxLogo d-table m-auto">
                        <Image
                            src={require('assets/img/SIFW-LOGO-Red.png')}
                            alt="Logo" />
                    </div>
                    <div className="titleNav">
                        <Titulo>{empresaData.razon_social}</Titulo>
                    </div>
                    <div className="imageBoxLogo d-table m-auto">
                        {empresaData.logo &&
                            <ImageLogo
                                src={empresaData.logo}
                                alt="Logo" />
                        }
                    </div>
                    <NavBarMenuUser>
                        <ul className="borderUser flex-row navbar-nav">
                            {SidebarDataUserInventario.map((menu, index) => {
                                return <MenuItemsUser 
                                    items={menu} 
                                    key={index} 
                                    img={(user.avatar) ? __CONFIG__.api_image + user.avatar : defaultAvatar}
                                    user={user.username}
                                    logOut={logOut}
                                />;
                            })}
                        </ul>
                    </NavBarMenuUser>
                </NavBar>
                <NavBarMenu>
                    <ul className="menus flex-row navbar-nav">
                        {menuFiltrado.map((menu, index) => {
                            return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                </NavBarMenu>
                <SidebarNav sidebar={sidebar}>
                    <SiderbarWrap>
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIcon>
                        {menuFiltrado.map((item, index) => {
                            return <SubMenu item={item} key={index} />;
                        })}
                    </SiderbarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </React.Fragment>
    );
}

NavbarInventario.propTypes = {
    logOut: PropTypes.func,
    user: PropTypes.object,
};

export default NavbarInventario;

const NavBar = styled.div`
    background-color: #333;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavBarMenu = styled.div`
    background-color: black;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1084px) {
        display: none;
        
    }
`;

const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: none;
    @media (max-width: 1084px) {
        display: block;
        
    }
`;

const SidebarNav = styled.nav`
    background: #333;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
`;

const SiderbarWrap = styled.div`
    width: 250px;
`;

const NavBarMenuUser = styled.div`
    background-color: black;
    height: 61px;
    width: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Titulo = styled.div`
    color: white;
    padding-top: 5px;
    padding-left: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    @media (max-width: 985px) {
        display: none;
        
    }
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    @media (max-width: 985px) {
        display: none;
        
    }
`;

const ImageLogo = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media (max-width: 985px) {
        display: none;
    }
`;
