import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import moment from 'moment';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

class ComandasList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, leerPeriodo, getModulo, parametrosEmpresa } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodo(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        parametrosEmpresa();
        listar();
    }
    render(){
        const { data, loader, periodoC, modulo, datos, imprimirFacturaCDS, descargarFacturaCDS } = this.props;
        let ruta = window.location.href;
        let datos_emp = ruta.split('/');
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodoC}
                    modulo = {modulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Facturas</h3>
                <br />
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            width="100"
                            headerAlign="center"
                            dataAlign='right'
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                            dataFormat={(cell, row)=>{
                                return moment(cell).format("DD/MM/YYYY");
                            }}
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="interno"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Interno
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serieDoc"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Serie
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numeroDoc"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="400"
                            dataField="nombreCli"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cliente
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            headerAlign="center"
                            dataAlign='right'
                            dataField="total"
                            dataFormat={(cell, row)=>{
                                return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                            }}
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estatus"
                            dataAlign='center'
                            dataSort
                            width="140"
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Estado
                        </TableHeaderColumn>
                        {parseInt(datos.certificador) === 3 &&
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    //ver_inv_fel: "factura",
                                    imprimirFactura: imprimirFacturaCDS,
                                    descargarFactura: descargarFacturaCDS,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        }   
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                //anularFac_fel: anularFacFel,
                                certificadorFel: datos.certificador
                            })} 
                        >
                            Anular
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default ComandasList;
