import { handleActions } from 'redux-actions';
//import { push } from "react-router-redux";
//import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LISTADO_COMANDA = 'LISTADO_COMANDA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO = 'PERIODO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    const params = { id, periodo };
    api.get('/pdv_comanda', params).then((response) => {
        dispatch({ type: LISTADO_COMANDA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar comandas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

/*export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/bodega/${id}`).then((response) => {
        dispatch(initializeForm('FormBodega', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar bodega',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}*/

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodo = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_periodo = id_emp[7];
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        localStorage.setItem('info_periodo', response.periodo.periodo);
        localStorage.setItem('fechaI', response.periodo.fecha_inicio);
        localStorage.setItem('fechaF', response.periodo.fecha_fin);
        dispatch({ type: PERIODO, periodoC: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            0
        );
    })
}

export const actions = {
    listar,
    //leer,
    getModulo,
    leerPeriodo
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_COMANDA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO]: (state, { periodoC }) => {
        return {
            ...state,
            periodoC,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    lectura: [],
    modulo: [],
    periodoC: []
};

export default handleActions(reducers, initialState);
