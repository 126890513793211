import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";


const LISTADO_PRECIOS = 'LISTADO_PRECIOS';
const GUARDAR_PRECIOS = 'GUARDAR_PRECIOS';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PAGE = 'PAGE';
const LOADER = 'LOADER';
const PARAMETRO = 'PARAMETRO';
const MODULO = 'MODULO';
const LISTADO = 'LISTADO';

const setPage = page => ({
    type: PAGE,
    page,
});

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let modulo = 'FAC'
    const params = { id, modulo };
    dispatch(setLoader(true));
    api.get('/listado_precios', params).then((response)=>{
        dispatch({ type: LISTADO_PRECIOS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar precios',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroListado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormListadoPrecios.values;
    const detalle = localStorage.getItem('id_detalle_listado_precios');
    const formData = {
        nombre_lista: data.nombre_lista,
        producto: data.producto.value,
        precio: data.precio,
        empresa: id_emp[5],
        modulo: 'FAC',
        detalle: detalle
    }
    dispatch(setLoader(true));
    api.post('/listado_precios/', formData).then((response) => {
        if (detalle){
            dispatch(initializeForm('FormListadoPrecios', response));
            dispatch(leerDetalleProductos(response.id));
        }else{
            dispatch(initializeForm('FormListadoPrecios', response));
            localStorage.setItem('id_detalle_listado_precios', response.id);
            dispatch(leerDetalleProductos(response.id));
        }
        NotificationManager.success(
            'Producto ingresado correctamente',
            'Exito',
            3000
        );
        
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}           

export const modificarListado = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormListadoPrecios.values;
    const id = datos.id;
    const formData = {
        id: id,
        nombre_lista: datos.nombre_lista,
    }
    dispatch(setLoader(true));
    api.put(`/listado_precios/${id}/`, formData).then((response) => {
        NotificationManager.success(
            'Listado modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/facturacion/${id_emp[5]}/listado_precios`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar listado de precios',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/listado_precios/${id}`).then((response) => {
        dispatch(initializeForm('FormListadoPrecios', response));
        localStorage.setItem('id_detalle_listado_precios', response.id);
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al consultar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/listado_precios_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_listado_precios');
    dispatch(setLoader(true));
    api.eliminar(`/listado_precios_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes_cod',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarPrecios = (page = 1) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { page, id };
    let cont = 1;
    let ct = localStorage.getItem("asignarListPrecio");
    localStorage.setItem("asignarListPrecio",cont);
    if (page===1 && ct===null){
        dispatch(setLoader(true));
    }
    
    api.get('/asignar_listado_precios', params).then((response)=>{
        dispatch({ type: LISTADO, lista: response });
        dispatch(setPage(page));
    }).catch((error)=>{
        console.log("error: ", error)
        NotificationManager.error(
            'Ocurrió un error al listar',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listPrecios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/listado_precios/empresa_listado',params).then(data=>{
        const list_precio = [];
        if(data){
            data.forEach(item=>{
                list_precio.push({
                    value: item.id,
                    label: item.nombre_lista
                })
            })
        }
        return list_precio ;
    }).catch((error)=>{
        
        return [];
    })
}

export const registroAsignar = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAsignarListadoPrecios.values;
    const formData = {
        cliente_id: data.cliente.value,
        listado_precio_id: data.listado.value,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/asignar_listado_precios/', formData).then((response) => {
        NotificationManager.success(
            'Registro agregado',
            'Exito',
            3000
        );
        let page = 1
        dispatch(listarPrecios(page));
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/asignar_listado_precios/${id}`).then(() => {
        let page = 1
        dispatch(listarPrecios(page));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listar,
    registroListado,
    modificarListado,
    leer,
    getModulo,
    parametrosEmpresa,
    listarProductos,
    leerDetalleProductos,
    eliminarLineaProducto,
    listarClientes,
    listarPrecios,
    listPrecios,
    registroAsignar,
    eliminarLinea
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PRECIOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [GUARDAR_PRECIOS]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO]: (state, { lista }) => {
        return {
            ...state,
            lista,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    listaP: [],
    datos: [],
    modulo: [],
    lista: [],
    detalleProducto: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)