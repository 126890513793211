import React, { useState } from 'react';
import Modal from '../components/Modal';
import ModalInf from '../components/ModalInf';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderFieldCheckB,
    renderFieldRadio
} from "../Utils/renderField/renderField";

const meses = [
    {
        label: 'ENERO',
        value: '01'
      
    },
    {
        label: 'FEBRERO',
        value: '02'
    },
    {
        label: 'MARZO',
        value: '03',
    },
    {
        label: 'ABRIL',
        value: '04',
    },
    {
        label: 'MAYO',
        value: '05',
    },
    {
        label: 'JUNIO',
        value: '06',
    },
    {
        label: 'JULIO',
        value: '07',
    },
    {
        label: 'AGOSTO',
        value: '08',
    },
    {
        label: 'SEPTIEMBRE',
        value: '09',
    },
    {
        label: 'OCTUBRE',
        value: '10'
    },
    {
        label: 'NOVIEMBRE',
        value: '11',
    },
    {
        label: 'DICIEMBRE',
        value: '12',
    },
];

const tiposCli = [
    {
        label: 'TODOS',
        value: 'TODOS'
    },
    {
        value: 'LOCAL',
        label: 'LOCAL'
    },
    {
        value: 'EXTERIOR',
        label: 'EXTERIOR'
    },
    {
        value: 'PERSONA',
        label: 'PERSONA'
    },
];

function InformesForm(props) {
    const {
        monedas,
        reporteLibroVentas,
        loader,
        reporteAntiguedadSaldos,
        listarClientes,
        listarListaDePrecios,
        modulo,
        reporteEstadoCuenta,
        reporteClientes,
        reporteListadoFacturas,
        reporteListadoAbonos,
        reporteListadoPrecios,
        reporteAnticipos,
        excelListadoAbonos,
        excelLibroVentas,
        excelAntiguedadSaldos,
        excelEstadoCuenta
    } = props;

    const [estadoModalLibro, cambiarEstadoModalLibro] = useState(false);
    const [estadoModalAntiguedad, cambiarEstadoModalAntiguedad] = useState(false);
    const [estadoModalEstadoCuenta, cambiarEstadoModalEstadoCuenta] = useState(false);
    const [folioLV,  cambiarFolioLV] = useState(1);
    const [estadoModalCli, cambiarEstadoModalCli] = useState(false);
    const [estadoModalListadoFacturas, cambiarEstadoModalListadoFacturas] = useState(false);
    const [estadoModalListadoAbonos, cambiarEstadoModalListadoAbonos] = useState(false);
    const [estadoModalListadoPrecios, cambiarEstadoModalListadoPrecios] = useState(false);
    const [opcion, setOpcion] = useState('CLIENTE');
    const [estadoModalAnticiposNoAplicados, cambiarEstadoModalAnticiposNoAplicados] = useState(false);
    
    const onOptionChange = e => {
        setOpcion(e.target.value)
      }
    localStorage.setItem('folioLV', folioLV);
    const handleChangeLV = e => {
        localStorage.setItem('folioLV', e.target.value);
        cambiarFolioLV(e.target.value);
    }
    let autoFocus = true;
    const [detalle, setDetalle] = useState(false);
    const [detalleAb, setDetalleAb] = useState(false);

    const onCheckboxClicked = (idx, isChecked) => {
        setDetalle(isChecked);
    }

    const onCheckboxClickedAb = (idx, isChecked) => {
        setDetalleAb(isChecked);
    }

    let titulo = 'Informes';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalCli(!estadoModalLibro)}
                                data-bs-toggle="list"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                role="tab"
                                aria-controls="list-home">Listado de Clientes
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalLibro(!estadoModalLibro)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Libro de Ventas
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListadoFacturas(!estadoModalListadoFacturas)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de facturas
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListadoAbonos(!estadoModalListadoAbonos)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de abonos
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListadoPrecios(!estadoModalListadoPrecios)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de precios
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalAnticiposNoAplicados(!estadoModalAnticiposNoAplicados)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Anticipos no aplicados
                            </button>
                        </div>
                    </div>
                </div>     
                <h4 className='mt-5' style={{ color: "white" }}>Cuenta por cobrar</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalEstadoCuenta(!estadoModalAntiguedad)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Estado de cuenta</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalAntiguedad(!estadoModalAntiguedad)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/facturacion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Antiguedad de saldos
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModalLibro} //libro ventas
                    cambiarEstado={cambiarEstadoModalLibro}
                    titulo="Libro de ventas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                            {/*<div className='col-6 mb-3'>
                                <div className="form-floating">
                                    <input
                                        type="number"
                                        name="folio"
                                        className="form-control"
                                        min="1"
                                        value={folioLV}
                                        onChange={handleChangeLV}
                                    />
                                    <label className='form-label' htmlFor="folio">
                                    Folio
                                    </label>
                                </div>
                            </div>*/}
                        </Contenido>
                    
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteLibroVentas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelLibroVentas}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalLibro(false)}
                                 >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <ModalInf
                    estado={estadoModalAntiguedad} //Antiguedad saldos
                    cambiarEstado={cambiarEstadoModalAntiguedad}
                    titulo="Antiguedad de saldos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                {monedas &&
                                    <div className="form-floating">
                                        <Field
                                            autoFocus={autoFocus}
                                            name="moneda"
                                            options={monedas}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Moneda"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                        
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                            <div className='col-4 ml-5'>
                                <Field
                                    name="detallado"
                                    checked={detalle}
                                    label="Detallado"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClicked}
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteAntiguedadSaldos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelAntiguedadSaldos}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalAntiguedad(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </ModalInf>
                <ModalInf
                    estado={estadoModalEstadoCuenta} //Estado de cuenta
                    cambiarEstado={cambiarEstadoModalEstadoCuenta}
                    titulo="Estado de cuenta"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                {monedas &&
                                    <div className="form-floating">
                                        <Field
                                            autoFocus={autoFocus}
                                            name="moneda"
                                            options={monedas}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Moneda"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                            
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                            
                                    />
                                </div>
                            </div>
                            
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteEstadoCuenta}
                                    >
                                        Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelEstadoCuenta}
                                    >
                                        Exportar excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalEstadoCuenta(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </ModalInf>
                <Modal
                    estado={estadoModalCli} //listado clientes
                    cambiarEstado={cambiarEstadoModalCli}
                    titulo="Listado de clientes"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className="form-floating mb-4 col-6">
                                <Field
                                    autoFocus={autoFocus}
                                    name="tipo_cliente"
                                    dato={'TODOS'}
                                    options={tiposCli}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </Contenido>
                        
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteClientes}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalCli(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                
                <Modal
                    estado={estadoModalListadoFacturas} //Listado de facturas
                    cambiarEstado={cambiarEstadoModalListadoFacturas}
                    titulo="Listado de facturas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                            <fieldset>
                                <Field
                                    type="radio"
                                    name="opcion"
                                    className="form-control"
                                    component={renderFieldRadio}
                                    label="Por Cliente"
                                    value="CLIENTE"
                                    checked={opcion === "CLIENTE"}
                                    onChange={onOptionChange}
                                />
                                <Field
                                    type="radio"
                                    name="opcion"
                                    className="form-control"
                                    component={renderFieldRadio}
                                    label="Por correlativo"
                                    value="CORRELATIVO"
                                    checked={opcion === "CORRELATIVO"}
                                    onChange={onOptionChange}
                                />
                            </fieldset>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => reporteListadoFacturas(opcion)}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListadoFacturas(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={estadoModalListadoAbonos} //Listado de abonos
                    cambiarEstado={cambiarEstadoModalListadoAbonos}
                    titulo="Listado de abonos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                            <div className='col-4 ml-5'>
                                <Field
                                    name="detalladoAb"
                                    checked={detalleAb}
                                    label="Detallado"
                                    component={renderFieldCheckB}
                                    onChange={onCheckboxClickedAb}
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoAbonos}
                                    >
                                        Generar reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelListadoAbonos}
                                    >
                                        Exportar excel
                                    </button>
                                </div>    
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListadoAbonos(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={estadoModalListadoPrecios} //Listado de precios
                    cambiarEstado={cambiarEstadoModalListadoPrecios}
                    titulo="Listado de precios"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="listaPrecios"
                                    placeholder="Lista..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarListaDePrecios}
                                    msj="Lista"
                                />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoPrecios}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListadoPrecios(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalAnticiposNoAplicados} //Anticipos no aplicacdos
                    cambiarEstado={cambiarEstadoModalAnticiposNoAplicados}
                    titulo="Anticipos no aplicados"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                          
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mt-2 mb-3 btn-primary'
                                        onClick={reporteAnticipos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalAnticiposNoAplicados(false)}
                                    >
                                        Cancelar
                                    </button>
                            </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
            </div>
        </div>
    );

}

InformesForm.propTypes = {
    reporteLibroVentas: PropTypes.func,
    reporteAntiguedadSaldos: PropTypes.func,
    loader: PropTypes.bool,
    monedas: PropTypes.object,
    listarClientes: PropTypes.object,
    listarListaDePrecios: PropTypes.object,
    modulo: PropTypes.object,
    reporteEstadoCuenta: PropTypes.func,
    reporteClientes: PropTypes.func,
    reporteListadoFacturas: PropTypes.func,
    reporteListadoAbonos: PropTypes.func,
    reporteListadoPrecios: PropTypes.func,
};

export default reduxForm({
    form: 'FormInformesF',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
