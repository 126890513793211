import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { validar } from "../../../common/components/components/ValidarNit";
import Swal from 'sweetalert2';
import moment from 'moment';


const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const NOMBRE = 'NOMBRE';
const ID_CLIENTE = 'ID_CLIENTE';

const PRODUCTOS = 'PRODUCTOS';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const SALDO_CAJA = 'SALDO_INICIAL';


const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarProductos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    api.get('/producto/productos_inventario_bodega_punto_venta/', params).then((response) => {
        const producto = [];
        response.forEach(item => {
            producto.push({
                id: item.idProducto,
                tipo:item.tipoPro,
                sku: item.sku,
                nombre: item.productoN,
                precio: !item.precioVenta ? 0.00 : item.precioVenta,
                label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 3 }).format(item.saldo),
                stock: item.saldo,
                url: `${__CONFIG__.api_image}/media/`+item.imagen
            })
        })
        dispatch({ type: PRODUCTOS, productosSelect: producto })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar los productos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datosEmpresa: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

/******** CLIENTES PUNTO DE VENTA ******** */
export const registroCliente = (nombreCli) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaPdv.values;
    // Registro Cliente con NIT
    let valid = validar.valNit(data.identificadorCli);
    if (valid === false) {
        Swal.fire({
            title: 'Cliente',
            text: 'Ingrese Nit válido',
            type: 'error',
        })
    } else {
        if (nombreCli === 'NIT no encontrado') {
            Swal.fire({
                title: 'Cliente',
                text: 'Ingrese Nit válido',
                type: 'error',
            })
        } else {
            const formData = {
                identificador: data.identificadorCli,
                nombre: nombreCli,
                direccion: data.direccionCli,
                telefono: data.telefonoCli,
                correo: data.correo,
                modulo: 'PDV',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/pdv_cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const buscarNit = (nit) => (dispatch) => {
    const params = { nit }
    dispatch(setLoader(true));
    api.get('/pdv_cliente/buscar_nit/', params).then((response) => {
        if (response.detailCode === 'FREY_FREY_NIT_SEARCH_NOT_FOUND') {
            dispatch({ type: NOMBRE, nombreCli: 'NIT no encontrado' });
        }
        if (response.message === 'OK') {
            dispatch({ type: NOMBRE, nombreCli: response.name });
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar cliente',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const buscarCliente = (search) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    dispatch(setLoader(true));
    return api.get('/pdv_cliente/buscar_cliente', params).then((response) => {
        const cliente = {
            nombre: response[0].nombre,
            id: response[0].id
        };
        return cliente
    }).catch(() => {
        NotificationManager.error(
            'El cliente no existe!',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

/******** CAJA PUNTO DE VENTA ******** */
// Consultar status inicial de caja 
export const statusCaja = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let fecha = moment().format("YYYY-MM-DD")
    let status = "A"
    const params = { id, fecha, status }
    api.get('/pdv_caja', params).then((response) => {
        dispatch({ type: SALDO_CAJA, saldo: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar caja',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
// Apertura de caja
export const registrarAperturaCaja = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let fecha = moment().format("YYYY-MM-DD")
    // let fecha = "2024-11-04";
    const data = getStore().form.FormFacturaPdv.values;

    if (!data) {
        Swal.fire({
            title: 'Apertura de Caja',
            text: 'Ingrese el efectivo inicial',
            type: 'warning',
        })
    }
    if (data) {
        const formData = {
            fecha_caja: fecha,
            efectivo_inicial: data.efectivoInicial,
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/pdv_caja/', formData).then((response) => {

            NotificationManager.success(
                'Caja creado con éxito',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}
export const limpiarDatos = () => (dispatch) => {
    dispatch({ type: NOMBRE, nombreCli: '' });
}

export const registroVenta = (detalleVenta, datos, idCliente) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const fecha = new Date();
    const fechaAjustada = new Date(fecha.getTime() - 6 * 60 * 60 * 1000);
    const fechaFormateada = fechaAjustada.toISOString().split('T')[0];
    const formData = {
        fecha: fechaFormateada,
        cliente: idCliente,
        detalle_venta: detalleVenta,
        modulo: 'PDV',
        empresa: id_emp[5],
    }
    if (detalleVenta.length === 0){
        Swal.fire({
            title: 'Punto de venta',
            text: 'Debe de ingresar detalle de venta',
            type: 'error',
        })
    }else{
        if (datos.comanda_pdv === 'S'){
            dispatch(setLoader(true));
            api.post('/pdv_comanda/', formData).then((response) => {
                NotificationManager.success(
                    'Venta guardada correctamente',
                    'Exito',
                    3000
                );
                Swal.fire({
                    title: '¿Punto de venta?',
                    text: '¡Desea generar la factura!',
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, aceptar!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if (parseInt(datos.certificador) === 3){
                            //CERTIFICADOR CDS
                            const formData = {
                                fecha: fechaFormateada,
                                cliente: idCliente,
                                detalle_venta: detalleVenta,
                                modulo: 'PDV',
                                empresa: id_emp[5],
                                idComanda: response.id
                            }
                            dispatch(setLoader(true));
                            api.post('/factura/PDV_factura_fel_CDS/', formData).then((responseFac) => {
                                NotificationManager.success(
                                    'Factura generada correctamente',
                                    'Exito',
                                    3000
                                );
                                window.open(`https://print.totaldoc.io/pdf?uuid=${responseFac.encabezado.numero_autorizacion}&noattachment=1`, "_blank");
                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    2000
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }else {
                        window.location.reload(true);
                    }
                });
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            if (parseInt(datos.certificador) === 3){
                //CERTIFICADOR CDS
                dispatch(setLoader(true));
                api.post('/factura/PDV_factura_fel_CDS/', formData).then((response) => {
                    NotificationManager.success(
                        'Venta guardada correctamente',
                        'Exito',
                        3000
                    );
                    window.open(`https://print.totaldoc.io/pdf?uuid=${response.encabezado.numero_autorizacion}&noattachment=1`, "_blank");
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const actions = {
    getModulo,
    parametrosEmpresa,
    listarProductos,
    buscarNit,
    registroCliente,
    buscarCliente,
    statusCaja,
    registrarAperturaCaja,
    registroVenta,
    limpiarDatos
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datosEmpresa }) => {
        return {
            ...state,
            datosEmpresa,
        };
    },
    [SALDO_CAJA]: (state, { saldo }) => {
        return {
            ...state,
            saldo,
        };
    },
    [PRODUCTOS]: (state, { productosSelect }) => {
        return {
            ...state,
            productosSelect,
        };
    },
    [NOMBRE]: (state, { nombreCli }) => {
        return {
            ...state,
            nombreCli,
        };
    },
    [CLIENTE]: (state, { searchCliente }) => {
        return {
            ...state,
            searchCliente,
        };
    },
    [ID_CLIENTE]: (state, { idCliente }) => {
        return {
            ...state,
            idCliente,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    productosSelect: [],
    saldo: [],
    nombreCli: '',
    searchCliente: '',
    idCliente: '',
};

export default handleActions(reducers, initialState);
